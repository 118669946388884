/**
 * This component will be used in the progress bar, and the getting started app
 */
import React from 'react'
import { Stepper, Step, StepLabel, StepConnector } from 'components'

/**
 * We add StepContent here to provide spacing
 * If there is no activeStep, then everything is active (progress bar vs steps)
 */
export default ({
  steps,
  activeStep,
  orientation = 'vertical',
  backgroundColor = '#FFF',
  connector = <StepConnector />,
}) => {
  const active = !activeStep

  return (
    <Stepper
      activeStep={activeStep}
      orientation={orientation}
      sx={{ padding: 0, width: '100%', backgroundColor }}
      connector={connector}
    >
      {steps.map((label, index) => (
        <Step key={index} active={active} className="pt-2 pb-2">
          <StepLabel sx={{ minHeight: '48px' }}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}
