import React, { useState } from 'react'
import get from 'lodash/get'
import { useSelector, useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import ROLES from 'utils/constants/roles'
import { includesSome } from 'utils/includes'
import { useQuery } from 'utils/apollo'
import { gql } from '@apollo/client'
import { useStateWatch } from 'hooks'
import { setEdit, setForm, selectUser } from 'store/modules/clients'
import { setActiveClientsData } from 'store/modules/activeClients'
import { Grid } from 'components'
import CreateClient from '../components/buttons/CreateClient'
import ClientsTableColumns from './ClientsTableColumns'
import ClientsSearchAndFilterBar from './ClientsSearchAndFilterBar'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { useGetUserWithoutRefresh } from 'utils/hooks/useGetUser'
import { useOutletContext } from 'react-router'
import LicensePromptOnClientCreation from './LicensePromptOnClientCreation'
import ClientTableComponent from 'components/table/ClientTableComponent'

// we will always retrieve all clients
const GET_ACTIVE_CLIENTS_BY_IDS = gql`
  query getActiveClientsByUserIds {
    getActiveClientsByUserIds
  }
`

export default () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { showNewSubscriptionPlan } = useSelector((state) => state.ff)

  const INITIAL_FILTER_STATE = {
    activeClient: false,
    archived: false,
    suspended: false,
    ssp: false,
    focus: false,
    remote: false,
    inPerson: false,
  }
  const [filterState, setFilterState] = useState({ ...INITIAL_FILTER_STATE })

  const userRoles = useSelector((state) => get(state, 'auth.user.roles', []))
  const [canCreateOrInvite] = useStateWatch(
    () => !includesSome(userRoles, [ROLES.ADMIN, ROLES.ADMIN_NO_CLIENTS]),
    [userRoles]
  )
  const selectedUser = useSelector((state) => state.clients.selectedUser)
  const users = useSelector((state) => state.clients.data)

  const { refetch, initialQuery, queryVars, setQueryVars, fetch, setLoading } = useOutletContext()
  const clientsData = useSelector((state) => state.clients.data)

  const onUpdate = async (createdUserId) => {
    const newData = await refetch()
    const selected = get(newData, 'data.getUsers', []).findIndex(({ id }) => id === createdUserId)
    if (selected > -1) {
      dispatch(selectUser(selected))
    }
    enqueueSnackbar('User was created successfully', {
      variant: 'success',
      action: CloseSnackbarAction,
    })
  }

  const userIds = users.map(({ id }) => id)
  useQuery(GET_ACTIVE_CLIENTS_BY_IDS, {
    skip: !userIds?.length,
    onCompleted: (state) => dispatch(setActiveClientsData(state)),
  })
  const getActiveClientsByUserIds = get(
    useSelector((state) => state.activeClients.data),
    'getActiveClientsByUserIds',
    []
  )
  const activeIds = getActiveClientsByUserIds
    .filter(({ isActive }) => !!isActive)
    .map(({ id }) => id)

  const {
    selectedUser: { hasCompletedSspCertification, hasCompletedFocusCertification },
  } = useGetUserWithoutRefresh()
  const { hasSspProducts, hasFocusProducts } = useSelector((state) => get(state, 'ff', {}))

  const showSSPFilterChip = hasCompletedSspCertification && hasSspProducts
  const showFocusFilterChip = hasCompletedFocusCertification && hasFocusProducts

  const filterChips = [
    {
      id: 'activeClient',
      label: () => 'Active Client',
      queryUp: { ids: activeIds?.length ? activeIds : [-1] },
      queryDown: null,
      isEnabled: ({ showNewSubscriptionPlan }) => !showNewSubscriptionPlan,
      tooltipMessage: () => 'Show Active Clients',
    },
    {
      id: 'archived',
      label: () => 'Archived',
      queryUp: { isArchived: true },
      queryDown: { isArchived: false },
      isEnabled: () => true,
      tooltipMessage: () => 'Show Archived Clients',
    },
    {
      id: 'suspended',
      label: () => 'Suspended',
      queryUp: { isSuspended: true },
      queryDown: null,
      isEnabled: () => true,
      tooltipMessage: () => 'Show Suspended Clients',
    },
    {
      id: 'ssp',
      label: () => 'SSP Enabled',
      queryUp: 'ssp',
      queryDown: null,
      isEnabled: ({ showSSPFilterChip, showNewSubscriptionPlan }) =>
        showSSPFilterChip && !showNewSubscriptionPlan,
      tooltipMessage: () => 'Show Clients with SSP Enabled',
    },
    {
      id: 'focus',
      label: () => 'ILS Enabled',
      queryUp: 'focus',
      queryDown: null,
      isEnabled: ({ showFocusFilterChip, showNewSubscriptionPlan }) =>
        showFocusFilterChip && !showNewSubscriptionPlan,
      tooltipMessage: () => 'Show Clients with ILS Enabled',
    },
    {
      id: 'sspUse',
      label: () => 'Using SSP',
      queryUp: { sspSlotStatuses: ['used'] },
      queryDown: null,
      isEnabled: ({ showSSPFilterChip, showNewSubscriptionPlan }) =>
        showSSPFilterChip && showNewSubscriptionPlan,
      tooltipMessage: () => 'Show Clients using SSP',
    },
    {
      id: 'focusUse',
      label: () => 'Using ILS',
      queryUp: { focusSlotStatuses: ['used'] },
      queryDown: null,
      isEnabled: ({ showFocusFilterChip, showNewSubscriptionPlan }) =>
        showFocusFilterChip && showNewSubscriptionPlan,
      tooltipMessage: () => 'Show Clients using ILS',
    },
    {
      id: 'sspAssigned',
      label: () => 'SSP Not Started',
      queryUp: { sspSlotStatuses: ['assigned'] },
      queryDown: null,
      isEnabled: ({ showSSPFilterChip, showNewSubscriptionPlan }) =>
        showSSPFilterChip && showNewSubscriptionPlan,
      tooltipMessage: () => 'SSP Assigned but Not Started',
    },
    {
      id: 'focusAssigned',
      label: () => 'ILS Not Started',
      queryUp: { focusSlotStatuses: ['assigned'] },
      queryDown: null,
      isEnabled: ({ showFocusFilterChip, showNewSubscriptionPlan }) =>
        showFocusFilterChip && showNewSubscriptionPlan,
      tooltipMessage: () => 'ILS Assigned but Not Started',
    },
    {
      id: 'remote',
      label: () => 'Remote',
      queryUp: { isRemoteUser: true },
      queryDown: null,
      isEnabled: () => true,
      tooltipMessage: () => 'Show Remote Clients',
    },
    {
      id: 'inPerson',
      label: () => 'In-Person Only',
      queryUp: { isRemoteUser: false },
      queryDown: null,
      isEnabled: () => true,
      tooltipMessage: () => 'Show In-Person only Clients',
    },
  ].filter(({ isEnabled }) =>
    isEnabled({
      showSSPFilterChip,
      showFocusFilterChip,
      showNewSubscriptionPlan,
    })
  )

  const onSort = ({ sort }) => {
    setQueryVars({ ...queryVars, sort })
  }

  return (
    <div className="px-5 pb-5">
      <Grid container alignItems="center" className="pt-1 pb-1">
        {canCreateOrInvite && <CreateClient roles={[ROLES.CLIENT]} onUpdate={onUpdate} />}
      </Grid>
      <Grid container alignItems="center" className="pt-2 pb-1" data-test="user-search">
        <ClientsSearchAndFilterBar
          queryVars={queryVars}
          setQueryVars={setQueryVars}
          initialQuery={initialQuery}
          filterChips={filterChips}
          filterState={filterState}
          setFilterState={setFilterState}
        />
      </Grid>
      <ClientTableComponent
        data={clientsData}
        sort={queryVars.sort}
        onSort={onSort}
        selectedRow={selectedUser}
        onSelectRow={(index) => {
          dispatch(selectUser(index))
          dispatch(setEdit(false))
          dispatch(setForm(null))
        }}
        columns={ClientsTableColumns({ activeIds })}
        fetch={fetch}
        setLoading={setLoading}
      />
      <LicensePromptOnClientCreation />
    </div>
  )
}
