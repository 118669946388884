/**
 * This component will be the wrapper determining provider
 * dashboard or client dashboard
 */
import React, { useEffect, useState } from 'react'
import { Outlet, useParams } from 'react-router'
import get from 'lodash/get'
import { useSelector, useDispatch } from 'react-redux'
import {
  useGetSingleSessions,
  useGetAssessmentSessionsForDashboard,
  useGetSentAssessments,
} from 'utils/hooks/useGetSessions'
import useGetUser from 'utils/hooks/useGetUser'
import ROLES from 'utils/constants/roles'
import MainLayout from 'components/containers/main/Main'
import LoadingPage from 'components/LoadingPage'

import { gql, useQuery } from 'utils/apollo'
import useGetAssessments from 'views/assessments/utils/useGetAssessments'
import { setUserData } from 'store/modules/assessments'
import { GET_ACTIVE_CLIENTS, getActiveClients } from 'views/billing/ActiveClients'
import { setActiveClients } from 'store/modules/activeClients'
import { format } from 'date-fns'
import { GET_USERS_FOR_ASSESSMENTS } from 'views/assessments/constants/gql'

// Get the current date and time
const getSub30Days = () => {
  const currentDate = new Date()
  // Set the time to midnight
  currentDate.setHours(0, 0, 0, 0)
  currentDate.setDate(currentDate.getDate() - 30)

  // Format the date in the desired format
  const formattedDate = currentDate.toISOString().split('T')[0] + 'T00:00:00.000Z'

  return formattedDate
}

const WELCOME_TITLE_ARRAY = [
  { title: 'Welcome', isActive: () => true },
  {
    title: 'Good morning',
    isActive: () => {
      const currentDate = new Date()
      return currentDate.getHours() < 12
    },
  },
  {
    title: 'Good afternoon',
    isActive: () => {
      const currentDate = new Date()
      const currentHour = currentDate.getHours()
      return currentHour >= 12 && currentHour < 18
    },
  },
  {
    title: 'Good evening',
    isActive: () => {
      const currentDate = new Date()
      const currentHour = currentDate.getHours()
      return currentHour >= 18
    },
  },
  { title: 'Hi there', isActive: () => true },
  { title: 'Hello', isActive: () => true },
]

const DASHBOARD_CHECK_FOR_RECENTLY_COMPLETED_ASSESSMENTS = gql`
  query DashboardCheckForRecentlyCompletedAssessments(
    $filter: FilterSessionsInput
    $includeCount: Boolean
    $limit: Int
  ) {
    getSessions(filter: $filter, includeCount: $includeCount, limit: $limit) {
      id
    }
  }
`

export default function DashboardWrapper() {
  const {
    hasFocusProducts,
    hasSspProducts,
    showNewDashboardv2,
    showNewSubscriptionPlan,
    showProcessingCard,
  } = useSelector((state) => state.ff)
  const { id: userId } = useParams()
  const { loading: loadingSessions } = useGetSingleSessions({ skip: !!userId })
  const { hasSspSessions, hasFocusSessions, hasAssessmentSessions } = useSelector(
    (state) => state.sessions
  )
  const authUser = useSelector((state) => get(state, 'auth.user', {}))
  const { selectedUser, loading: loadingGetUser } = useGetUser(userId)

  const dispatch = useDispatch()
  // save assessments (metadata) to redux
  useGetAssessments({})

  useQuery(GET_USERS_FOR_ASSESSMENTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: {
        anyRoles: 'client',
        includeNoSlotClients: true,
      },
    },
    onCompleted: (data) => {
      dispatch(setUserData(get(data, 'getUsers', [])))
    },
  })

  // check for recently completed assessments
  const now = new Date()
  now.setHours(0, 0, 0, 0) // Ensure 'now' is set to midnight before calculations
  const lastThirtyDays = new Date(now)
  lastThirtyDays.setDate(now.getDate() - 30)

  const { data: recentAssessments } = useQuery(DASHBOARD_CHECK_FOR_RECENTLY_COMPLETED_ASSESSMENTS, {
    fetchPolicy: 'no-cache',
    variables: {
      includeCount: true,
      limit: 0,
      filter: {
        updatedAt: {
          gtEq: format(lastThirtyDays, 'yyyy-MM-dd'),
        },
        types: 'answers',
      },
    },
  })

  const hasRecentAssessments = get(recentAssessments, 'getSessionsCount', 0) > 0
  const hasAssessmentProducts = useSelector((state) => !!state.ff.hasAssessmentProducts)

  const {
    hasCompletedSspCertification,
    hasCompletedFocusCertification,
    hasVoiceProCertification,
    hasCompletedVoiceProCertification,
    roles,
    firstName,
    lastName,
    email,
    hasSspCertification,
    hasFocusCertification,
    sspInTrainingOnly,
    focusInTrainingOnly,
  } = selectedUser
  const isSspCompletedUser = selectedUser.hasCompletedSspCertification
  const isFocusCompletedUser = selectedUser.hasCompletedFocusCertification

  const {
    // SSP
    hasPaidSspSubscription,
    hasUnpaidSspSubscription,
    hasPastDueSspSubscription,
    hasOrgSspCertificationCompleted,
    hasAllCanceledSspSubscription,
    // ils
    hasPaidFocusSubscription,
    hasUnpaidFocusSubscription,
    hasPastDueFocusSubscription,
    hasOrgFocusCertificationCompleted,
    hasAllCanceledFocusSubscription,
  } = useSelector((state) => state.organization)

  const professionalCredentials = useSelector((state) =>
    get(state, 'auth.user.professionalCredentials', null)
  )
  const otherProfessionalCredentials = useSelector((state) =>
    get(state, 'auth.user.otherProfessionalCredentials', null)
  )

  // if certification is not completed then return trainingIncomplete
  const isClient = roles?.some((role) => ROLES.CLIENT_ROLES.includes(role))
  const isProvider = roles?.some((role) => ROLES.PROVIDER_ROLES.includes(role))
  const isBilling = roles?.some((role) => ROLES.BILLING === role)
  const gtEq = getSub30Days()
  useGetAssessmentSessionsForDashboard({ skip: !!userId || isClient, gtEq })
  useGetSentAssessments({ skip: !!userId, isProvider })
  const products = useSelector((state) => state.seats.products)

  const loading = loadingGetUser || loadingSessions

  // get random title
  const filteredWelcomeTitleArray = WELCOME_TITLE_ARRAY.map(
    ({ isActive, title }) => isActive() && title
  ).filter(Boolean)
  const randomInt = Math.floor(Math.random() * filteredWelcomeTitleArray.length)
  const greeting = filteredWelcomeTitleArray[randomInt]
  const [randomTitle, setRandomTitle] = useState()
  useEffect(() => {
    if (!randomTitle) {
      setRandomTitle([greeting, firstName, '✨'].join(' '))
    }
  }, [])

  const mainTitle = [
    {
      title: randomTitle,
      isActive: ({ userId }) => !userId,
    },
    {
      title: `Dashboard for ${[firstName, lastName].filter(Boolean).join(' ')}${
        email ? ` (${email})` : ''
      }`,
      isActive: ({ userId }) => userId,
    },
  ].find(({ isActive }) => isActive({ showNewDashboardv2, userId })).title

  useQuery(GET_ACTIVE_CLIENTS, {
    fetchPolicy: 'cache-and-network',
    skip: !isProvider || showNewSubscriptionPlan,
    onCompleted: async (data) => {
      const orgActiveClients = get(data, 'getActiveClients[0].count', 0)
      const activeClientsArray = get(data, 'getActiveClients[0].activeClients', [])
      const activeClients = getActiveClients(activeClientsArray, authUser.id)

      dispatch(
        setActiveClients({
          activeClients,
          orgActiveClients,
        })
      )
    },
  })

  const { isProcessingPayment, numberOfProviders } = useSelector((state) => state.organization)

  return (
    <LoadingPage loading={loading} text="loading...">
      <MainLayout title={mainTitle}>
        <Outlet
          context={{
            loading,
            isProvider,
            isClient,
            isBilling,
            professionalCredentials,
            otherProfessionalCredentials,
            products,
            selectedUser,
            hasRecentAssessments, // is a boolean for filteredData is not empty
            hasAssessmentProducts,
            hasAssessmentSessions,
            // ssp
            hasUnpaidSspSubscription,
            hasPastDueSspSubscription,
            hasCompletedSspCertification,
            hasPaidSspSubscription,
            hasSspSessions,
            hasSspProducts,
            hasOrgSspCertificationCompleted,
            hasAllCanceledSspSubscription,
            isSspCompletedUser,
            hasSspCertification,
            sspInTrainingOnly,
            // ils
            hasUnpaidFocusSubscription,
            hasPastDueFocusSubscription,
            hasCompletedFocusCertification,
            hasPaidFocusSubscription,
            hasFocusSessions,
            hasFocusProducts,
            hasOrgFocusCertificationCompleted,
            hasAllCanceledFocusSubscription,
            isFocusCompletedUser,
            hasFocusCertification,
            focusInTrainingOnly,
            // voice pro
            hasVoiceProCertification,
            hasCompletedVoiceProCertification,
            // new org settings
            isProcessingPayment: isProcessingPayment && showProcessingCard,
            numberOfProviders,
          }}
        />
      </MainLayout>
    </LoadingPage>
  )
}
