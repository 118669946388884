/**
 * This popup shows up when a new plan provider enters client page
 */
import React from 'react'
import { useSelector } from 'react-redux'
import { DoNotShowAgainDialog } from 'components/DoNotShowAgainDialog'
import { OrganizationConvertedToNewPlanMessage } from './content/OrganizationConvertedToNewPlanMessage'
import { HowClientLicensesWorkMessage } from './content/HowClientLicensesWorkMessage'

export default function ProgramsAndClientLicenses() {
  // get redux states
  const { hasSspProducts, hasFocusProducts, showSubscriptionSlotsAssignedMessage } = useSelector(
    (state) => state.ff
  )
  const userId = useSelector((state) => state.auth?.user?.id)

  // transform text based on license
  const licenseText = [
    {
      value: 'the SSP or the ILS',
      isEnabled: ({ hasSspProducts, hasFocusProducts }) => hasSspProducts && hasFocusProducts,
    },
    {
      value: 'the SSP',
      isEnabled: ({ hasSspProducts, hasFocusProducts }) => hasSspProducts && !hasFocusProducts,
    },
    {
      value: 'the ILS',
      isEnabled: ({ hasSspProducts, hasFocusProducts }) => !hasSspProducts && hasFocusProducts,
    },
  ].find(({ isEnabled }) => isEnabled({ hasSspProducts, hasFocusProducts }))

  const productText = [
    {
      value: 'SSP/ILS',
      isEnabled: ({ hasSspProducts, hasFocusProducts }) => hasSspProducts && hasFocusProducts,
    },
    {
      value: 'SSP',
      isEnabled: ({ hasSspProducts, hasFocusProducts }) => hasSspProducts && !hasFocusProducts,
    },
    {
      value: 'ILS',
      isEnabled: ({ hasSspProducts, hasFocusProducts }) => !hasSspProducts && hasFocusProducts,
    },
  ].find(({ isEnabled }) => isEnabled({ hasSspProducts, hasFocusProducts }))

  return (
    <DoNotShowAgainDialog
      id={`programs-and-client-licenses-${userId}`}
      title={
        showSubscriptionSlotsAssignedMessage
          ? 'Important information about your subscription!'
          : 'Programs and client licenses'
      }
    >
      {!showSubscriptionSlotsAssignedMessage && (
        <HowClientLicensesWorkMessage licenseText={licenseText.value} />
      )}
      {showSubscriptionSlotsAssignedMessage && (
        <OrganizationConvertedToNewPlanMessage productText={productText?.value} />
      )}
    </DoNotShowAgainDialog>
  )
}
