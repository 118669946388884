/**
 *
 */
import { Box, Button, ButtonProps } from '@mui/material'
import { AnimatedLeafLogoStartIcon } from 'components/PageLoader'
import React from 'react'

interface LoadingButtonProps {
  loading: boolean
  label: string
  children: React.ReactNode
}
export const LoadingButton: React.FC<ButtonProps & LoadingButtonProps> = ({
  loading,
  label,
  children,
  ...rest // spread the rest of the button props to button
}) => {
  return (
    <Box>
      <Button
        {...rest}
        variant="contained"
        startIcon={loading && <AnimatedLeafLogoStartIcon fill="#fff" />}
      >
        {children}
      </Button>
    </Box>
  )
}
