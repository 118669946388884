import React from 'react'
import { FormHelperText, FormControlLabel, Checkbox, Link } from 'components'
import ROLES from 'utils/constants/roles'
import LINKS from 'utils/constants/links'
import { includesSome } from 'utils/includes'

export default ({ checked = false, onChange = () => null, roles = [], disabled }) => {
  return (
    <FormControlLabel
      data-test="terms-of-use"
      control={
        <Checkbox
          checked={checked}
          onChange={(e) => onChange(!!e.target.checked)}
          color="primary"
          disabled={disabled}
        />
      }
      label={
        <>
          {includesSome(roles, [ROLES.CLIENT]) ? (
            <FormHelperText>
              I accept the Unyte-iLs
              <span> </span>
              <Link style={{ color: 'inherit' }} target="_blank" to={LINKS.invitation.termsOfUse}>
                Terms of Use
              </Link>
              <span> and </span>
              <Link
                style={{ color: 'inherit' }}
                target="_blank"
                to={LINKS.invitation.privacyNotice}
              >
                Privacy Notice
              </Link>
              .
            </FormHelperText>
          ) : (
            <FormHelperText>
              I accept the Unyte-iLs
              <span> </span>
              <Link
                style={{ color: 'inherit' }}
                target="_blank"
                to={LINKS.invitation.termsOfService}
              >
                Customer Terms of Service
              </Link>
              {' including the Professional Attestation'}.
            </FormHelperText>
          )}
        </>
      }
    />
  )
}
