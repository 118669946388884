/**
 * This component follows the mockup of UW-5352
 * We will show new purchase plan text to all users
 */
import {
  Container,
  Grid,
  CardContent,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CardMedia,
  Typography,
  Stack,
} from '@mui/material'
import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router'
import get from 'lodash/get'
import AddIcon from '@mui/icons-material/Add'
import { useSelector } from 'react-redux'
import useGetUser from 'utils/hooks/useGetUser'
import ROLES from '../../utils/constants/roles'
import { includesSome } from '../../utils/includes'
import useGTM from 'utils/hooks/useGTM'
import { sspCardConfigs as cards } from './components/StoreFrontCardConfigs'
import BookACallCard from './components/BookACallCard'
import Card from './components/PurchaseCardComponent'
import CardHeader from './components/PurchaseCardHeader'
import PurchaseCard from './components/PurchaseCard'
import ContactAdministratorMessage from './components/ContactAdministratorMessage'

const MARGIN_LEFT = '0.5rem'

export default function StoreFront() {
  const { setMainLayoutTitle } = useOutletContext()
  const { showNewSubscriptionPlan } = useSelector((state) => state.ff)
  const { selectedUser } = useGetUser()
  const { hasSspCertification, roles } = selectedUser

  const isProvider = includesSome(roles, [...ROLES.PROVIDER_ROLES])
  const isBilling = roles.includes(ROLES.BILLING)
  const isProviderWithBilling = isProvider && isBilling

  const {
    hasOrgSspCertificationCompleted,
    hasPaidSspSubscription,
    hasPastDueSspSubscription,
    hasPendingSspSubscription,
    hasPaidFocusSubscription,
    hasPastDueFocusSubscription,
    hasPendingFocusSubscription,
    hasAllCanceledSspSubscription,
  } = useSelector((state) => get(state, 'organization', {}))

  const hasActiveSspSubscription =
    hasPastDueSspSubscription || hasPaidSspSubscription || hasPendingSspSubscription

  const hasActiveFocusSubscription =
    hasPaidFocusSubscription || hasPastDueFocusSubscription || hasPendingFocusSubscription

  const showTrainingPurchaseOption = isProviderWithBilling && !hasSspCertification
  const showOnlyTrainingPurchaseOption = showTrainingPurchaseOption && hasActiveSspSubscription
  const showSubscriptionPurchaseOptions = isBilling && !hasActiveSspSubscription

  const isNewUser = !hasActiveFocusSubscription && !hasActiveSspSubscription

  // set the title on load
  const { pageView } = useGTM()
  useEffect(() => {
    const title = 'Purchase SSP'
    setMainLayoutTitle(title)

    pageView({
      pageUrl: window.document.location.href,
      pageTitle: 'Internal Purchase - Select items',
    })
    // eslint-disable-next-line
  })

  const subscriptionItems = [
    'Multiple playlists delivering three SSP Pathways, curated for adults and children',
    'The Unyte-iLs app for in-clinic and remote delivery',
    'The MyUnyte platform for managing clients and accessing numerous practical and clinical tools',
    'Unlimited use of Unyte Assessments',
    'Ongoing live support from our dedicated Client Success team',
    'Exclusive events such as Provider-only Q&As',
    'Peer events & continuing education opportunities such as provider spotlights, webinars, ongoing training updates',
    showNewSubscriptionPlan || isNewUser
      ? '25 Client Licenses included with annual plan; 6 included with 3 month plan.*'
      : 'Access for 5 active clients monthly*',
  ]

  return (
    <Container maxWidth="md" sx={{ margin: '0 0 50px 0' }}>
      {/* Header */}
      <Grid container spacing={1} alignItems="stretch" justifyItems="space-between" mt={1}>
        <Grid sm={6}>
          <CardMedia
            image="/images/store/ssp-store-front-header.png"
            sx={{
              height: '300px',
              maxWidth: '330px',
              margin: '0 0 0 15%',
            }}
          />
        </Grid>
        <Grid sm={6}>
          <Typography color="#3c7e7d" sx={{ fontSize: 30 }}>
            Safe and Sound Protocol: Certification and Subscriptions
          </Typography>
          <br />
          <Typography sx={{ fontWeight: 500 }}>
            Start guiding your clients’ nervous systems to become more aware, regulated and
            resilient so they can more effectively respond to life’s challenges.
          </Typography>
          <br />
          <br />
          <Typography>
            Our subscription options make it easy to integrate Polyvagal Theory into your practice!
          </Typography>
        </Grid>
      </Grid>

      {/* plans */}
      {isBilling && (
        <Grid container spacing={1} alignItems="stretch" justifyItems="space-between" mt={1}>
          {cards
            .filter(({ isActive }) =>
              isActive({
                hasSspCertification,
                hasOrgSspCertificationCompleted,
                hasActiveSspSubscription,
                hasAllCanceledSspSubscription,
              })
            )
            .map(
              ({
                title,
                costPerMonth,
                description,
                trainingMessage,
                subDescription,
                link,
                skus,
                testName,
              }) => (
                <Grid item xs={12} sm={4} data-test={testName}>
                  <PurchaseCard
                    title={title}
                    costPerMonth={costPerMonth}
                    description={description}
                    trainingMessage={trainingMessage}
                    subDescription={subDescription({ hasActiveFocusSubscription })}
                    link={link}
                    skus={skus}
                    marginLeft={MARGIN_LEFT}
                    data-test={testName}
                  />
                </Grid>
              )
            )}
        </Grid>
      )}

      {/* SSP Subscription items */}
      <Grid container spacing={1} alignItems="stretch" sx={{ marginTop: '0rem' }}>
        {showSubscriptionPurchaseOptions && (
          <Grid item xs={12} sm={8} data-test="ssp-subscription-details-card">
            <Card backgroundColor="#f7f7f7">
              <CardHeader
                title="Your SSP Subscription Includes:"
                color="#3c7e7d"
                sx={{ paddingBottom: 0 }}
                data-test="ssp-subscription-details-card"
              />
              <Box sx={{ margin: '0 0.5rem 0 0.5rem' }}>
                <CardContent sx={{ padding: '0 0.5rem 0rem 0.5rem' }}>
                  <List>
                    {subscriptionItems.map((item) => (
                      <ListItem dense disableGutters>
                        <ListItemIcon sx={{ minWidth: '36px' }}>
                          <AddIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary={item}
                          primaryTypographyProps={{ variant: 'body2' }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
                <CardContent
                  sx={{ padding: '0 0.5rem 0.5rem 0.5rem', margin: '0 0.5rem 0 0.5rem' }}
                >
                  <Stack direction="column" spacing={1}>
                    {(showNewSubscriptionPlan || isNewUser) && (
                      <Typography variant="caption">
                        * Each Client License is accessible for 12 months from the start of
                        listening.
                        <br />
                        Add additional Client Licenses anytime for $25.
                      </Typography>
                    )}
                    {!(showNewSubscriptionPlan || isNewUser) && (
                      <Typography variant="caption">
                        * Additional active clients are $10/month. An active client is a client who
                        has listened to 30 minutes or more of SSP music within your monthly billing
                        cycle.
                      </Typography>
                    )}
                    <Typography variant="caption">
                      ** All subscription plans automatically renew.
                    </Typography>
                  </Stack>
                </CardContent>
              </Box>
            </Card>
          </Grid>
        )}

        <Grid
          item
          sx={{ display: 'flex', flexDirection: 'column' }}
          xs={12}
          sm={4}
          direction="column"
        >
          {showTrainingPurchaseOption && (
            /* Purchase SSP training */
            <Grid
              sx={{ margin: '0 0 5px 0' }}
              item
              md={4}
              xs={12}
              sm={4}
              data-test="ssp-certification-card"
            >
              <PurchaseCard
                title={'Foundational SSP Training'}
                oneTimeCost={349}
                description={
                  <Typography>
                    Get SSP online training! Purchase your subscription later.
                  </Typography>
                }
                link={'/purchase/confirm'}
                skus={['sspCertification']}
                marginLeft={MARGIN_LEFT}
                data-test="ssp-certification-card"
              />
              {showOnlyTrainingPurchaseOption && (
                <Typography variant="caption">
                  Please note: an active SSP subscription is required to deliver the SSP program
                </Typography>
              )}
            </Grid>
          )}
          {/* Book a call */}
          {showSubscriptionPurchaseOptions && <BookACallCard sourceParam="mu_ssp_sub" />}
        </Grid>
      </Grid>

      {isProvider && !isBilling && !(hasSspCertification && hasActiveSspSubscription) && (
        <ContactAdministratorMessage />
      )}
    </Container>
  )
}
