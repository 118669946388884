import React from 'react'
import MainLayout from 'components/containers/main/Main'
import { Container } from 'components'
import { useSelector } from 'react-redux'
import { BILLING_TABS } from './tabs'
import { useGetUserWithoutRefresh } from 'utils/hooks/useGetUser'
import { HowClientLicensesWorkMessage } from 'views/users/components/modals/content/HowClientLicensesWorkMessage'

export default function HowClientLicensesWork() {
  const {
    hasSspProducts,
    hasFocusProducts,
    showBillingHistory,
    showNewSSPSubscriptionPlan,
    showNewFocusSubscriptionPlan,
  } = useSelector((state) => state.ff)
  const {
    selectedUser: { hasCompletedFocusCertification, hasCompletedSspCertification },
  } = useGetUserWithoutRefresh()

  const tabs = BILLING_TABS.filter(({ isEnabled }) =>
    isEnabled({
      showBillingHistory,
      hasSspProducts,
      hasFocusProducts,
      showNewSSPSubscriptionPlan,
      showNewFocusSubscriptionPlan,
      hasCompletedSspCertification,
      hasCompletedFocusCertification,
    })
  )

  // helper function to transform text based on license
  const licenseText = [
    {
      value: 'the SSP or the ILS',
      isEnabled: ({ hasSspProducts, hasFocusProducts }) => hasSspProducts && hasFocusProducts,
    },
    {
      value: 'the SSP',
      isEnabled: ({ hasSspProducts, hasFocusProducts }) => hasSspProducts && !hasFocusProducts,
    },
    {
      value: 'the ILS',
      isEnabled: ({ hasSspProducts, hasFocusProducts }) => !hasSspProducts && hasFocusProducts,
    },
  ].find(({ isEnabled }) => isEnabled({ hasSspProducts, hasFocusProducts }))

  return (
    <MainLayout title="My Billing" tabs={tabs}>
      <Container className="pt-5" maxWidth="md">
        {licenseText && <HowClientLicensesWorkMessage licenseText={licenseText.value} />}
      </Container>
    </MainLayout>
  )
}
