import React, { useState } from 'react'
import { get, isEmpty, xor } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import ROLES from 'utils/constants/roles'
import deepTrim from 'utils/deepTrim'
import { useSnackbar } from 'notistack'
import moment from 'utils/moment'
import { useStateWatch } from 'hooks'
import formats from 'utils/constants/formats'

import { setEdit, setForm, selectUser, setData } from 'store/modules/admins'

import { useQuery } from 'utils/apollo'
import { gql } from '@apollo/client'

import { Grid, UnyteTable, Button } from 'components'
import Avatar from '../components/Avatar'
import SortLabel from '../components/table/SortLabel'
import NameCol from '../components/table/NameCol'
import CreateInvitation from '../components/modals/CreateInvitation'
import UserDetails from './AdminsDetails'
import AddIcon from '@mui/icons-material/Add'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import SearchBar from '../components/Search'

const USER_QUERY = gql`
  query getUsers($filter: FilterUsersInput, $sort: [[String!]], $offset: Int) {
    getUsers(filter: $filter, sort: $sort, offset: $offset) {
      address1
      address2
      city
      country
      createdAt
      dob
      email
      firstName
      id
      isArchived
      isSuspended
      lastLoginAt
      lastName
      phone
      state
      roles
      zip
    }
  }
`

export default () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const userRoles = useSelector((state) => get(state, 'auth.user.roles', []))
  const selectedUser = useSelector((state) => state.admins.selectedUser)
  const reduxData = useSelector((state) => state.admins.data)
  const [queryVars, setQueryVars] = useState({
    sort: [],
    filter: {
      anyRoles: [ROLES.ADMIN, ROLES.ADMIN_NO_CLIENTS],
      any: '',
      isArchived: false,
    },
  })
  const { data, fetchMore, refetch, loading } = useQuery(USER_QUERY, {
    variables: deepTrim(queryVars),
  })
  const [users] = useStateWatch(() => get(data, 'getUsers', []), [data])
  const [pending, setPending] = useStateWatch(() => loading, [loading])

  const [openPrompt, setOpenPrompt] = useState(false)
  const handleClickOpen = () => {
    setOpenPrompt(true)
  }

  const onSearchbarChange = (event) => {
    const newObject = {
      ...queryVars,
      filter: { ...queryVars.filter, any: event.target.value },
    }
    setQueryVars(newObject)
  }
  const onClear = () => {
    const newObject = {
      ...queryVars,
      filter: { ...queryVars.filter, any: '' },
    }
    setQueryVars(newObject)
  }

  return (
    <div className="px-5 pb-5">
      <Grid container alignItems="center" className="pt-1 pb-1">
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          startIcon={<AddIcon />}
          data-test="invite-admin-button"
        >
          Invite Admin
        </Button>
        <CreateInvitation
          roles={
            userRoles.includes(ROLES.ADMIN_NO_CLIENTS)
              ? [ROLES.ADMIN_NO_CLIENTS]
              : [ROLES.ADMIN, ROLES.ADMIN_NO_CLIENTS]
          }
          text="Invite admin"
          showModal={openPrompt}
          setModalState={setOpenPrompt}
        />
      </Grid>
      <Grid container alignItems="center" className="pt-5 pb-1 pl-4">
        <SearchBar
          placeholder="Search by first name, last name, or email."
          handleTextChange={onSearchbarChange}
          handleClick={onClear}
          keywords={get(queryVars, 'filter.any', '')}
        />
      </Grid>
      <UnyteTable
        data={users}
        loading={pending}
        onLoadMore={() => {
          if (!isEmpty(xor(reduxData, users))) {
            setPending(true)
            setData(users)
            fetchMore({
              variables: { offset: users.length },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                setPending(false)
                return {
                  getUsers: previousResult.getUsers.concat(fetchMoreResult.getUsers),
                }
              },
            })
          }
        }}
        detailsComponent={({ data: selectedUser }) => (
          <UserDetails
            user={selectedUser}
            onUpdate={(cache) => {
              cache.reset()
              refetch()
              enqueueSnackbar('Update successful', {
                variant: 'success',
                action: CloseSnackbarAction,
              })
            }}
          />
        )}
        sort={queryVars.sort}
        onSort={({ sort }) => setQueryVars((old) => ({ ...old, sort }))}
        selectedRow={selectedUser}
        onSelectRow={(index) => {
          dispatch(selectUser(index))
          dispatch(setEdit(false))
          dispatch(setForm(null))
        }}
        columns={[
          {
            width: '33%',
            header: ({ onSort, sort }) => <NameCol onSort={onSort} sort={sort} />,
            body: ({ data }) => <Avatar {...data} />,
          },
          {
            width: '33%',
            header: ({ onSort, sort }) => (
              <SortLabel sort={sort} title="Last sign in" sortKey="lastLoginAt" onChange={onSort} />
            ),
            body: ({ data }) =>
              data.lastLoginAt ? moment(data.lastLoginAt).format(formats.dateAndTime) : null,
          },
          {
            width: '33%',
            header: ({ onSort, sort }) => (
              <SortLabel sort={sort} title="Created" sortKey="createdAt" onChange={onSort} />
            ),
            body: ({ data }) => moment(data.createdAt).format(formats.dateAndTime),
          },
        ]}
      />
    </div>
  )
}
