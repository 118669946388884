import React from 'react'
import { Card, CardContent, Typography, CardMedia, Box, Grid, Stack } from '@mui/material'

import LeafLogo from '../icons/LeafLogo'
import LINKS from 'utils/constants/links'

/**
 * TODO: UW-1375: Change sspActive to hasActiveProducts
 * https://unytehealth.atlassian.net/browse/UW-1375
 */
export default ({ showDownloadImage }) => {
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      {showDownloadImage && (
        <Grid item mt={2}>
          <Card
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
            elevation={0}
          >
            <CardMedia
              sx={{ width: '200px', height: '300px' }}
              image="/images/dashboard/MockupPhone.png"
            />
            <CardContent className={`m-auto`}>
              <Box
                pb={4}
                sx={{
                  display: 'inline-flex',
                }}
              >
                <LeafLogo />
                <Typography
                  pl={1}
                  sx={{
                    fontSize: '0.875rem',
                    lineHeight: '1rem',
                    margin: 'auto',
                  }}
                >
                  Get the Unyte-iLs App
                </Typography>
              </Box>
              <a href={LINKS.download.playStore.href} target="_blank" rel="noopener noreferrer">
                <CardMedia
                  my={1}
                  sx={{
                    width: 140,
                    paddingTop: '25%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  image={LINKS.download.playStore.imgSrc}
                />
              </a>
              <a href={LINKS.download.appStore.href} target="_blank" rel="noopener noreferrer">
                <CardMedia
                  sx={{
                    width: 140,
                    paddingTop: '27%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  my={1}
                  image={LINKS.download.appStore.imgSrc}
                />
              </a>
            </CardContent>
          </Card>
        </Grid>
      )}
      <footer
        style={{
          bottom: 0,
          left: 0,
          right: 0,
          margin: '0 auto',
          paddingTop: '10px',
          paddingBottom: '10px',
        }}
      >
        <Box
          mb={1}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          display="flex"
          alignSelf="flex-end"
        >
          <Typography
            sx={{
              color: '#718096',
            }}
            variant="caption"
          >
            Copyright © Unyte Health Inc {new Date().getFullYear()}
          </Typography>
          <Stack spacing={1} direction="row">
            <a
              target="_blank"
              rel="noreferrer"
              href={LINKS.invitation.termsOfService}
              className="no-underline"
            >
              <Typography
                variant="caption"
                sx={{
                  color: '#718096',
                }}
              >
                Terms & Conditions
              </Typography>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href={LINKS.invitation.privacyNotice}
              className="no-underline"
            >
              <Typography
                variant="caption"
                sx={{
                  color: '#718096',
                  textDecorationLine: 'none',
                }}
              >
                Privacy Notice
              </Typography>
            </a>
          </Stack>
        </Box>
      </footer>
    </Grid>
  )
}
