import React, { useState } from 'react'
import { gql } from '@apollo/client'
import { useMutation } from 'utils/apollo'
import useForm from 'components/form/useForm'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  FormHelperText,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
} from 'components'
import { Button } from '@mui/material'
import { useOutletContext } from 'react-router'
import { ASSIGN_SLOT_TO_USER } from 'views/users/clients/queries'
import { useSelector } from 'react-redux'
import { LoadingModal } from 'components/LoadingPage'
import { maskValidationMessage } from 'utils/apollo/errors'

const CREATE_USER = gql`
  mutation CreateUser($user: CreateUserInput!) {
    createUser(user: $user) {
      id
    }
  }
`

export default function CreateUser({ roles = [], showModal, setModalState }) {
  const [createUser] = useMutation(CREATE_USER)
  const [assignSlotToUser] = useMutation(ASSIGN_SLOT_TO_USER)
  const [error, setError] = useState({ state: false, message: '' })
  const { showNewSubscriptionPlan, hasSspProducts, hasFocusProducts } = useSelector(
    (state) => state.ff
  )

  const {
    slotsInfo,
    refetchOne,
    setLoading,
    setLoadingMessage,
    loading,
    loadingMessage,
    setOpenLicensePrompt,
    hasCompletedSspCertification,
    hasCompletedFocusCertification,
  } = useOutletContext()

  const { form, setFormValue, errors, pending, isValid, onSubmit, reset, trimmedForm } = useForm({
    data: {
      firstName: '',
      lastName: '',
      email: '',
      roles: [...roles],
    },
    validation: {
      firstName: {
        required: { msg: 'First name is required.' },
        len: {
          args: [null, 100],
          msg: 'First name must be no longer than 100 characters',
        },
      },
      lastName: {
        required: { msg: 'Last name is required.' },
        len: {
          args: [null, 100],
          msg: 'Last name must be no longer than 100 characters',
        },
      },
      email: {
        len: { args: [null, 100], msg: 'Email must be no longer than 100 characters.' },
        email: { msg: 'Please enter a valid email address.' },
      },
      roles: {
        custom: {
          args: {
            fn: (val, args) => (val || []).length > 0,
          },
          msg: 'At least one role must be present',
        },
      },
    },
    async onSubmit() {
      setLoading(true)
      setLoadingMessage('creating client...')
      setError({ state: false, message: '' })
      try {
        const {
          data: {
            createUser: { id: userId },
          },
        } = await createUser({ variables: { user: trimmedForm } })

        // get the first slot found and assign to user
        const sspSlotId = slotsInfo.ssp.find((slot) => slot.category === 'ssp')?.id
        const focusSlotId = slotsInfo.focus.find((slot) => slot.category === 'focus')?.id
        if (switchTypes.ssp && sspSlotId) {
          await assignSlotToUser({
            variables: {
              userId,
              productCategory: 'ssp',
            },
          })
        }
        if (switchTypes.focus && focusSlotId) {
          await assignSlotToUser({
            variables: {
              userId,
              productCategory: 'focus',
            },
          })
        }
        await setModalState(false)
        await refetchOne({ filter: { ids: [userId] } })

        await setTimeout(() => {
          setSwitchTypes({ ssp: false, focus: false })
          setOpenLicensePrompt(false)
          reset()
        }, 1500)
      } catch (errors) {
        const message = maskValidationMessage(errors, 'CreateUser')
        setError({ state: true, message })
      } finally {
        setTimeout(() => {
          setLoading(false)
          setLoadingMessage()
        }, 3000)
      }
    },
  })

  const [switchTypes, setSwitchTypes] = useState({ ssp: false, focus: false })
  const handleSwitch = (type) => (event) => {
    setSwitchTypes({ ...switchTypes, [type]: event.target.checked })
  }

  return (
    <Dialog
      open={showModal}
      onClose={() => {
        setModalState(false)
        reset()
      }}
    >
      {roles.includes('client') ? (
        <DialogTitle>Add Client - In-Person Delivery</DialogTitle>
      ) : (
        <DialogTitle>Add user</DialogTitle>
      )}
      <DialogContent>
        {roles.includes('client') && (
          <FormHelperText>
            With In-Person Delivery, the Client will not have direct access to the MyUnyte platform
            and the Unyte-iLs app.
          </FormHelperText>
        )}
        {errors.none && (
          <FormHelperText className="pb-2 mt-0" error={true}>
            {errors.none}
          </FormHelperText>
        )}

        {!errors.none && error.state && (
          <FormHelperText className="pb-2 mt-0" error={true}>
            {error.message}
          </FormHelperText>
        )}

        <TextField
          value={form.firstName}
          fullWidth
          onChange={setFormValue('firstName')}
          label="First name*"
          error={errors.firstName}
          className="mb-3"
        />
        <TextField
          value={form.lastName}
          fullWidth
          onChange={setFormValue('lastName')}
          label="Last name*"
          error={errors.lastName}
          className="mb-3"
        />
        {!roles.includes('client') && (
          <TextField
            value={form.email}
            fullWidth
            onChange={setFormValue('email')}
            label="Email"
            error={errors.email}
            className="mb-3"
          />
        )}
        {roles.length > 1 && (
          <FormControl className="mb-2" style={{ width: '100%' }}>
            <InputLabel htmlFor="select-roles">Roles</InputLabel>
            <Select
              multiple
              value={form.roles}
              onChange={setFormValue('roles')}
              style={{ marginTop: 20 }}
              inputProps={{
                id: 'select-roles',
              }}
            >
              {roles.map((role) => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error>{errors.roles}</FormHelperText>
          </FormControl>
        )}
        {showNewSubscriptionPlan && (
          <TableContainer>
            <Table aria-label="slots table">
              <TableHead>
                <TableRow>
                  <TableCell>Program</TableCell>
                  <TableCell align="center">Enable For This Client</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hasSspProducts && hasCompletedSspCertification && (
                  <TableRow>
                    <TableCell>SSP</TableCell>
                    <TableCell align="center">
                      {!!slotsInfo.ssp.length && (
                        <Switch checked={switchTypes.ssp} onChange={handleSwitch('ssp')} />
                      )}
                      {!slotsInfo.ssp.length && 'No available licenses'}
                    </TableCell>
                  </TableRow>
                )}
                {hasFocusProducts && hasCompletedFocusCertification && (
                  <TableRow>
                    <TableCell>ILS</TableCell>
                    <TableCell align="center">
                      {!!slotsInfo.focus.length && (
                        <Switch checked={switchTypes.focus} onChange={handleSwitch('focus')} />
                      )}
                      {!slotsInfo.focus.length && 'No available licenses'}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          disabled={pending}
          onClick={() => {
            setModalState(false)
            reset()
          }}
        >
          Cancel
        </Button>
        <Button disabled={!isValid || pending} type="submit" color="primary" onClick={onSubmit}>
          Add Client
        </Button>
      </DialogActions>
      {loading && <LoadingModal text={loadingMessage} />}
    </Dialog>
  )
}
