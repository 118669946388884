/**
 * TODO: whenever we call reset, also reset all productIds
 */
import React, { useRef, useState } from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import get from 'lodash/get'
import { useSelector } from 'react-redux'
import { useOutletContext } from 'react-router'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormHelperText,
  Button,
  FormControl,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
} from '@mui/material'
import useForm from 'components/form/useForm'
import ROLES from 'utils/constants/roles'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { LICENSES_QUERY, ASSIGN_SLOT_TO_INVITATION } from 'views/users/clients/queries'
import { maskValidationMessage } from 'utils/apollo/errors'

const CREATE_INVITATION = gql`
  mutation createInvitation($invitation: CreateInvitationInput!) {
    createInvitation(invitation: $invitation) {
      id
      userId
      firstName
      lastName
      toEmail
      roles
    }
  }
`

export default function CreateInvitation({ roles, showModal, setModalState }) {
  const { enqueueSnackbar } = useSnackbar()

  // context states
  const {
    slotsInfo,
    setOpenLicensePrompt,
    refetchSlots,
    hasCompletedSspCertification,
    hasCompletedFocusCertification,
  } = useOutletContext()

  const {
    showNewSubscriptionPlan,
    hasSspProducts: hasSspProductsFF,
    hasFocusProducts: hasFocusProductsFF,
  } = useSelector((state) => state.ff)

  // component states
  const modalRef = useRef()
  const [productIds, setProductIds] = useState({})
  const [products, setProducts] = useState([])

  const resetProductIds = () => {
    setProductIds({})
  }

  // gql
  const [createInvitation] = useMutation(CREATE_INVITATION, {
    async update(cache) {
      cache.reset()
      resetProductIds()
      enqueueSnackbar('Invitation was successfully sent', {
        variant: 'success',
        action: CloseSnackbarAction,
      })
    },
  })
  const [assignSlotToInvitation] = useMutation(ASSIGN_SLOT_TO_INVITATION)

  const [error, setError] = useState({ state: false, message: '' })
  const { form, setFormValue, pending, errors, onSubmit, isValid, reset, trimmedForm } = useForm({
    data: {
      firstName: '',
      lastName: '',
      toEmail: '',
      roles: [roles[0]],
      productIds: null,
    },
    validation: {
      toEmail: {
        required: { msg: 'Email is required.' },
        email: { msg: 'Please enter a valid email address.' },
        len: { args: [null, 100], msg: 'Email must be no longer than 100 characters.' },
      },
      firstName: {
        len: {
          args: [null, 100],
          msg: 'First name must be no longer than 100 characters',
        },
      },
      lastName: {
        len: {
          args: [null, 100],
          msg: 'Last name must be no longer than 100 characters',
        },
      },
      roles: {
        custom: {
          args: {
            fn: (val, args) => (val || []).length > 0,
          },
          msg: 'At least one role must be present',
        },
      },
    },
    /**
     * 1. create invitation
     * 2. update slot
     */
    async onSubmit(e) {
      e.preventDefault()
      setError({ state: false, message: '' })
      try {
        const newForm = {
          ...trimmedForm,
          productIds: Object.keys(productIds)
            .filter((key) => productIds[key])
            .join(','),
        }
        // current user is not created yet, so we don't update slots here
        const res = await createInvitation({ variables: { invitation: newForm } })

        if (showNewSubscriptionPlan) {
          // we need invitation id here because user Id is provider
          const invitationId = get(res, 'data.createInvitation.id', null)

          const sspSlotId = slotsInfo.ssp.find(
            ({ category, status }) => category === 'ssp' && status === 'available'
          )?.id
          const focusSlotId = slotsInfo.focus.find(
            ({ category, status }) => category === 'focus' && status === 'available'
          )?.id

          const hasFocusProducts = products.some(
            ({ id, category }) => productIds[id] && category === 'focus'
          )
          const hasSspProducts = products.some(
            ({ id, category }) => productIds[id] && category === 'ssp'
          )
          if (hasSspProducts && sspSlotId) {
            await assignSlotToInvitation({
              variables: {
                invitationId,
                productCategory: 'ssp',
              },
            })
          }
          if (hasFocusProducts && focusSlotId) {
            await assignSlotToInvitation({
              variables: {
                invitationId,
                productCategory: 'focus',
              },
            })
          }
        }

        // update slots
        setModalState(false)
        refetchSlots()
        reset()
        setOpenLicensePrompt(false)
        resetProductIds()
      } catch (errors) {
        const message = maskValidationMessage(errors, 'suspend')
        modalRef.current.querySelector('.MuiPaper-root[role="dialog"]').scrollTop = 0
        setError({ state: true, message })
      }
    },
  })

  useQuery(LICENSES_QUERY, {
    variables: {
      filter: {
        isActive: true,
      },
      sort: [['product', 'order', 'ASC']],
    },
    onCompleted: (data) => {
      const products = get(data, 'getLicenses', [])
        .map(({ product }) => {
          const isAssessment = get(product, 'category', '') === 'assessment'
          if (!isAssessment) {
            return { ...product }
          }
          return null
        })
        .filter(Boolean)
      setProducts(products)
    },
  })

  const handleSwitch = (productId) => (event) => {
    setProductIds({ ...productIds, [productId]: event.target.checked })
  }

  const handleCloseDialog = () => {
    setModalState(false)
    reset()
    resetProductIds()
  }

  return (
    <Dialog open={showModal} ref={modalRef} onClose={handleCloseDialog}>
      <form onSubmit={onSubmit} className="overflow-y-scroll">
        {roles.includes('client') ? (
          <DialogTitle>Add Client - Remote Delivery</DialogTitle>
        ) : (
          <DialogTitle>Send Invitation</DialogTitle>
        )}
        <DialogContent>
          {roles.includes('client') && (
            <FormHelperText>
              With Remote Delivery, the Client will have controlled access to the MyUnyte platform
              and the Unyte-iLs app.
            </FormHelperText>
          )}
          {errors.none && (
            <FormHelperText className="pb-2 mt-0" error>
              {errors.none}
            </FormHelperText>
          )}
          {!errors.none && error.state && (
            <FormHelperText className="pb-2 mt-0" error>
              {error.message}
            </FormHelperText>
          )}

          <TextField
            value={form.firstName}
            fullWidth
            onChange={setFormValue('firstName')}
            label="First name"
            error={errors.firstName}
            className="mb-3"
            inputProps={{
              form: {
                autoComplete: 'off',
              },
            }}
          />
          <TextField
            value={form.lastName}
            fullWidth
            onChange={setFormValue('lastName')}
            label="Last name"
            error={errors.lastName}
            className="mb-3"
            inputProps={{
              form: {
                autoComplete: 'off',
              },
            }}
          />
          <TextField
            value={form.toEmail}
            fullWidth
            onChange={setFormValue('toEmail')}
            label="Email*"
            error={errors.toEmail}
            className="mb-3"
          />
          {roles.length > 1 && (
            <FormControl className="mb-2" style={{ width: '100%' }}>
              <InputLabel htmlFor="select-roles">Roles</InputLabel>
              <Select
                multiple
                value={form.roles}
                onChange={setFormValue('roles')}
                style={{ marginTop: 20 }}
                inputProps={{
                  id: 'select-roles',
                }}
              >
                {roles.map((role) => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>{errors.roles}</FormHelperText>
            </FormControl>
          )}
          {roles.includes('client') && (
            <TableContainer>
              <Table aria-label="slots table">
                <TableHead>
                  <TableRow>
                    <TableCell>Program</TableCell>
                    <TableCell align="center">Enable For This Client</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {showNewSubscriptionPlan &&
                    hasSspProductsFF &&
                    !slotsInfo.ssp.length &&
                    hasCompletedSspCertification && (
                      <TableRow>
                        <TableCell>SSP</TableCell>
                        <TableCell align="center">No available licenses</TableCell>
                      </TableRow>
                    )}
                  {showNewSubscriptionPlan &&
                    hasFocusProductsFF &&
                    !slotsInfo.focus.length &&
                    hasCompletedFocusCertification && (
                      <TableRow>
                        <TableCell>ILS</TableCell>
                        <TableCell align="center">No available licenses</TableCell>
                      </TableRow>
                    )}
                  {products
                    .filter(
                      (product) =>
                        !showNewSubscriptionPlan ||
                        (product.category === 'ssp' && slotsInfo.ssp.length) ||
                        (product.category === 'focus' && slotsInfo.focus.length)
                    )
                    .map(({ name, id }) => (
                      <TableRow key={`product${id}`}>
                        <TableCell>{name}</TableCell>
                        <TableCell align="center">
                          <Switch onChange={handleSwitch(id)} />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {roles.includes(ROLES.CLIENT) && (
            <Typography style={{ marginTop: '15px' }}>
              {' '}
              <strong> Note: </strong> This will send an email invitation to your Client.{' '}
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          <Button color="secondary" disabled={pending} onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button disabled={!isValid} type="submit" color="primary" loading={pending}>
            {roles.includes('client') ? 'Add Client' : 'Invite'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
