import React from 'react'
import { Button, Grid, Stack, Typography, Box } from 'components'
import focusActivities from '../utils/constants/focusActivities'
import Stepper from 'components/Stepper'

const FocusActivity = ({ name, printLayoutEnabled }) => {
  const {
    activityName,
    activityVideoSrc,
    steps,
    category,
    easierOption,
    harderOption,
    cognitiveChallenge,
    rationale,
  } = focusActivities.find(({ activityName }) => activityName === name)

  const exerciseModes = [
    {
      title: 'Make it easier:',
      options: easierOption,
    },
    {
      title: 'Make it harder:',
      options: harderOption,
    },
    {
      title: 'Cognitive challenge:',
      options: cognitiveChallenge,
    },
    {
      title: 'Rationale',
      options: rationale,
    },
  ]

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          width: '95%',
          margin: '0px',
          paddingBottom: '30px',
        }}
      >
        <Grid item xs={12} md={6}>
          <Box p={1}>
            {printLayoutEnabled && (
              <div style={{ display: 'inline-flex' }}>
                <img
                  src="/images/unyte-logo.png"
                  alt="Unyte"
                  className="mr-5"
                  style={{ width: '50px' }}
                />
                <Typography className="pt-6" variant="h4">
                  Activity Library
                </Typography>
              </div>
            )}
            <Typography variant="h3">{activityName}</Typography>
            <Typography variant="body2" className="mb-6">
              <strong>Category:</strong> {category} {/* | <strong>Age:</strong> {age} */}
            </Typography>
            <Typography variant="h6" className="mb-3">
              Steps
            </Typography>
            <Stepper
              steps={steps.map((step) => (
                <Typography variant="body1">{step}</Typography>
              ))}
              connector={null}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
          <Grid container direction="column">
            {!printLayoutEnabled && (
              <iframe
                src={activityVideoSrc}
                width="100%"
                height="600px"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                title="focusActivityVideo"
              />
            )}
            <Typography
              className="pl-2"
              variant={printLayoutEnabled ? 'body1' : 'caption'}
              align={printLayoutEnabled ? 'left' : 'center'}
            >
              <strong>Approved by Unyte's Clinical Team.</strong>
            </Typography>
          </Grid>
        </Grid>

        <Grid className="pl-2" container direction="row" alignItems="flex-start">
          <Grid item xs={12} md={12}>
            <Box p={1}>
              <Typography variant="h6">Modifications</Typography>
            </Box>
          </Grid>
          {exerciseModes.map(({ title, options }, key) => {
            if (!options) return null
            return (
              <Grid item xs={12} md key={key}>
                <Box p={1}>
                  <Typography variant="body1" className="mb-3">
                    <strong>{title}</strong>
                  </Typography>
                  {options.map((option, key) => {
                    return (
                      <Typography key={key} variant="body1" className="mb-3">
                        {option}
                      </Typography>
                    )
                  })}
                </Box>
              </Grid>
            )
          })}
        </Grid>
        <Grid item xs={12} pt={5}>
          <Typography variant="body2" width="100%" textAlign="center">
            To create a personalized ILS activity set, pair this activity with one activity of each
            category below
          </Typography>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            width="100%"
            justifyContent="center"
            spacing={2}
          >
            <Button href="/resources/activity-library?tags=Regulation" size="large">
              <Typography variant="button">Regulation</Typography>
            </Button>
            <Button href="/resources/activity-library?tags=Gross+Motor" size="large">
              <Typography variant="button">Gross Motor</Typography>
            </Button>
            <Button href="/resources/activity-library?tags=Fine+Motor" size="large">
              <Typography variant="button">Fine Motor</Typography>
            </Button>
            <Button href="/resources/activity-library?tags=Visual+Motor" size="large">
              <Typography variant="button">Visual Motor</Typography>
            </Button>
            <Button href="/resources/activity-library?tags=Early+Development" size="large">
              <Typography variant="button">Early Development</Typography>
              <Typography
                variant="caption"
                textTransform="lowercase"
                pl={1}
                pb="1px"
                alignSelf="end"
              >
                (if applicable)
              </Typography>
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}

export default FocusActivity
