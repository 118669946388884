import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { extendUser } from 'store/modules/auth'
import { useSnackbar } from 'notistack'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { Auth } from 'aws-amplify'
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
} from '@mui/material'

export default function UploadAvatarButton({ setLoading }) {
  const [token, setToken] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)
  const [file, setFile] = useState(null)
  const [avatar, setAvatar] = useState(null)

  const user = useSelector((state) => state?.auth?.user)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const openMenu = Boolean(anchorEl)

  useEffect(() => {
    const getTokenAndSetToState = async () => {
      const currentSession = await Auth.currentSession()
      const accessToken = currentSession.getIdToken()
      const token = 'Bearer ' + accessToken.getJwtToken()
      setToken(token)
    }
    getTokenAndSetToState()
  }, [])

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleSubmit = async (_file) => {
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append('avatar', _file)

      const changeAvatarUrl = process.env.REACT_APP_IMAGE_HANDLER_URL + '/upload/avatar'
      const result = await fetch(changeAvatarUrl, {
        method: 'POST',
        headers: {
          Authorization: token,
        },
        body: formData,
      })

      const data = await result.json()
      if (data?.avatar) {
        dispatch(
          extendUser({
            ...user,
            avatar: data.avatar,
            avatarThumb: data.avatarThumb,
          })
        )
        handleClose()
        setFile()
        setAvatar()
      } else {
        throw new Error('Invalid response')
      }
    } catch (error) {
      console.error(error)
      enqueueSnackbar('Sorry but this image format/size is not supported', {
        variant: 'error',
        action: CloseSnackbarAction,
      })
    } finally {
      setLoading(false)
      setAnchorEl(null)
    }
  }

  const handleDeleteAvatar = async () => {
    try {
      setLoading(true)

      const deleteAvatarUrl = process.env.REACT_APP_IMAGE_HANDLER_URL + '/avatar'
      const result = await fetch(deleteAvatarUrl, {
        method: 'DELETE',
        headers: {
          Authorization: token,
        },
      })

      const data = await result.json()
      if (data?.message === 'ok') {
        dispatch(
          extendUser({
            ...user,
            avatar: null,
            avatarThumb: null,
          })
        )
      } else {
        throw new Error('Invalid response')
      }
    } catch (error) {
      console.error(error)
      enqueueSnackbar('Failed to remove avatar. Please try again later', {
        variant: 'error',
        action: CloseSnackbarAction,
      })
    } finally {
      setLoading(false)
      setAnchorEl(null)
    }
  }
  const handleClickOpen = (event) => {
    if (!user.avatar) {
      setOpen(true)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setAvatar()
  }

  // Handle file change
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]
    setFile(selectedFile)

    const reader = new FileReader()
    reader.onload = () => setAvatar(reader.result)
    reader.readAsDataURL(selectedFile)

    return selectedFile
  }

  // upload then immediately submit..
  const handleChangeAvatar = async (event) => {
    const _file = await handleFileChange(event)
    await handleSubmit(_file)
  }

  return (
    <>
      <Button id="open-modal" onClick={handleClickOpen}>
        Change
      </Button>
      <input
        type="file"
        accept="image/*"
        id="avatar-upload-menu"
        style={{ display: 'none' }}
        onChange={handleChangeAvatar}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => setOpen(true)}>Change</MenuItem>
        <MenuItem onClick={handleDeleteAvatar}>Delete</MenuItem>
      </Menu>
      <input
        type="file"
        accept="image/*"
        id="avatar-upload"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Change Profile Picture</DialogTitle>
        <DialogContent>
          <Box sx={{ padding: '0.5rem', width: '100%' }}>
            <Avatar alt="User Avatar" src={avatar} sx={{ width: 80, height: 80, margin: 'auto' }} />
          </Box>
          <DialogContentText id="alert-dialog-description">
            Accepted image formats are jpeg, jpg, png, and gif. <br />
            Maximum file size is 8MB.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <label htmlFor="avatar-upload">
            <Button color="primary" aria-label="upload picture" component="span">
              {!avatar ? 'Upload Picture' : 'Change'}
            </Button>
          </label>
          {avatar && file && (
            <Button onClick={() => handleSubmit(file)} autoFocus>
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}
