import React from 'react'
import { useStripe, useElements, CardElement, Elements } from '@stripe/react-stripe-js'
import { useSnackbar } from 'notistack'
import {
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Grid,
} from 'components'

import loadStripe from 'utils/loadStripe'
import { useQuery, useMutation } from 'utils/apollo'
import { gql } from '@apollo/client'
import CardInput from './CardInput'

import {
  setError,
  setShowError,
  setDisable,
  setPending,
  setMakeDefault,
} from 'store/modules/credit-card'
import { useSelector, useDispatch } from 'react-redux'
import CloseSnackbarAction from 'components/CloseSnackbarAction'

const GET_STRIPE_CLIENT_SECRET = gql`
  query GetStripeSecret {
    getStripeClientSecret
  }
`

export const UPDATE_BILLING = gql`
  mutation UpdateBilling($stripe: String) {
    updateBilling(stripeDefaultPaymentMethodId: $stripe)
  }
`

const StripeCardInput = ({
  open,
  setOpen,
  refetch,
  authUpdatePurchaseObject = {},
  authUpdatePurchase,
  hasShipping,
  isCardsEmpty,
  setLoadingText = () => {},
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()
  const [updateBilling] = useMutation(UPDATE_BILLING)
  const { disable, pending, makeDefault } = useSelector((state) => state.creditCard)

  const { data: getStripeClientSecret, loading: stripeLoading, refetch: refetchSecret } = useQuery(
    GET_STRIPE_CLIENT_SECRET
  )

  const onSubmit = async (e) => {
    e.preventDefault()
    setLoadingText('saving card...')
    try {
      dispatch(setPending(true))
      dispatch(setDisable(true))

      const { setupIntent, error: stripeError } = await stripe.confirmCardSetup(
        getStripeClientSecret.getStripeClientSecret.stripeClientSecret,
        {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        }
      )

      if (stripeError) {
        const stripeErrorCode = `Code: ${
          stripeError.decline_code ? stripeError.decline_code : stripeError.code
        }.`
        const returnError = stripeError.message ? `${stripeError.message} ${stripeErrorCode}` : null

        dispatch(setError(returnError))
        dispatch(setShowError(true))
      } else {
        if (isCardsEmpty) {
          dispatch(setMakeDefault(true))
        }

        // only update default if you're on the billing page
        if (makeDefault) {
          await updateBilling({
            variables: {
              stripe: setupIntent.payment_method,
            },
          })
        }
        enqueueSnackbar('Card successfully saved.', {
          variant: 'success',
          action: CloseSnackbarAction,
        })
        await setOpen(false)
        await refetch()
      }

      await refetchSecret()
    } catch (e) {
      console.error(e)
      enqueueSnackbar('Server error.. Please try again later.', {
        variant: 'error',
        action: CloseSnackbarAction,
      })
    } finally {
      dispatch(setPending(false))
      dispatch(setDisable(false))
    }
  }

  const handleClose = () => {
    setOpen(false)
    dispatch(setShowError(false))
  }

  const handleChange = () => {
    dispatch(setMakeDefault(!makeDefault))
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-update-cc"
      fullWidth={true}
      maxWidth="xs"
    >
      <DialogTitle>Add Payment Method</DialogTitle>
      <Divider />
      <DialogContent sx={{ pb: 0, width: '10rem' }}>
        <CardInput
          onChange={handleChange}
          internalPurchase
          hasShipping={hasShipping}
          authUpdatePurchase={authUpdatePurchase}
          authUpdatePurchaseObject={authUpdatePurchaseObject}
          setLoadingText={setLoadingText}
        />
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between" className="px-5">
          <Grid item className="py-1">
            <FormControlLabel
              control={
                <Checkbox
                  checked={makeDefault || isCardsEmpty}
                  onChange={handleChange}
                  disabled={isCardsEmpty}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Make Default"
            />
          </Grid>
          <Grid item className="py-2">
            <Button onClick={handleClose} disableElevation color="secondary" className="mx-3">
              Cancel
            </Button>
            <Button
              onClick={onSubmit}
              loading={!stripe || !elements || pending || stripeLoading}
              disabled={disable}
              variant="contained"
              disableElevation
              color="primary"
            >
              Save card
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default (props) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY)

  return (
    <Elements stripe={stripePromise}>
      <StripeCardInput {...props} />
    </Elements>
  )
}
