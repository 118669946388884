import React, { useEffect, useState } from 'react'

import { Button, Grid, Stack, TextField } from '@mui/material'
import MuiPhoneNumber from 'mui-phone-number'
import get from 'lodash/get'

import SelectCountry from 'components/ecommerce/shipping/SelectCountry'
import SelectState from 'components/ecommerce/shipping/SelectState'
import { getCountries, getStates } from 'utils/constants/getIsoRegion'

const REQUIRED_FIELDS = ['locationName', 'address1', 'country', 'state', 'city', 'postalCode']

export function AddressForm({ value, onSave, onCancel, onRemove, showRemoveButton }) {
  const {
    form,
    disabled,
    errors,
    handleFormChange,
    handleCountryChange,
    handleStateChange,
    handlePhoneNumberChange,
    handleSave,
  } = useAddressForm({ value, onSave })

  // only hide location name when it is business address on load
  const [hideLocationName, setHideLocationName] = useState(false)
  useEffect(() => {
    setHideLocationName(form.locationName === 'Business Address')
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Stack direction="column" alignItems="flex-start" pt={2}>
        <Stack direction="column" className="w-full">
          {!hideLocationName && (
            <TextField
              label="Location Name"
              placeholder="Location/practice name eg. Location 1, Downtown location"
              value={form.locationName}
              onChange={handleFormChange('locationName')}
              error={errors.locationName}
            />
          )}
          <TextField
            label="Address"
            placeholder="Address"
            value={form.address1 || ''}
            onChange={handleFormChange('address1')}
            error={errors.address1}
          />
          <TextField
            label="Apartment, suite, etc. (optional)"
            placeholder="Apartment, suite, etc. (optional)"
            value={form.address2 || ''}
            onChange={handleFormChange('address2')}
            error={errors.address2}
          />
          <Grid item sx={{ marginBottom: '4px', marginTop: '8px' }}>
            <SelectCountry
              formClassName="w-full"
              label="Country"
              showFullName
              value={form.country}
              onChange={handleCountryChange}
              error={errors.country}
            />
          </Grid>
          <Grid container direction="row" alignItems="flex-end" justifyContent="space-between">
            <Grid item sx={{ flex: 1, maxWidth: '32%', marginBottom: '4px', marginTop: '8px' }}>
              <SelectState
                label="State/Province/Region"
                showFullStateName
                country={form.country}
                value={form.state}
                onChange={handleStateChange}
                error={errors.state}
              />
            </Grid>
            <Grid item sx={{ flex: 1, maxWidth: '32%' }}>
              <TextField
                className="w-full"
                label="City"
                placeholder="City"
                value={form.city || ''}
                onChange={handleFormChange('city')}
                error={errors.city}
              />
            </Grid>
            <Grid item sx={{ flex: 1, maxWidth: '32%' }}>
              <TextField
                className="w-full"
                label="Zip/Postal Code"
                placeholder="Zip/Postal Code"
                value={form.postalCode || ''}
                onChange={handleFormChange('postalCode')}
                error={errors.postalCode}
              />
            </Grid>
          </Grid>
          {!hideLocationName && (
            <MuiPhoneNumber
              variant="outlined"
              label="Phone (optional)"
              placeholder="Phone (optional)"
              value={form.phoneNumber || ''}
              onChange={handlePhoneNumberChange}
              name="phonemask"
              disableAreaCodes
              preferredCountries={['ca', 'us']}
              defaultCountry={'us'}
            />
          )}
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center" m={2}>
        <Stack direction="row" spacing={4}>
          <Button onClick={handleSave} disabled={disabled}>
            Save
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
        </Stack>
        {showRemoveButton && (
          <Button color="secondary" onClick={onRemove} sx={{ mr: 1 }}>
            Remove
          </Button>
        )}
      </Stack>
    </>
  )
}

export const useAddressForm = ({ value, onSave }) => {
  const [form, setForm] = useState({
    isInPersonLocation: 'No',
    ...value,
  })
  const [disabled, setDisabled] = useState(false)
  const [errors, setErrors] = useState({})

  const handleFormChange = (fieldName) => (e) => {
    if (errors[fieldName]) {
      setDisabled(false)
      setErrors({ ...errors, [fieldName]: undefined })
    }

    setForm({ ...form, [fieldName]: e.target.value })
  }

  const handleCountryChange = (e) => {
    setDisabled(false)
    setErrors({
      ...errors,
      country: undefined,
      isoCountry: undefined,
      state: undefined,
      isoState: undefined,
    })

    const isoCountry = getCountries().find((country) => country.name === e.target.value)?.code
    setForm({
      ...form,
      country: e.target.value,
      isoCountry,
      state: undefined,
      isoState: undefined,
    })
  }

  const handleStateChange = (e) => {
    setErrors({
      ...errors,
      state: undefined,
      isoState: undefined,
    })
    setDisabled(false)

    const isoState = getStates(form.isoCountry).find((state) => state.name === e.target.value)
      ?.subCode
    setForm({ ...form, state: e.target.value, isoState })
  }

  const handlePhoneNumberChange = (e) => {
    setForm({ ...form, phoneNumber: e })
  }

  const handleSave = () => {
    // if no and in person selected, show error

    const formErrors = REQUIRED_FIELDS.reduce((prev, curr) => {
      if (!form[curr]) {
        return { ...prev, [curr]: true }
      }
      return prev
    }, {})

    if (Object.keys(formErrors).length) {
      setDisabled(true)
      setErrors(formErrors)
    } else {
      const res = onSave(form)
      const errorMessage = get(res, 'errors', {})
      setErrors({ ...errorMessage })
    }
  }

  return {
    form,
    setForm,
    disabled,
    setDisabled,
    errors,
    setErrors,
    handleFormChange,
    handleCountryChange,
    handleStateChange,
    handlePhoneNumberChange,
    handleSave,
  }
}
