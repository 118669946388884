const SET_ORGANIZATION_VALUE = 'ORGANIZATION/SET_ORGANIZATION_VALUE'
const RESET_ORGANIZATION = 'ORGANIZATION/RESET_ORGANIZATION'

const initialState = {
  // SSP
  hasOrgSspCertificationCompleted: false,
  hasOrgSspCertificationEnrolled: false,
  hasPaidSspSubscription: false,
  hasUnpaidSspSubscription: false,
  hasPastDueSspSubscription: false,
  hasPendingSspSubscription: false,
  hasAllCanceledSspSubscription: false,

  // ILS
  hasFocusSubscription: false,
  hasOrgFocusCertificationCompleted: false,
  hasOrgFocusCertificationEnrolled: false,
  hasPaidFocusSubscription: false,
  hasUnpaidFocusSubscription: false,
  hasPastDueFocusSubscription: false,
  hasPendingFocusSubscription: false,
  hasAllCanceledFocusSubscription: false,

  // Other
  hasAssessmentProducts: false,
  organizationProfileInfo: {},
  numberOfProviders: 0,
  refetch: () => {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ORGANIZATION_VALUE:
      return {
        ...state,
        ...action.payload,
      }
    case RESET_ORGANIZATION:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export function setOrganizationValue(payload) {
  return {
    type: SET_ORGANIZATION_VALUE,
    payload,
  }
}

export function resetOrganization() {
  return {
    type: RESET_ORGANIZATION,
  }
}
