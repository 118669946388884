import React, { FC, ReactNode } from 'react'
import MuiDialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

interface DialogTitleWithCloseProps {
  children: ReactNode
  onClose?: () => void
}

const DialogTitleWithClose: FC<DialogTitleWithCloseProps> = (props) => {
  const { children, onClose, ...other } = props
  return (
    <MuiDialogTitle {...other}>
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="large"
          sx={{
            position: 'absolute',
            right: '8px',
            top: '8px',
            color: '#9e9e9e',
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

export default DialogTitleWithClose
