import React, { useContext, useState } from 'react'

import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Card as MuiCard,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  capitalize,
} from '@mui/material'

import formatAddress from 'utils/formatAddress'

import { AddressForm } from './AddressForm'
import { format } from 'date-fns'
import { DATE_FORMATS } from 'utils/constants/formats'
import { ProviderProfileContext } from './MyProfile'
import { useOnValueChange } from 'utils/hooks/useOnValueChange'

import get from 'lodash/get'
import RadioGroupMessage from './components/radio-group-message'

export const IN_PERSON_LOCATIONS_MAP = {
  Yes: 'I see clients in-person here',
  No: 'I do not see clients in-person here',
}

export function AddressList({ inPersonSelected = false, handleChange, handleSave, handleCancel }) {
  // providerProfile has default values on load
  // form has dynamic state values
  const { providerProfileInfo, wizardState } = useContext(ProviderProfileContext)
  const deliveryModels = get(providerProfileInfo, 'deliveryModels', [])
  const _lastUpdatedAt = get(providerProfileInfo, 'addressesUpdatedAt', null)
  const providerProfileAddressesLastUpdatedAt = get(providerProfileInfo, 'addressesUpdatedAt', null)
  const allAddresses = get(providerProfileInfo, 'addresses', [])
  const _inPersonAddresses = allAddresses.filter((address) => address.isInPersonLocation === 'Yes')
  const _selectedNoAt = get(providerProfileInfo, 'addressesSelectedNoAt', null)

  // states for component
  const [lastUpdatedAt, setLastUpdatedAt] = useState()
  const [inPersonAddresses, setInPersonAddresses] = useState([])
  const [formIndex, setFormIndex] = useState(null) // formIndex always starts at the end
  const [showForm, setShowForm] = useState(false)
  const [worksInPerson, setWorksInPerson] = useState(null)

  // handlers
  const handleFormSave = (form) => {
    const currentAddressForm = { ...form, isInPersonLocation: 'Yes' }
    const newAddresses =
      formIndex === allAddresses.length
        ? [...allAddresses, currentAddressForm] // add new address
        : allAddresses.map((address, idx) => (idx === formIndex ? currentAddressForm : address)) // update existing

    setShowForm(false)
    handleChange(null, newAddresses)

    // if we are able to save, then delivery model will always have in person
    const newDeliveryModels = deliveryModels.includes('In Person')
      ? deliveryModels
      : [...deliveryModels, 'In Person']

    handleSave({
      addresses: newAddresses,
      deliveryModels: newDeliveryModels,
    })
  }

  const handleEditAddress = (index) => () => {
    setFormIndex(index)
    setShowForm(true)
  }

  const handleAddNewAddress = () => {
    setFormIndex(allAddresses.length)
    setShowForm(true)
  }

  const handleFormCancel = () => {
    setShowForm(false)
    handleCancel()

    if (_selectedNoAt && _inPersonAddresses.length === 0) {
      handleWorksInPerson('hook')({ target: { value: 'No' } })

      // if we had no pre-exsting addresses, and didn't select no
    } else if (!_inPersonAddresses.length) {
      setWorksInPerson(null)
    }
  }

  // the reasoning here is that index === 0 is often times business address..
  const handleRemoveAddress = (index) => () => {
    const newAddresses = allAddresses.map((address, idx) =>
      idx === index ? { ...address, isInPersonLocation: 'No' } : address
    )

    // if we have no in person addresses
    const hasInPersonAddresses = !!newAddresses.find(
      (address) => address?.isInPersonLocation === 'Yes'
    )

    handleChange(null, newAddresses)
    setShowForm(false)
    const saveObj = { addresses: newAddresses }

    if (!hasInPersonAddresses) {
      const currentTime = new Date()
      const newDeliveryModels = deliveryModels.includes('Remote') ? ['Remote'] : []
      saveObj['addressesSelectedNoAt'] = currentTime.toISOString()
      saveObj['deliveryModels'] = newDeliveryModels
      setWorksInPerson('No')
    }

    handleSave(saveObj)
  }

  /**
   * from: "form" | "hook"
   * When no is selected remove all addresses except for business address..
   */
  const handleWorksInPerson = (from) => (event) => {
    setWorksInPerson(event.target.value)
    if (event.target.value === 'Yes') {
      if (from === 'form') {
        setFormIndex(allAddresses.length)
        setShowForm(true)
      }
    } else if (event.target.value === 'No') {
      setShowForm(false)

      if (from === 'form') {
        const newAddresses = allAddresses.map((address) => ({
          ...address,
          isInPersonLocation: 'No',
        }))

        // remote delivery can only be remote if we remove "In Person"
        const newDeliveryModels = deliveryModels.includes('Remote') ? ['Remote'] : []

        // handleChange(null, newAddresses)
        const currentTime = new Date()
        const obj = {
          addresses: newAddresses,
          addressesSelectedNoAt: currentTime.toISOString(),
          deliveryModels: newDeliveryModels,
        }
        handleSave(obj)
      }
    }
  }

  useOnValueChange(
    JSON.stringify({
      deliveryModels,
      _lastUpdatedAt,
      _inPersonAddresses,
      _selectedNoAt,
      providerProfileAddressesLastUpdatedAt,
      wizardState,
    }),
    () => {
      setLastUpdatedAt(_lastUpdatedAt || providerProfileAddressesLastUpdatedAt)
      setInPersonAddresses(_inPersonAddresses)

      // we need updated at so we can refresh the form
      if (
        (_lastUpdatedAt || providerProfileAddressesLastUpdatedAt) &&
        deliveryModels.includes('In Person') &&
        _inPersonAddresses.length > 0
      ) {
        handleWorksInPerson('hook')({ target: { value: 'Yes' } })
      } else if (_selectedNoAt && _inPersonAddresses.length === 0) {
        handleWorksInPerson('hook')({ target: { value: 'No' } })
      }
    }
  )

  const worksInPersonContent =
    worksInPerson === 'Yes' ? (
      'I currently work with clients in-person at a physical location.'
    ) : (
      <>
        I do <span className="underline">not</span> currently work with clients in-person at a
        physical location.
      </>
    )

  return (
    <MuiCard variant="outlined">
      <Stack direction="row" alignItems="center" justifyContent="space-between" m={2} p={2}>
        <Typography id="address-info" variant="h4">
          In-Person Delivery Info
        </Typography>
        {lastUpdatedAt && (
          <Typography
            id="address-info-last-updated-at"
            variant="caption"
            sx={{ color: 'gray !important' }}
          >
            Last Updated: {format(new Date(lastUpdatedAt), DATE_FORMATS.dateAndTime)}
          </Typography>
        )}
      </Stack>
      <Stack spacing={2} mx={4}>
        <FormControl>
          <FormLabel id="work-with-clients-in-person-label">
            <Typography variant="body2">
              Do you work with clients in-person at a physical location?
            </Typography>
          </FormLabel>
          <Stack direction="row" spacing={2} alignItems="center" my={1}>
            <RadioGroup
              aria-labelledby="work-with-clients-in-person-group-label"
              name="work-with-clients-in-person-label"
              sx={{ display: 'flex', flexDirection: 'row' }}
              value={worksInPerson}
              onChange={handleWorksInPerson('form')}
            >
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="No" control={<Radio />} label="No" />
            </RadioGroup>
            {worksInPerson && <RadioGroupMessage content={worksInPersonContent} />}
          </Stack>
        </FormControl>
      </Stack>
      <>
        <Stack direction="column" mx={2} p={2} spacing={2}>
          {!!inPersonAddresses.length && (
            <Typography variant="body2" component="h6" sx={{ fontWeight: '500 !important' }}>
              In-person delivery location/s:
            </Typography>
          )}
          {allAddresses.map((address, idx) => {
            if (!(address?.isInPersonLocation === 'Yes')) {
              return null
            }
            if (idx === formIndex && showForm) {
              return null
            }
            return (
              <Grid item key={idx}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ mb: 2 }}
                >
                  <Box sx={{ flex: 1, maxWidth: '25%' }} display="flex" justifyContent="flex-start">
                    <Stack direction="column">
                      {address.locationName && (
                        <Typography variant="body2">{capitalize(address.locationName)}</Typography>
                      )}
                    </Stack>
                  </Box>

                  <Box sx={{ flex: 1, maxWidth: '45%' }} display="flex" justifyContent="flex-start">
                    <Typography variant="body2" sx={{ color: 'gray !important' }}>
                      {formatAddress(address)}
                    </Typography>
                  </Box>

                  {!showForm && (
                    <Box sx={{ flex: 1, maxWidth: '25%' }} display="flex" justifyContent="flex-end">
                      <Button variant="text" onClick={handleEditAddress(idx)}>
                        Edit Address
                      </Button>
                    </Box>
                  )}
                </Stack>

                <Divider />
              </Grid>
            )
          })}

          {showForm && (
            <>
              <Typography variant="body2" component="h6" sx={{ fontWeight: '500 !important' }}>
                Add address for in-person delivery location:
              </Typography>
              <AddressForm
                inPersonSelected={inPersonSelected}
                value={{
                  ...allAddresses[formIndex],
                }}
                onSave={handleFormSave}
                onCancel={handleFormCancel}
                onRemove={handleRemoveAddress(formIndex)}
                // we don't want to see the remove button when adding new address
                showRemoveButton={formIndex !== allAddresses.length}
              />
            </>
          )}
        </Stack>

        {worksInPerson === 'Yes' && inPersonAddresses.length > 0 && !showForm && (
          <Box display="flex" justifyContent="flex-start" m={2} px={1}>
            <Button onClick={handleAddNewAddress}>Add Another In-Person Delivery Location</Button>
          </Box>
        )}
      </>
    </MuiCard>
  )
}
