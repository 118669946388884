import React from 'react'
import { BulletLink, DashboardCard, Typography } from './DashboardCard2'
import EMAILS from 'utils/constants/emails'
import { useOutletContext } from 'react-router'

export default function CompleteTrainingCard({
  type = '',
  hasActiveSspSubscription,
  hasActiveFocusSubscription,
  ...gridProps
}) {
  const {
    isBilling,
    hasOrgSspCertificationCompleted,
    isSspCompletedUser,
    hasOrgFocusCertificationCompleted,
    isFocusCompletedUser,
    sspInTrainingOnly,
    focusInTrainingOnly,
  } = useOutletContext()

  const isCompletedUser =
    (type === 'SSP' && isSspCompletedUser) || (type === 'ILS' && isFocusCompletedUser)
  const title = isCompletedUser
    ? `You are ${type} certified!`
    : `A Provider in your Organization is ${type} Certified!`

  const hasPaidTypeSubscription =
    (type === 'SSP' && hasActiveSspSubscription) || (type === 'ILS' && hasActiveFocusSubscription)

  const showBillingMessage =
    isBilling &&
    !hasPaidTypeSubscription &&
    ((type === 'SSP' && hasOrgSspCertificationCompleted) ||
      (type === 'SSP' && sspInTrainingOnly) ||
      (type === 'ILS' && hasOrgFocusCertificationCompleted) ||
      (type === 'ILS' && focusInTrainingOnly))

  return (
    <DashboardCard
      {...gridProps}
      key={`${type.toLowerCase()}-certified-providers`}
      title={title}
      dataTestLabel={`dashboard-card-${type}-certified-non-billing`}
    >
      {isCompletedUser && (
        <Typography>Congratulations on obtaining your {type} certification!</Typography>
      )}
      {showBillingMessage && (
        <>
          <Typography pt={4}>
            Please purchase an {type} subscription to begin delivering {type} to clients.
          </Typography>
          <BulletLink internalURI={`/store/${type.toLowerCase()}`}>
            for {type} Subscription purchase options.
          </BulletLink>
        </>
      )}
      {!showBillingMessage && (
        <Typography pt={4}>
          For assistance, please contact our Client Success team at{' '}
          <a className="text-link" href={`mailto:${EMAILS.supportEmail}`}>
            {EMAILS.supportEmail}
          </a>
          .
        </Typography>
      )}
    </DashboardCard>
  )
}
