import React, { useState } from 'react'
import { Dialog, DialogContent, DialogTitle, Button } from 'components'
import { useSelector, useDispatch } from 'react-redux'
import get from 'lodash/get'
import { useSnackbar } from 'notistack'

import {
  Grid,
  DialogActions,
  Typography,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  TermsOfUse,
} from 'components'
import { extendUser } from 'store/modules/auth'
import { gql, useMutation } from 'utils/apollo'
import { PROFESSIONAL_CREDENTIALS } from 'utils/constants/professionalCredentials'
import CloseSnackbarAction from 'components/CloseSnackbarAction'

const UPDATE_USER = gql`
  mutation updateUser($user: UpdateUserInput!) {
    updateUser(user: $user) {
      id
      email
      firstName
      lastName
      createdAt
      isSuspended
      address1
      address2
      city
      state
      country
      phone
      dob
      professionalCredentials
      otherProfessionalCredentials
      zip
    }
  }
`

export default function VerifyProfessionalCredentialsModal() {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  // always start with open
  const [open, setOpen] = useState(true)

  const id = useSelector((state) => get(state, 'auth.user.id'))
  const _professionalCredentials = useSelector((state) =>
    get(state, 'auth.user.professionalCredentials', undefined)
  )
  const [updateUser] = useMutation(UPDATE_USER)
  const [agreement, setAgreement] = useState(false)
  const [professionalCredentials, setProfessionalCredentials] = useState(_professionalCredentials)

  const onChange = (event) => {
    setProfessionalCredentials(event.target.value)
  }

  const onSave = async (event) => {
    event.preventDefault()

    try {
      const res = await updateUser({ variables: { user: { id, professionalCredentials } } })
      dispatch(extendUser(res.data.updateUser))
      setAgreement(false)
      enqueueSnackbar('Update successful', { variant: 'success', action: CloseSnackbarAction })
      setOpen(false)
    } catch (error) {
      console.error('on submit professional credentials', error)
    }
  }

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle
        style={{
          backgroundColor: '#008080',
          textAlign: 'center',
          color: '#fff',
        }}
      >
        Please Confirm Your Professional Credentials
      </DialogTitle>
      <DialogContent className="py-5" dividers>
        <Typography variant="body1" className="py-3">
          We've noticed that we don't have your credentials on file. Please complete professional
          attestation by selecting your professional credentials.
        </Typography>
        <Typography variant="body2" className="pb-5">
          Note: If you have been pre-approved, please select “Other credentials which have been
          approved by Unyte”.
        </Typography>
        <FormControl fullWidth>
          <InputLabel id="select-professional-credentials-input-label">
            Confirm your professional credentials
          </InputLabel>
          <Select
            label="Confirm your professional credentials"
            labelId="select-professional-credentials-label"
            id="select-professional-credentials"
            value={professionalCredentials || ''}
            onChange={onChange}
          >
            {PROFESSIONAL_CREDENTIALS.map((value, index) =>
              value === 'Other' ? (
                <MenuItem key={`pc${index}`} value={'Other Credentials'}>
                  Other credentials which have been approved by Unyte
                </MenuItem>
              ) : (
                <MenuItem key={`pc${index}`} value={value}>
                  {value}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
        <div className="mt-5 mb-3">
          <TermsOfUse checked={agreement} onChange={setAgreement} />
        </div>
      </DialogContent>
      <DialogActions className="justify-between">
        <Grid container justifyContent="center">
          <Button
            onClick={onSave}
            disabled={
              !(agreement && professionalCredentials && professionalCredentials !== 'Other')
            }
            color="primary"
            variant="contained"
          >
            Save
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
