import React, { useEffect, useState } from 'react'

import { Typography, Button, Stack, Box, Tooltip } from '@mui/material'
import { useLocation, useOutletContext } from 'react-router'
import { formatTimeString } from 'utils/(time)/formatTimeString'
import { Link } from 'react-router-dom'
import { saveCurrentFilter, setUser } from 'store/modules/assessments'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import useLocalStorage from 'utils/hooks/useLocalStorage'

// components
import AssessmentsTitle, { AssessmentsGettingStartedTitle } from './components/AssessmentsTitle'
import GetStartedWithAssessments from './GetStartedWithAssessments'
import AssessmentTableComponent from 'components/table/AssessmentTableComponent'
import { Status } from './components/table/Status'
import AssessmentFilter from './components/table/AssessmentFilter'
import { AssessmentsKPIs } from './components/AssessmentsKPIs'

// icons
import ExpandIcon from '@mui/icons-material/ExpandMore'
import CollapseIcon from '@mui/icons-material/ExpandLess'

export default function AssessmentTable() {
  const {
    setHideHeader,
    setHideFilter,
    setHideTabs,
    setTitle,
    setLoadingState,
    data,
  } = useOutletContext()

  /**
   * data need to be from useGetTransformedClientData to work with sessions..
   * `const data = useSelector((state) => state.assessments.sessions)`
   * will only work if we incorporate filters into assessments..
   *  */

  const [showTable, setShowTable] = useState(false)

  // Using the location state to pass variables to new page.
  const { state } = useLocation()
  const hideGettingStarted = state?.hideGettingStarted
  const authUserId = useSelector((state) => get(state, 'auth.user.id', null))

  // ls = localStorage
  const [localStorageHideGettingStarted, setLocalStorageHideGettingStarted] = useLocalStorage(
    `hideGettingStarted${authUserId}`
  )

  useEffect(() => {
    if (!showTable && !localStorageHideGettingStarted) {
      setShowTable(data?.length > 0 || hideGettingStarted)
    }

    if (data?.length > 0) {
      setLocalStorageHideGettingStarted(true)
    }
    // eslint-disable-next-line
  }, [data, hideGettingStarted])

  useEffect(() => {
    setHideHeader(false)
    if ((showTable && !hideGettingStarted) || localStorageHideGettingStarted) {
      changeDisplay()
    } else {
      setTitle(<AssessmentsGettingStartedTitle />)
      setHideFilter(true)
      setHideTabs(true)
    }

    setLoadingState(false)
    // eslint-disable-next-line
  }, [showTable])

  const changeDisplay = () => {
    setShowTable(true)
    setTitle(<AssessmentsTitle />)
    setHideFilter(false)
    setHideTabs(false)
  }

  const dispatch = useDispatch()
  const handleNewAssessment = () => {
    dispatch(saveCurrentFilter())
    dispatch(
      setUser({
        userId: undefined,
      })
    )
  }
  const clients = useSelector((state) => get(state, 'assessments.userData', []))
  const disableNewAssessments = !clients.length

  return (
    <>
      {!showTable && !localStorageHideGettingStarted && (
        <GetStartedWithAssessments changeDisplay={changeDisplay} />
      )}
      {(showTable || localStorageHideGettingStarted) && (
        <Stack direction="column" spacing={1}>
          <Stack direction={{ sm: 'column', md: 'row' }} alignItems="center" p={2} spacing={1}>
            <Tooltip
              title={
                disableNewAssessments &&
                'Administering an assessment requires a client to be added first.'
              }
            >
              <Box pr={8}>
                <Link
                  to={disableNewAssessments ? '/assessments' : '/assessments/select'}
                  className="no-underline text-white"
                >
                  <Button
                    variant="contained"
                    size="large"
                    onClick={handleNewAssessment}
                    disabled={disableNewAssessments}
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    New Assessment
                  </Button>
                </Link>
              </Box>
            </Tooltip>
            <AssessmentFilter />
          </Stack>
          <AssessmentsKPIs />
          {/* Assessment table goes here */}
          <AssessmentTableComponent
            columns={[
              {
                width: '20%',
                header: () => 'Client',
                body: ({ data }) => (
                  <Stack direction="column">
                    <Typography variant="body2" fontWeight={500}>
                      {data?.user?.fullName}
                    </Typography>
                    <Typography variant="caption">{data?.user?.email}</Typography>
                  </Stack>
                ),
              },
              {
                width: '20%',
                header: () => 'Assessment',
                body: ({ data }) => (
                  <Stack direction="column">
                    <Typography variant="body2" fontWeight={500}>
                      {data?.metadata?.shortTitle}
                    </Typography>
                    <Typography variant="caption">{data?.metadata?.populationLabels}</Typography>
                  </Stack>
                ),
              },
              {
                width: '15%',
                header: () => 'Created Date',
                body: ({ data }) => (
                  <Typography variant="body2">{formatTimeString(data.createdAt)}</Typography>
                ),
              },
              {
                width: '15%',
                header: () => 'Last Updated',
                body: ({ data }) => (
                  <Typography variant="body2">{formatTimeString(data.updatedAt)}</Typography>
                ),
              },
              {
                width: '10%',
                header: () => 'Score',
                body: ({ data, metadata }) => {
                  const isIntakeForm = get(data, 'metadata.isIntakeForm', false)
                  return (
                    <>
                      {data.status === 'Completed' && !isIntakeForm && (
                        <Typography variant="body2" textAlign="left">
                          {data?.score?.sum || 0} out of {data.score.maximumScore || 0}
                        </Typography>
                      )}
                      {isIntakeForm && data?.status === 'Completed' && (
                        <Typography variant="body2" textAlign="left">
                          N/A
                        </Typography>
                      )}
                    </>
                  )
                },
              },
              {
                width: '20%',
                header: () => 'Status',
                body: ({ data, isSelected, isLastRow }) => (
                  <Stack direction="row" justifyContent="space-between">
                    <Status status={data?.data?.status} progress={data?.progress} />
                    {isLastRow && (isSelected ? <CollapseIcon /> : <ExpandIcon />)}
                  </Stack>
                ),
              },
            ]}
          />
        </Stack>
      )}
    </>
  )
}
