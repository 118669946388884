/**
 * This component is  for internal purchase
 */
import React, { useEffect, useState } from 'react'
import {
  Grid,
  Card,
  PageLoader,
  Button,
  CardContent,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  TextField,
  Typography,
} from 'components'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import { getShipstationQuotes, setShipping, extendData } from 'store/modules/new-purchase'
import { CardHeader, Radio } from '@mui/material'
import SelectCountry from 'components/ecommerce/shipping/SelectCountry'
import SelectState from 'components/ecommerce/shipping/SelectState'
import useCart from 'views/new-purchase/utils/useCart'
import formatMoney from 'views/new-purchase/utils/formatMoney'
import useGTM from '../../utils/hooks/useGTM'
import { useForm } from '../../hooks'
import { useNavigate } from 'react-router-dom'
import PurchaseLayout from './components/PurchaseLayout'
import GenerateAddressButton from '../../components/testFeatures/GenerateAddressButton'
import EMAILS from 'utils/constants/emails'
import MuiPhoneNumber from 'mui-phone-number'

// purchase service libraries
import { gql, useMutation } from '@apollo/client'
import { AUTH_CREATE_PURCHASE, client } from 'utils/apollo/purchaseService/client'
import { InternationalMessage } from '../../components/ecommerce/constants/InternationalShippingMessage'

const AUTH_UPDATE_PURCHASE = gql`
  mutation AUTH_UPDATE_PURCHASE($params: AuthUpdatePurchaseInput!, $uuid: String!) {
    auth_update_purchase(params: $params, uuid: $uuid) {
      uuid
      stripe_invoice
      stripe_invoice_id
    }
  }
`

export default function Products() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [loadingText, setLoadingText] = useState('')
  const [changed, setChanged] = useState(false)
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [lastUpdated, setLastUpdated] = useState('')
  const { pageView, addShippingInfo } = useGTM({})
  const navigate = useNavigate()

  const {
    email,
    phoneNumber,
    address1,
    address2,
    toPostalCode,
    toState,
    toCountry,
    toCity,
    uuid,
    onboardingProducts = [],
  } = useSelector((state) => state.newPurchase.data)

  const adminEmail = useSelector((state) => get(state, 'auth.user.email', ''))
  const firstName = useSelector((state) => get(state, 'auth.user.firstName', ''))
  const lastName = useSelector((state) => get(state, 'auth.user.lastName', ''))

  const { form, setFormValue, errors } = useForm({
    data: {
      email: email || adminEmail,
      phoneNumber,
      address1,
      address2,
      toPostalCode,
      toState,
      toCountry,
      toCity,
    },
    validation: {
      fullName: {
        required: { msg: 'Name is required.' },
      },
      email: {
        required: { msg: 'Email is required.' },
        email: { msg: 'Please enter a valid email address.' },
        len: { args: [null, 100], msg: 'Email must be no longer than 100 characters.' },
      },
      address1: {
        required: { msg: 'Address is required.' },
      },
      toPostalCode: {
        required: { msg: 'Postal Code is required.' },
        len: {
          args: [null, 10],
          msg: 'Please enter a valid Zip/Postal code',
        },
      },
      toState: {
        required: { msg: 'State is required.' },
      },
      toCountry: {
        required: { msg: 'Country is required.' },
      },
      toCity: {
        required: { msg: 'City is required.' },
      },
    },
  })

  const [showShipping, setShowShipping] = useState(false)
  const [selectedServiceCode, setSelectedServiceCode] = useState()
  const { weight, selectedAddOnsArray } = useCart()
  const shipstationRates = useSelector((state) =>
    get(state, 'newPurchase.data.shipstationRates', [])
  )
  const shipstationError = useSelector((state) =>
    get(state, 'newPurchase.data.shipstationError', false)
  )

  const products = [
    ...onboardingProducts.map((item) => ({ sku: item, quantity: 1 })),
    ...selectedAddOnsArray,
  ]

  const shipping_address = {
    full_name: form.fullName,
    email: form.email,
    phone_number: form.phoneNumber,
    address1: form.address1,
    address2: form.address2,
    postal_code: form.toPostalCode,
    state: form.toState,
    country: form.toCountry,
    city: form.toCity,
  }
  const [getTax] = useMutation(AUTH_UPDATE_PURCHASE, {
    client,
    variables: {
      params: {
        shipping_address,
        items: products.map(({ sku, quantity }) => ({
          sku,
          quantity,
        })),
      },
      uuid,
    },
  })
  const [createPurchase] = useMutation(AUTH_CREATE_PURCHASE, {
    client,
    variables: { params: { data: {} } },
  })

  // Calculate shipping
  const onCalculate = async () => {
    setLoading(true)
    setShowError(false)
    setShowShipping(false)
    setLoadingText('calculating shipping...')
    // get total weight from all selected products..
    try {
      await dispatch(
        getShipstationQuotes({
          toPostalCode: form.toPostalCode,
          toState: form.toState,
          toCountry: form.toCountry,
          toCity: form.toCity,
          weight,
          email: adminEmail,
          firstName,
          lastName,
          products,
        })
      )
    } catch (error) {
      console.error(error)
      setShowError(true)
      setErrorMessage(error?.message)
    } finally {
      setLoading(false)
      setChanged(false)
      setShowShipping(true)
    }
  }

  const shippingObject = {
    fullName: form.fullName,
    email: form.email,
    phoneNumber: form.phoneNumber,
    address1: form.address1,
    address2: form.address2,
    toPostalCode: form.toPostalCode,
    toState: form.toState,
    toCountry: form.toCountry,
    toCity: form.toCity,
  }

  // Set shipment method and calculate tax
  const onContinue = async () => {
    setLoading(true)
    setShowError(false)

    setLoadingText('calculating tax...')
    try {
      // if we don't have uuid at this step, get it..
      const getNewUuid = async () => {
        const internalPurchaseData = await createPurchase()
        const newUuid = get(internalPurchaseData, 'data.auth_create_purchase.uuid', null)
        await dispatch(extendData({ uuid: newUuid }))

        return newUuid
      }

      const result = await getTax({
        variables: {
          params: {
            shipping_address,
            shipment_method: selectedServiceCode,
            items: products.map(({ sku, quantity }) => ({
              sku,
              quantity,
            })),
          },
          uuid: uuid || getNewUuid(),
        },
      })

      const tax = get(result, 'data.auth_update_purchase.stripe_invoice.tax', 0)
      const taxEnabled = get(result, 'stripe_invoice.automatic_tax.enabled', null) === true
      const taxComplete = get(result, 'stripe_invoice.automatic_tax.status', null) === 'complete'

      if (taxEnabled && !taxComplete) {
        await dispatch(extendData({ shipstationError: true }))
      }

      await dispatch(
        extendData({
          totalTax: tax,
        })
      )

      navigate('/purchase/confirm')
    } catch (error) {
      console.error(error)
      setShowError(true)
      setErrorMessage(error?.message)
    } finally {
      setLoading(false)
      setShowShipping(true)
    }

    dispatch(extendData({ ...shippingObject }))
    await addShippingInfo({ shipping_tier: selectedServiceCode })
  }

  // TODO: rename products to items
  const handleRadio = (e) => {
    setSelectedServiceCode(e.target.value)
    dispatch(extendData({ selectedServiceCode: e.target.value }))
    dispatch(setShipping(e.target.value))
  }

  // always delete saved service code on load & back
  const clearShipping = () => {
    setShowShipping(false)
    setSelectedServiceCode('')
    dispatch(extendData({ selectedServiceCode: '' }))
    dispatch(setShipping(''))
  }
  useEffect(() => {
    clearShipping()

    // on load: send virtual page load
    pageView({
      pageUrl: window.document.location.href,
      pageTitle: 'Internal Purchase - Enter shipping address',
    })
    // eslint-disable-next-line
  }, [])

  // remove shipping item when user clicks back
  const onChange = (type) => (e) => {
    const options = type === 'phoneNumber' ? { path: '' } : { path: 'target.value' }
    setFormValue(type, options)(e)
    setLastUpdated(type)
    setChanged(true)
    clearShipping()
  }

  // if country has been updated, remove state
  useEffect(() => {
    if (lastUpdated === 'toCountry') {
      setFormValue('toState')('')
    }
    // eslint-disable-next-line
  }, [lastUpdated])

  // if newPurchase is an empty object, we know user has restarted the page..
  const onBack = () => {
    clearShipping()
    navigate(-1)
  }

  return (
    <PurchaseLayout title="Shipping" loading={loading} loadingText={loadingText}>
      <Card variant="outlined" className="w-full" sx={{ padding: '20px' }}>
        <CardHeader
          titleTypographyProps={{ fontWeight: 500, p: 0 }}
          title="Please enter your shipping address"
        />
        <Grid container>
          <CardContent>
            <TextField
              autoFocus={true}
              className="mb-5 w-full"
              label="Full Name*"
              value={form.fullName}
              error={errors.fullName}
              onChange={onChange('fullName')}
              disabled={loading}
            />
            <TextField
              className="mb-5 w-full"
              label="Email*"
              value={form.email}
              error={errors.email}
              onChange={onChange('email')}
              disabled={loading}
            />

            <MuiPhoneNumber
              label="Phone Number"
              id="phone-number"
              value={form.phoneNumber}
              onChange={onChange('phoneNumber')}
              disableAreaCodes={true}
              preferredCountries={['ca', 'us']}
              defaultCountry="us"
              fullWidth
              className="mb-5"
              variant="outlined"
            />
            <TextField
              className="mb-5 w-full"
              label="Address 1*"
              value={form.address1}
              error={errors.address1}
              onChange={onChange('address1')}
              disabled={loading}
            />
            <TextField
              className="mb-5 w-full"
              label="Address 2"
              value={form.address2}
              error={errors.address2}
              onChange={onChange('address2')}
              disabled={loading}
            />
            <TextField
              className="mb-5 w-full"
              label="City*"
              value={form.toCity}
              error={errors.toCity}
              onChange={onChange('toCity')}
              disabled={loading}
            />
            <SelectCountry
              value={form.toCountry}
              onChange={onChange('toCountry')}
              error={errors.toCountry}
              disabled={loading}
              excludeNonShippableCountries
            />
            <SelectState
              disabled={loading}
              country={form.toCountry}
              onChange={onChange('toState')}
              value={form.toState}
              error={errors.toState}
            />
            <TextField
              className="mt-5 w-full"
              label="Zip/Postal Code*"
              value={form.toPostalCode}
              error={errors.toPostalCode}
              onChange={onChange('toPostalCode')}
              disabled={loading}
            />

            {showShipping && shipstationError && !showError && (
              <FormHelperText error className="pt-5">
                Sorry but we're unable to calculate shipping costs for the address you entered.
                Please edit the address and try again.
              </FormHelperText>
            )}
            {showShipping && !shipstationError && showError && (
              <FormHelperText error className="pt-5">
                There was an issue updating your purchase. For assistance, please contact our Client
                Success team at{' '}
                <a className="text-link" href={`mailto:${EMAILS.supportEmail}`}>
                  {EMAILS.supportEmail}
                </a>
                . For reference: {errorMessage}
              </FormHelperText>
            )}

            {showShipping && !shipstationError && !showError && (
              <>
                {!shipstationRates && <PageLoader />}
                <CardHeader
                  title="Select your shipping option below"
                  titleTypographyProps={{ fontWeight: 500, p: 0 }}
                />

                {form.toCountry !== 'US' && <InternationalMessage />}

                <Divider />
                <FormControl component="fieldset" className="px-4">
                  <RadioGroup
                    aria-label="Shipping Option"
                    name="Shipping Option"
                    value={selectedServiceCode}
                    onChange={handleRadio}
                  >
                    {shipstationRates &&
                      shipstationRates.map(
                        ({ serviceCode, shipmentCost, serviceName, otherCost }, index) => (
                          <FormControlLabel
                            data-test={`shipping-option-${index}`}
                            key={`shippingRate${index}`}
                            disabled={loading}
                            value={serviceCode}
                            control={<Radio color="primary" />}
                            label={`${serviceName} - ${formatMoney(shipmentCost + otherCost)}`}
                          />
                        )
                      )}
                  </RadioGroup>
                </FormControl>
              </>
            )}
          </CardContent>
        </Grid>
        <Grid>
          <Typography>
            <GenerateAddressButton
              setFormValue={setFormValue}
              addressType="Colorado"
              clearShipping={clearShipping}
            />
          </Typography>
          <Typography>
            <GenerateAddressButton
              setFormValue={setFormValue}
              addressType="Canada"
              clearShipping={clearShipping}
            />
          </Typography>
          <Typography>
            <GenerateAddressButton
              setFormValue={setFormValue}
              addressType="Massachussets"
              clearShipping={clearShipping}
            />
          </Typography>
        </Grid>
        <Grid container sx={{ p: 2 }} justifyContent="space-between">
          <Button disabled={loading} color="primary" className="my-5" onClick={onBack}>
            Back
          </Button>
          <Button
            variant="contained"
            className="my-5"
            color={selectedServiceCode ? 'inherit' : 'primary'}
            onClick={onCalculate}
            disabled={
              !form.fullName ||
              !form.email ||
              !form.address1 ||
              !form.toPostalCode ||
              !form.toState ||
              !form.toCountry ||
              !form.toCity ||
              loading ||
              errors.email ||
              errors.toPostalCode ||
              (shipstationError && !changed)
            }
          >
            Calculate Shipping
          </Button>
          <Button
            disabled={!selectedServiceCode || loading || changed}
            color="primary"
            variant="contained"
            className="w-25 my-5 no-underline"
            onClick={onContinue}
          >
            Continue
          </Button>
        </Grid>
      </Card>
    </PurchaseLayout>
  )
}
