/**
 *   <LineCharts/>
 *   <ScoringGuide/>
 *   <Responses/>
 *
 */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { useReactToPrint } from 'react-to-print'

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  LineChart,
  Line,
} from 'recharts'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate, useNavigationType, useOutletContext, useParams } from 'react-router'
import LINKS from 'utils/constants/links'
import ScoringGuide from './components/reports/ScoringGuide'
import CompletionDateCard from './components/reports/CompletionDate'
import { round } from 'lodash'
import { AssessmentBackButton } from './components/assessment-back-button'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  GET_COMPLETED_ASSESSMENTS_FOR_REPORTS,
  UPDATE_ASSESSMENTS_SCORE_FROM_REPORTS,
} from './constants/gql'
import get from 'lodash/get'
import { useOnValueChange } from 'utils/hooks/useOnValueChange'
import { getAssessmentScore } from './utils/get-assessment-score'
import { parseMetadata } from './utils/parse-metadata'
import ResponseTable from './ResponseTable'
import {
  Button,
  Box,
  Card,
  Link,
  Typography,
  Stack,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
} from '@mui/material'
import { resetFilter } from 'store/modules/assessments'
import { SCORING_GUIDE } from './utils/scoringGuide'
import useGetTransformedClientData from './utils/useGetTransformedClientData'
import { PrintHeader } from 'components/assessments/print-header'
import throttle from 'lodash/throttle'

const COLORS = [
  '#E0003C',
  '#00CD6C',
  '#A247AE',
  '#FFD147',
  '#E7770D',
  '#1637CA',
  '#FF70DE',
  '#38EEFF',
  '#577590',
  '#43aa8b',
  '#f8961e',
]

/**
 * if we don't have BBCSS, we want to show the total..
 */
const AreaChartToolTip = ({ active, payload, subScaleOrder, isBBCSS }) => {
  if (active && payload && payload.length) {
    const hasSubScale = !!subScaleOrder.length
    return (
      <Card>
        <p className="m-3 font-semibold">{moment(payload[0].payload.x).format('MMMM Do YYYY')}</p>
        {hasSubScale &&
          isBBCSS &&
          subScaleOrder.map((scale) => {
            // we should not see nan here
            const roundedScore = round(payload.find(({ name }) => name === scale)?.value, 1)
            const nanFilteredRoundedScore = isNaN(roundedScore) ? '-' : roundedScore
            return (
              <p className="m-3" key={`${scale}-${payload.x}`}>
                {scale}: {nanFilteredRoundedScore}
              </p>
            )
          })}

        {hasSubScale && !isBBCSS && (
          <p className="m-3" key={`total-${payload.x}`}>
            Total:{' '}
            {subScaleOrder.reduce(
              (a, scale) => a + round(payload.find(({ name }) => name === scale)?.value, 1),
              0
            )}
          </p>
        )}
        {!hasSubScale &&
          payload.map(({ name, payload }, index) => (
            <p className="m-3" key={`${payload.x}${index}`}>
              {name}: {payload[name]}
            </p>
          ))}
      </Card>
    )
  }

  return null
}

const CustomizedLegend = ({ subScaleOrder, payload, showPrintObjects }) => {
  return (
    <Box sx={{ height: '150px' }}>
      <Grid
        container
        spacing={2}
        direction="row"
        sx={{
          width: showPrintObjects ? '100vw' : '100%',
          // height: showPrintObjects ? 'inherit' : '150px',
          // height: '150px',
        }}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {!subScaleOrder.length &&
          payload.map((entry, index) => (
            <Grid item key={`item-${index}`} spacing={2} direction="row" alignItems="center">
              <div
                style={{
                  backgroundColor: entry?.color,
                  width: '15px',
                  height: '15px',
                  borderRadius: '9999px',
                }}
              />
              <Typography variant="body2">{entry?.value}</Typography>
            </Grid>
          ))}
        {!!subScaleOrder.length &&
          subScaleOrder?.map((subscale, index) => {
            const entry = payload.find(({ dataKey }) => dataKey === subscale)
            return (
              <Grid item key={`item-${index}`} spacing={2} direction="row">
                {!showPrintObjects && (
                  <Stack direction="row" spacing={2} alignItems="center">
                    <div
                      style={{
                        backgroundColor: entry?.color,
                        width: '20px',
                        height: '20px',
                        borderRadius: '9999px',
                      }}
                    />
                    <Typography variant="body2">{entry?.value}</Typography>
                  </Stack>
                )}
                {showPrintObjects && (
                  <Stack direction="row" spacing={1}>
                    <div
                      style={{
                        backgroundColor: entry?.color,
                        width: '7px',
                        height: '7px',
                        borderRadius: '9999px',
                      }}
                    />
                    <Typography variant="caption">{entry?.value}</Typography>
                  </Stack>
                )}
              </Grid>
            )
          })}
      </Grid>
    </Box>
  )
}

/**
 * 1. useQuery to get the latest completed data
 * 2. if we don't have mean/ median score then calculate and save into metadata for next time
 * TODO:
 * - refactor out useGetTransformedClientData
 * - getLinePlot
 */
export default function Reports() {
  const { userId } = useParams()
  const { loading } = useOutletContext()
  const location = useNavigationType()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { getLinePlot } = useGetTransformedClientData()

  // redux variables
  const filterAssessmentName = useSelector((state) => state.assessments.selectedAssessmentName)
  const filterProductEventId = useSelector((state) =>
    get(state, 'assessments.filter.productEventId', null)
  )
  const productEvents = useSelector((state) => state.assessments.productAssessments)
  const metadata = get(
    productEvents.find(({ id }) => id === filterProductEventId),
    'metadata',
    {}
  )
  const userData = useSelector((state) => get(state, 'assessments.userData', []))

  // state variables
  const [completedAssessments, setCompletedAssessments] = useState([])
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 })

  // gql
  const [loadAssessments] = useLazyQuery(GET_COMPLETED_ASSESSMENTS_FOR_REPORTS)
  const [updateAssessment] = useMutation(UPDATE_ASSESSMENTS_SCORE_FROM_REPORTS)

  useOnValueChange(JSON.stringify({ userId, filterProductEventId, productEvents }), async () => {
    try {
      if (userId && filterProductEventId && productEvents) {
        const { data } = await loadAssessments({
          variables: {
            filter: {
              userIds: parseInt(userId),
              types: 'answers',
              status: 'Completed',
            },
          },
          fetchPolicy: 'no-cache',
        })

        // for each completed assessments, check their score, if it is not present
        const filteredCompletedAssessments = get(data, 'getSessions', [])
          .filter(({ productEventId }) => productEventId === filterProductEventId)
          .sort((a, b) => {
            const dateA = a.data.completedAt ? new Date(a.data.completedAt) : null
            const dateB = b.data.completedAt ? new Date(b.data.completedAt) : null

            if (dateA === null) return 1
            if (dateB === null) return -1
            return dateA - dateB
          })

        const completedAssessmentsWithScores = await filteredCompletedAssessments.map(
          (assessment) => {
            const status = get(assessment, 'data.status', {})
            const answers = get(assessment, 'data.answers', {})
            const previousScore = get(assessment, 'data.answers.score.sum')
            const currentScore = getAssessmentScore(answers, metadata)

            // Check if the assessment is completed and if there is a new current score
            // A new current score would indicate that the previous score was incorrect
            if (previousScore !== currentScore?.sum && status === 'Completed') {
              updateAssessment({
                variables: { session: { id: assessment.id, score: currentScore } },
              })

              return { ...assessment, data: { ...assessment.data, score: currentScore } }
            }
            return assessment
          }
        )
        setCompletedAssessments(completedAssessmentsWithScores)
      }
    } catch (error) {
      if (!error.message.includes('Not Authorized')) {
        console.error(error)
      }
    }
  })

  const { data, scales = [] } = getLinePlot(completedAssessments, metadata)
  const subScores = data.sort((a, b) => b.x - a.x)
  const {
    isIntakeForm,
    isBBCSS,
    isAce,
    meanMax,
    subScaleOrder,
    maximumScore,
    assessmentName: metadataAssessmentName,
    numberOfItems,
  } = parseMetadata(metadata)

  const lastUpdatedAssessment = get(completedAssessments, '[0]', {})

  // print handlers
  const componentRef = useRef(null)
  const [showPrintObjects, setShowPrintObjects] = useState(false)
  // the variable here will be used later when we have more defined
  const reactToPrintContent = useCallback(() => {
    return componentRef.current
    // eslint-disable-next-line
  }, [componentRef.current])

  const _handlePrint = useReactToPrint({
    content: reactToPrintContent,
  })
  const handlePrint = async () => {
    setShowPrintObjects(true)
    setTimeout(async () => {
      _handlePrint()
    }, 1500)
    setTimeout(async () => {
      setShowPrintObjects(false)
    }, 5000)
  }

  // do not delete line below, as location behaves differently depending on environment
  // Locally it is replace, on prod it is POP
  useEffect(() => {
    const _resetFilter = () => {
      dispatch(resetFilter())
    }
    if (location === 'POP') {
      _resetFilter()
      navigate('/assessments')
    }
  }, [location])

  const handleMouseMove = useCallback(
    throttle((e) => {
      const x = e.chartX
      const y = e.chartY
      const newX = x > 365 ? x - 120 : x // Adjusting 120 pixels left if more than half the chart width
      const newY = y > 125 ? y - 60 : y + 20 // Adjust up or down based on the height
      setTooltipPosition({ x: newX, y: newY })
    }, 200), // Throttle interval (e.g., 200ms)
    []
  )

  // URL should ALWAYS have a userID when we get here or else return
  if (!userId && !filterProductEventId) {
    return <Navigate to={'/assessments'} />
  }

  const user = userData.find(({ id }) => id === parseInt(userId))

  const assessmentDetails = SCORING_GUIDE.find(({ assessmentName }) =>
    assessmentName.includes(metadataAssessmentName)
  )

  const numberOfTables = Math.ceil(numberOfItems / 10)

  return (
    <>
      {/* header goes here */}
      <Stack direction="row" justifyContent="space-between">
        <AssessmentBackButton />
        <Box sx={{ p: 2 }}>
          <Button variant="contained" size="small" sx={{ px: 4 }} onClick={handlePrint}>
            Print Summary
          </Button>
        </Box>
      </Stack>
      {isIntakeForm && !loading && (
        <>
          <Typography
            paragraph
            variant="body1"
            fontWeight={500}
            component="p"
            pt={10}
            px={15}
            textAlign="center"
          >
            Please use 'View Responses' option under <Link to={'/assessments'}>Assessments</Link> to
            view your client’s intake form responses.
          </Typography>
          <Typography className="text-link" variant="body1" component="p" textAlign="center">
            Still having trouble?{' '}
            <a
              className="underline pr-1 text-base"
              target="_blank"
              rel="noopener noreferrer"
              href={LINKS.support.homepageUrl}
            >
              We’re always here to help
            </a>
          </Typography>
        </>
      )}
      {!isIntakeForm && !loading && !completedAssessments.length && (
        <>
          <Typography
            paragraph
            variant="body1"
            component="p"
            fontWeight={500}
            textAlign="center"
            pt={10}
            px={15}
          >
            We didn't find any completed assessments associated with the client(s), assessment,
            and/or dates you've selected. Please try a different combination of filters or fill out
            a New Assessment.
          </Typography>
          <Typography className="text-link" variant="body1" component="p" textAlign="center">
            Still having trouble?{' '}
            <a
              className="underline pr-1 text-base"
              target="_blank"
              rel="noopener noreferrer"
              href={LINKS.support.homepageUrl}
            >
              We’re always here to help
            </a>
          </Typography>
        </>
      )}
      {!isIntakeForm && !loading && !!completedAssessments.length && (
        <Box
          ref={componentRef}
          sx={{
            width: showPrintObjects ? '8.27in' : '100%',
            height: showPrintObjects ? '11.27in' : '100%',
          }}
        >
          {// page 1
          showPrintObjects && (
            <>
              <Stack justifyContent="space-between" direction="row" pt={3}>
                <div>
                  <Typography variant="h2" mx={5} mb={2} className="print-h2">
                    {user?.fullName}
                  </Typography>
                  <Typography variant="h1" mx={5} className="print-h1">
                    {filterAssessmentName}
                  </Typography>
                </div>
                <Box>
                  <img
                    style={{
                      height: '75px',
                      width: '150px',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      paddingRight: '1.25rem',
                    }}
                    src="/images/unyte-logo.png"
                    alt="Unyte Logo"
                  />
                </Box>
              </Stack>
              <Typography variant="h3" mx={5} className="print-h3">
                Progress Chart
              </Typography>
            </>
          )}

          {!showPrintObjects && (
            <>
              <CompletionDateCard assessment={lastUpdatedAssessment} metadata={metadata} />
              <Typography textAlign="center" variant="h5" mt={3}>
                {filterAssessmentName}
              </Typography>
            </>
          )}

          {/* line chart */}
          <Box
            sx={{
              width: showPrintObjects ? '760px' : '100%',
              height: showPrintObjects ? '600px' : '600px',
              pageBreakAfter: 'always',
            }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={700}
                height={250}
                data={subScores}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
                onMouseMove={handleMouseMove}
              >
                <XAxis
                  dataKey="x"
                  name="Time"
                  type="number"
                  domain={['auto', 'auto']}
                  tickFormatter={(unixTime) => moment(unixTime).format('MMM DD, YYYY')}
                  label={{ value: 'Date', position: 'bottom' }}
                  minTickGap={30}
                />
                <YAxis
                  type="number"
                  dataKey="y"
                  name={subScaleOrder?.length && isBBCSS ? 'Mean Score' : 'Score (Total)'}
                  domain={[0, subScaleOrder?.length && isBBCSS ? meanMax : maximumScore]}
                  label={{
                    value: subScaleOrder?.length && isBBCSS ? 'Mean Score' : 'Score (Total)',
                    angle: -90,
                    position: 'insideLeft',
                  }}
                  allowDataOverflow={true}
                />
                <CartesianGrid />
                <Tooltip
                  position={tooltipPosition}
                  content={<AreaChartToolTip isBBCSS={isBBCSS} subScaleOrder={subScaleOrder} />}
                />
                <Legend
                  layout="horizontal"
                  verticalAlign="top"
                  iconType="line"
                  align="center"
                  wrapperStyle={{
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                    paddingBottom: '1rem',
                    paddingTop: showPrintObjects ? '0rem' : '1rem',
                  }}
                  content={
                    <CustomizedLegend
                      subScaleOrder={subScaleOrder}
                      showPrintObjects={showPrintObjects}
                    />
                  }
                />
                {scales.map((key, index) => (
                  <Line
                    key={`line${index}`}
                    type="monotone"
                    dataKey={key}
                    stroke={key.includes('Total') ? '#111827' : COLORS[index]}
                    activeDot={{ r: 8 }}
                    strokeWidth={key.includes('Total') ? 4 : 2}
                    dot={{ r: 8 }}
                    legendType="rect"
                  />
                ))}
              </LineChart>
            </ResponsiveContainer>
          </Box>

          {// page 2
          showPrintObjects && (
            <>
              <PrintHeader
                clientName={user?.fullName || ''}
                assessmentName={filterAssessmentName}
                pageCurrent={2}
                pageMax={numberOfTables + 3}
              />
              <Typography variant="h3" className="print-h3" mx={3}>
                Total Scores
              </Typography>
              <Box mx={2}>
                <TableContainer component={Paper} elevation={1} variant="outlined">
                  <Table aria-label="report table">
                    <TableHead>
                      <TableRow
                        sx={{
                          '&:nth-of-type(odd)': {
                            backgroundColor: 'var(--gray-50)',
                          },
                        }}
                      >
                        <TableCell width="150px">Completed Date</TableCell>
                        <TableCell align="left" width="150px">
                          Total Score
                        </TableCell>
                        <TableCell align="left" width={'calc(100% - 300px)'}>
                          Results
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {completedAssessments.map((row) => {
                        const completedAt = new Date(row?.data?.completedAt)
                        const updatedAt = new Date(row?.updatedAt)
                        const options = { year: 'numeric', month: 'long', day: 'numeric' }
                        const subScaleScores = row?.data?.answers?.score?.subScaleScores

                        const { interpretation } = assessmentDetails.getInterpretation
                          ? assessmentDetails.getInterpretation(row?.data?.answers?.score?.sum)
                          : { interpretation: '', color: '#f5f5f5' }

                        const interpretationMessage = () => {
                          if (isAce) {
                            return `Result: ${interpretation}`
                          }
                          if (interpretation) {
                            return `Sympom Severity: ${interpretation}`
                          }
                          if (isBBCSS) {
                            return subScaleOrder.map((subScale) => {
                              const roundedScore = Math.round(subScaleScores[subScale])
                              const nanFilteredScore = isNaN(roundedScore) ? '-' : roundedScore
                              return (
                                <Grid item xs={6}>
                                  {`${subScale}: ${nanFilteredScore}`}
                                </Grid>
                              )
                            })
                          }
                          return ''
                        }

                        return (
                          <TableRow
                            key={row?.data?.completedAt}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                              '&:nth-of-type(even)': {
                                backgroundColor: 'var(--gray-50)',
                              },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {completedAt
                                ? completedAt.toLocaleDateString('en-US', options)
                                : updatedAt.toLocaleDateString('en-US', options)}
                            </TableCell>
                            <TableCell align="left">
                              {row?.data?.answers?.score?.sum} out of{' '}
                              {row?.data?.answers?.score?.maximumScore}
                            </TableCell>
                            <TableCell align="left">
                              <Grid container spacing={1}>
                                {interpretationMessage()}
                              </Grid>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <div style={{ pageBreakAfter: 'always' }} />
            </>
          )}

          {!showPrintObjects && <ScoringGuide />}

          {/* page 3+ */}
          <ResponseTable
            completedAssessments={completedAssessments}
            metadata={metadata}
            isPrintMode={showPrintObjects}
            clientName={user?.fullName}
            assessmentName={filterAssessmentName}
          />

          {showPrintObjects && (
            <>
              <div style={{ pageBreakBefore: 'always' }} />
              <PrintHeader
                clientName={user?.fullName || ''}
                assessmentName={filterAssessmentName}
                pageCurrent={numberOfTables + 3}
                pageMax={numberOfTables + 3}
              />
              <ScoringGuide isPrintMode={showPrintObjects} />
            </>
          )}
        </Box>
      )}
    </>
  )
}
