import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

export default function SelectGender({ disabled, error, value, onChange }) {
  return (
    <FormControl disabled={disabled} fullWidth variant="outlined">
      <InputLabel id="select-gender-input-label" error={error} variant="outlined">
        Gender*
      </InputLabel>
      <Select
        label="Gender*"
        variant="outlined"
        error={error}
        labelId="select-gender-label"
        id="select-gender-id"
        value={value}
        onChange={onChange}
        data-test="confirm-gender-input"
      >
        <MenuItem data-test="confirm-gender-female" value={'Woman/Girl'}>
          Woman/Girl
        </MenuItem>
        <MenuItem data-test="confirm-gender-male" value={'Man/Boy'}>
          Man/Boy
        </MenuItem>
        <MenuItem data-test="confirm-gender-non-binary" value={'Non-Binary/Non-Conforming'}>
          Non-Binary/Non-Conforming
        </MenuItem>
        <MenuItem data-test="confirm-gender-no-response" value={'Prefer not to respond'}>
          Prefer not to respond
        </MenuItem>
      </Select>
    </FormControl>
  )
}
