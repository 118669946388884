import React from 'react'
import { Box, Grid, Card, CardHeader, CardContent, Typography } from 'components'

export default function GettingStarted({ gridProps }) {
  return (
    <Grid item {...gridProps}>
      <Card
        data-test="dashboard-card-enrolled-voicepro-training"
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: '#edf2f7',
          padding: '1rem',
        }}
        elevation={0}
      >
        <CardHeader
          title={'Complete your VoicePro Training'}
          titleTypographyProps={{ variant: 'h5' }}
        />
        <CardContent>
          <Typography variant="body2" gutterBottom className="py-1">
            The VoicePro Training will provide you with the support and protocols for delivering the
            VoicePro System. You will learn to create individualized sessions for your clients to
            meet their auditory processing goals and more.
          </Typography>
          <Box display="flex" className="py-1">
            <div
              style={{
                width: 12,
                height: 12,
                borderRadius: '50%',
                backgroundColor: 'currentColor',
                marginTop: 3,
                flexShrink: 0,
                color: '#008080',
              }}
            />
            <Typography p={1} fontWeight={500} variant="body2">
              Click on Academy in the left menu to access your VoicePro Training.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  )
}
