import React from 'react'
import { Typography, Tooltip } from 'components'

export default ({ strong, product = 'ssp' }) => {
  const strongText = product === 'ssp' ? 'SSP active' : 'client licenses'
  const tooltipMessage =
    product === 'ssp' ? (
      <>
        An <strong>SSP active client</strong> is a client who has listened to 30 minutes or more of
        any SSP music within your monthly billing cycle.
      </>
    ) : (
      <>
        A <strong>client license</strong> allows a client to listen to any amount of an ILS Program.
      </>
    )

  return (
    <Tooltip
      title={
        <Typography variant="body2" className="text-white">
          {tooltipMessage}
        </Typography>
      }
      sx={{ backgroundColor: '#616161' }}
      placement="top"
      arrow
    >
      <span className={`cursor-pointer underline ${strong ? 'font-medium' : 'font-normal'}`}>
        {strongText}
      </span>
    </Tooltip>
  )
}
