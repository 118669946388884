/**
 * Wrapper layout for client view
 * also contains loading intersection observer
 */
import PrivateRouteWrapper from 'components/routes/PrivateRouteWrapper'
import React, { useEffect, useRef, useState } from 'react'
import MainLayout from 'components/containers/main/Main'
import { Outlet, matchPath } from 'react-router'
import { routes } from 'utils/constants/routes'
import { gql, useQuery } from '@apollo/client'
import { setClientsSlots, setData } from 'store/modules/clients'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import ROLES from 'utils/constants/roles'
import ProgramsAndClientLicenses from '../components/modals/ProgramsAndClientLicenses'
import { useGetUserWithoutRefresh } from 'utils/hooks/useGetUser'

export const CLIENTS_PAGE_SIZE = 20

const PRODUCTS_QUERY = gql`
  query getProductsForClientsTable {
    getProducts {
      id
      name
      order
      category
    }
  }
`

const USER_QUERY_WITH_SLOTS = gql`
  query getUsersForClientsTableWithSlots(
    $filter: FilterUsersInput
    $sort: [[String!]]
    $limit: Int
    $offset: Int
  ) {
    getUsers(filter: $filter, sort: $sort, limit: $limit, offset: $offset) {
      address1
      address2
      birthYear
      city
      country
      createdAt
      dob
      email
      firstName
      fullName
      gender
      id
      isArchived
      isSuspended
      lastLoginAt
      lastName
      phone
      seats {
        id
        status
        productId
      }
      slots {
        id
        status
        category
        expiredAt
        assignedAt
        usedAt
      }
      state
      roles
      zip
      productPreferences
      provider {
        id
        fullName
      }
      uuid
    }
  }
`

const GET_AVAILABLE_SLOTS = gql`
  query GetAvailableSlots_ClientTable($filter: FilterSlotsInput) {
    getSlots(filter: $filter) {
      assignedAt
      category
      createdAt
      expiredAt
      id
      organizationId
      status
      type
      usedAt
      usedBySessionId
      userId
      updatedAt
    }
  }
`

export default function ClientsLayoutWrapper() {
  const ref = useRef(null)
  const dispatch = useDispatch()
  const [currentOffset, setCurrentOffset] = useState(0)
  const { showNewSubscriptionPlan } = useSelector((state) => state.ff)
  const [loading, setLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState()
  const [allDataFetched, setAllDataFetched] = useState(false)
  const [isRefetchOne, setIsRefetchOne] = useState(false)
  const {
    selectedUser: { hasCompletedSspCertification, hasCompletedFocusCertification },
  } = useGetUserWithoutRefresh()

  // we need a temporary copy of all clients to prevent unneccessary loading UI
  const [allClients, setAllClients] = useState([])

  const initialQuery = {
    anyRoles: [ROLES.CLIENT],
    any: '',
    isArchived: false,
    categories: [],
    includeNoSlotClients: true,
  }
  const initialVariables = {
    sort: [],
    filter: initialQuery,
    limit: CLIENTS_PAGE_SIZE,
    offset: 0,
  }

  const [queryVars, _setQueryVars] = useState({
    sort: [],
    filter: initialQuery,
    limit: CLIENTS_PAGE_SIZE,
    offset: 0,
  })

  const { data: products } = useQuery(PRODUCTS_QUERY)

  /**
   * Using network only because we have encountered caching error during really quick refreshes
   * - we cannot dynamically change queries because it will lead to caching errors
   */
  const updateUsers = async (_data, options = {}) => {
    const { moveToTop } = options
    try {
      const currentClients = get(_data, 'getUsers', [])

      const allClientsMap = moveToTop
        ? [...currentClients, ...allClients].reduce(
            (acc, client) => {
              acc.map.set(client.id, client)
              return acc
            },
            { map: new Map() }
          )
        : [...allClients, ...currentClients].reduce(
            (acc, client) => {
              acc.map.set(client.id, client)
              return acc
            },
            { map: new Map() }
          )

      const uniqueData = Array.from(allClientsMap.map.values())
      const currentUserId = get(queryVars, 'filter.ids[0]', null)

      if (!isRefetchOne) {
        setAllDataFetched(currentClients.length < CLIENTS_PAGE_SIZE)
        const newOffset = currentOffset + CLIENTS_PAGE_SIZE
        setCurrentOffset(newOffset)
        setAllClients(uniqueData)
        dispatch(setData(uniqueData))
      } else if (!currentClients.length && currentUserId) {
        // if we have a filter on and we returned an empty array, remove current user from list..
        setAllClients(uniqueData.filter(({ id }) => id === currentUserId))
        dispatch(setData(uniqueData.filter(({ id }) => id === currentUserId)))
      } else {
        setAllClients(uniqueData)
        dispatch(setData(uniqueData))
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }
  const { refetch: _refetch } = useQuery(USER_QUERY_WITH_SLOTS, {
    fetchPolicy: 'network-only',
    variables: queryVars,
    onCompleted: (data) => {
      updateUsers(data)
    },
  })

  // this function is used for infinite scrolling
  const fetch = async (variables) => {
    try {
      _setQueryVars(variables || { ...initialVariables, ...queryVars, offset: currentOffset })
    } catch (error) {
      console.error('error refetching in clients table', error)
    }
  }

  // whenever we modify the query variables, perform a refetch to get new data
  const onSetQueryVars = async (newQueryVars, offset = 0) => {
    setLoading(true)
    try {
      setIsRefetchOne(false)
      setAllClients([])
      dispatch(setData([]))
      setCurrentOffset(offset)
      const newQuery = { ...initialVariables, ...newQueryVars, offset }
      _setQueryVars(newQuery)
    } catch (error) {
      setTimeout(() => {
        setLoading(false)
      }, 3000)
    }
  }

  // this is used after we update the seats of a client `ManageSeats.js`
  // we won't use set query variables here because we don't want the table to shrink to one variable..
  const refetch = async (refetchVariables) => {
    const { data } = await _refetch(refetchVariables)
    await updateUsers(data)
  }

  const refetchOne = async (variables, options = {}) => {
    const { stopLoading, moveToTop } = options
    await setLoading(true)
    await setIsRefetchOne(true)

    try {
      const refetchVariables = {
        ...queryVars,
        filter: { ...queryVars.filter, ...variables.filter },
        offset: 0,
      }

      const { data } = await _refetch(refetchVariables)
      await updateUsers(data, { moveToTop })
      await refetchSlots()
    } catch (error) {
      console.error(error)
    } finally {
      if (!stopLoading) {
        setTimeout(() => {
          setLoading(false)
          setIsRefetchOne(false)
        }, 3000)
      }
    }
  }

  const [slotsInfo, setSlotsInfo] = useState({ ssp: [], focus: [] })

  // TO DO: change the 2 states to a map
  const [openLicensePrompt, setOpenLicensePrompt] = useState(false)
  const [openCreateClientPrompt, setOpenCreateClientPrompt] = useState(false)

  const { refetch: _refetchSlots } = useQuery(GET_AVAILABLE_SLOTS, {
    variables: {
      filter: { statuses: ['available'], types: ['plan', 'purchase'] },
    },
    fetchPolicy: 'network-only',
    skip: !showNewSubscriptionPlan,
    onCompleted: (data) => {
      updateSlots(data)
    },
  })

  useEffect(() => {
    return () => dispatch(setData([]))
  }, [])

  const updateSlots = async (_data) => {
    const slots = get(_data, 'getSlots', [])
    const hasSspSlots = slots.filter((slot) => slot.category === 'ssp')
    const hasFocusSlots = slots.filter((slot) => slot.category === 'focus')
    const availableSspSlots = slots.filter(
      ({ category, status }) => category === 'ssp' && status === 'available'
    )
    const availableFocusSlots = slots.filter(
      ({ category, status }) => category === 'focus' && status === 'available'
    )

    dispatch(
      setClientsSlots({
        availableSspSlots: availableSspSlots.length,
        availableFocusSlots: availableFocusSlots.length,
      })
    )
    setSlotsInfo({
      ssp: hasSspSlots,
      focus: hasFocusSlots,
      availableFocusSlots,
      availableSspSlots,
    })
  }

  // these are bandaid functions, please remove when we do pagination
  const refetchSlots = async () => {
    const { data } = await _refetchSlots()
    await updateSlots(data)
  }

  return (
    <PrivateRouteWrapper notAuthorized="hasProducts" roles={routes.clients.roles}>
      <MainLayout
        title="Clients"
        tabs={[
          {
            text: 'Clients',
            url: '/clients',
            isActive: (pathname) => matchPath({ path: '/clients' }, pathname),
          },
          {
            text: 'Pending Invitations',
            url: '/clients/pending-invitations',
            isActive: (pathname) => matchPath({ path: '/clients/pending-invitations' }, pathname),
          },
        ]}
      >
        <Outlet
          context={{
            allDataFetched,
            currentOffset,
            fetch,
            hasCompletedFocusCertification,
            hasCompletedSspCertification,
            initialQuery,
            loading,
            loadingMessage,
            openCreateClientPrompt,
            openLicensePrompt,
            products: get(products, 'getProducts', []),
            queryVars,
            ref,
            refetch,
            refetchOne,
            refetchSlots,
            setAllDataFetched,
            setCurrentOffset,
            setLoading,
            setLoadingMessage,
            setOpenCreateClientPrompt,
            setOpenLicensePrompt,
            setQueryVars: (newQueryVars) => onSetQueryVars(newQueryVars),
            slotsInfo,
          }}
        />
        {showNewSubscriptionPlan && <ProgramsAndClientLicenses />}
      </MainLayout>
    </PrivateRouteWrapper>
  )
}
