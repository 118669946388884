import React, { useState } from 'react'
import {
  Grid,
  Button,
  SvgIcon,
  Card,
  CardMedia,
  CardContent,
  Typography,
  useMediaQuery,
  Divider,
  Collapse,
} from 'components'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import get from 'lodash/get'
import useCart from '../utils/useCart'
import { useSelector } from 'react-redux'

export default function Product({ product }) {
  // get values from localStorage and parse it
  const { selectedAddOns = {} } = useSelector((state) => state.newPurchase.data)
  const quantity = selectedAddOns[product.SKU] || 0

  const imgSrc = get(product, 'Attachments[0]', '')
  const { SKU, Name, PriceTier1, Description } = product
  const { handleEditProducts } = useCart()
  const [expanded, setExpanded] = useState(false)

  const matches = useMediaQuery('(min-width:513px)') // ipad width is 768

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const onAdd = () => {
    handleEditProducts(SKU, 'add')
  }
  const onSubtract = () => {
    if (quantity > 0) {
      handleEditProducts(SKU, 'subtract')
    }
  }

  return (
    <Grid item xs={12} className="py-3">
      <Card
        sx={{
          display: 'flex',
          width: '100%',
          minHeight: 225,
        }}
        elevation={0}
        key={SKU}
      >
        {matches && (
          <>
            <Grid item container xs={5} alignItems="center" justifyContent="center">
              <CardMedia
                sx={{
                  height: 210,
                  width: 210,
                }}
                image={imgSrc}
              />
            </Grid>
            <Grid item xs={7}>
              <CardContent className="py-1">
                <Grid container direction="column" justifyContent="space-between">
                  <Typography variant="h5" gutterBottom>
                    {Name}
                  </Typography>
                  <Divider />

                  <Collapse in={expanded} collapsedSize={88}>
                    <Typography variant="body2" className="my-2">
                      {Description}
                    </Typography>
                  </Collapse>

                  <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    className="h-10"
                  >
                    {Description.length > 200 && (
                      <>
                        <Grid
                          item
                          sx={{
                            height: '1px',
                            backgroundColor: 'rgba(0, 0, 0, 0.12)',
                            border: 'none',
                            margin: 0,
                            width: '33%',
                          }}
                        />
                        <Grid item>
                          <Button onClick={handleExpandClick} aria-label="show more">
                            <Typography variant="button">{expanded ? 'Less' : 'More'}</Typography>
                          </Button>
                        </Grid>
                        <Grid
                          item
                          sx={{
                            height: '1px',
                            backgroundColor: 'rgba(0, 0, 0, 0.12)',
                            border: 'none',
                            margin: 0,
                            width: '33%',
                          }}
                        />
                      </>
                    )}
                  </Grid>

                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                  >
                    <Typography variant="h5" className="p-2">
                      ${PriceTier1}
                    </Typography>
                    <Grid item>
                      <Grid container direction="row">
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          onClick={onSubtract}
                          sx={{
                            minWidth: 0,
                          }}
                          data-test="product-remove-button"
                        >
                          <SvgIcon
                            component={RemoveIcon}
                            sx={{
                              height: '1.5rem',
                            }}
                          />
                        </Button>
                        <Typography
                          data-test="product-count"
                          variant="body1"
                          className="text-center m-auto px-5"
                        >
                          {quantity}
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          onClick={onAdd}
                          sx={{
                            minWidth: 0,
                          }}
                          data-test="product-add-button"
                        >
                          <SvgIcon
                            component={AddIcon}
                            sx={{
                              height: '1.5rem',
                            }}
                          />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Grid>
          </>
        )}
        {!matches && (
          <div
            style={{
              display: 'block',
            }}
          >
            <div
              style={{
                display: 'grid',
                placeItems: 'center',
                padding: '0.5rem',
              }}
            >
              <CardMedia
                sx={{
                  height: 210,
                  width: 210,
                }}
                image={imgSrc}
              />
            </div>
            <CardContent className="py-1">
              <Grid container direction="column" justifyContent="space-between">
                <Typography variant="h5" gutterBottom>
                  {Name}
                </Typography>
                <Divider />

                <Collapse in={expanded} collapsedSize={88}>
                  <Typography variant="body2" className="my-2">
                    {Description}
                  </Typography>
                </Collapse>

                <Grid
                  container
                  direction="row"
                  justifyContent="space-around"
                  alignItems="center"
                  className="h-10"
                >
                  {Description.length > 200 && (
                    <>
                      <Grid
                        item
                        sx={{
                          height: '1px',
                          backgroundColor: 'rgba(0, 0, 0, 0.12)',
                          border: 'none',
                          margin: 0,
                          width: '33%',
                        }}
                      />
                      <Grid item>
                        <Button onClick={handleExpandClick} aria-label="show more">
                          <Typography variant="button">{expanded ? 'Less' : 'More'}</Typography>
                        </Button>
                      </Grid>
                      <Grid
                        item
                        sx={{
                          height: '1px',
                          backgroundColor: 'rgba(0, 0, 0, 0.12)',
                          border: 'none',
                          margin: 0,
                          width: '33%',
                        }}
                      />
                    </>
                  )}
                </Grid>

                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <Typography variant="h5" className="p-2">
                    ${PriceTier1}
                  </Typography>
                  <Grid item>
                    <Grid container direction="row">
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={onSubtract}
                        sx={{
                          minWidth: 0,
                        }}
                      >
                        <SvgIcon
                          component={RemoveIcon}
                          sx={{
                            height: '1.5rem',
                          }}
                        />
                      </Button>
                      <Typography variant="body1" className="text-center m-auto px-5">
                        {quantity}
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={onAdd}
                        sx={{
                          minWidth: 0,
                        }}
                      >
                        <SvgIcon
                          component={AddIcon}
                          sx={{
                            height: '1.5rem',
                          }}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </div>
        )}
      </Card>
    </Grid>
  )
}
