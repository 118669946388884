import React from 'react'
import MainLayout from 'components/containers/main/Main'
import { Grid, CardContent, Container, Card, Typography, Divider } from 'components'
import get from 'lodash/get'

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import { useQuery } from 'utils/apollo'
import { gql } from '@apollo/client'
import { useSelector } from 'react-redux'

import moment from 'utils/moment'
import DATE_FORMAT from 'utils/constants/formats'
import { clientPluralization } from 'utils/client-pluralization'
import { BILLING_TABS } from './tabs'

export const GET_ACTIVE_CLIENTS = gql`
  query GetActiveClients($numberOfPeriods: Int) {
    getActiveClients(numberOfPeriods: $numberOfPeriods)
  }
`

export const getActiveClients = (array, userId) => {
  return array.find(({ providerId }) => providerId === userId)?.activeClients || 0
}
/**
 * Given a Date, return a list of length [length] that are 1 month apart excluding the first month
 */
export default function ActiveClients() {
  const isLoggedIn = useSelector((state) => get(state, 'auth.user', null))
  // skip if not logged in
  const { data, loading } = useQuery(GET_ACTIVE_CLIENTS, {
    fetchPolicy: 'network-only',
    variables: {
      numberOfPeriods: 12,
    },
    skip: !isLoggedIn,
  })

  const userId = useSelector((state) => get(state, 'auth.user.id', ''))
  const activeClients = get(data, 'getActiveClients', [])
  const [currentMonth, ...history] = activeClients

  // are you the only provider with clients?
  const onlyProvider =
    activeClients &&
    activeClients.every(({ activeClients, count }) => getActiveClients(activeClients) === count)

  const loadingCount = 2 + (onlyProvider ? 0 : 1)

  const Loading = ({ count }) => (
    <TableBody>
      {Array.from({ length: 3 }, () => (
        <TableRow>
          {Array.from({ length: count }, () => (
            <TableCell align="right">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                style={{
                  '--shine': 'white',
                  '--background': 'var(--gray)',
                  backgroundImage: `linear-gradient(
                    90deg,
                    var(--background) 0px,
                    var(--shine) 40px,
                    var(--background) 80px
                  )`,
                  backgroundSize: '500px',
                  animation: `$shine 2s infinite linear`,
                }}
                alt=""
                height="15"
                width="100%"
              />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  )

  const {
    hasSspProducts,
    hasFocusProducts,
    showBillingHistory,
    showNewSSPSubscriptionPlan,
    showNewFocusSubscriptionPlan,
  } = useSelector((state) => state.ff)

  const tabs = BILLING_TABS.filter(({ isEnabled }) =>
    isEnabled({
      showBillingHistory,
      hasSspProducts,
      hasFocusProducts,
      showNewSSPSubscriptionPlan,
      showNewFocusSubscriptionPlan,
    })
  )

  return (
    <MainLayout title="My Billing" tabs={tabs}>
      <Container maxWidth="lg">
        <div className="p-5">
          <Typography variant="h5" className="pb-2">
            Current SSP Active Clients{' '}
            {currentMonth &&
              `for ${moment(currentMonth.fromDate)
                .utc()
                .format(DATE_FORMAT.date)} to ${moment(currentMonth.toDate)
                .utc()
                .format(DATE_FORMAT.date)}`}
          </Typography>

          {currentMonth && !currentMonth.count && (
            <Typography variant="body2" gutterBottom className="text-link">
              No SSP active clients this month.
            </Typography>
          )}

          {currentMonth && !!currentMonth.count && (
            <>
              <Typography variant="body2" gutterBottom className="text-link">
                An <strong>active client</strong> is a client who has listened to 30 minutes or more
                of any SSP music within your monthly billing cycle.
              </Typography>
              <Grid container direction="row" justifyContent="space-evenly" className="py-3">
                <Grid item xs={4}>
                  <Card
                    sx={{
                      backgroundColor: 'var(--teal)',
                    }}
                  >
                    <CardContent>
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography variant="h4" className="px-2 text-white">
                          {getActiveClients(currentMonth.activeClients, userId)}
                        </Typography>
                        <Typography variant="body1" className="text-white mt-2">
                          Your Active{' '}
                          {clientPluralization(
                            getActiveClients(currentMonth.activeClients, userId)
                          )}
                        </Typography>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                {!onlyProvider && (
                  <Grid item xs={4}>
                    <Card
                      sx={{
                        backgroundColor: 'var(--lightGreen)',
                      }}
                    >
                      <CardContent>
                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Typography variant="h4" className="px-2 text-white">
                            {currentMonth.count}
                          </Typography>
                          <Typography variant="body1" className="text-white mt-2">
                            Organization SSP Active Clients
                          </Typography>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </>
          )}

          <Typography variant="h5" className="pt-5 pb-2">
            SSP Active Clients History
          </Typography>
          <TableContainer component={Paper}>
            <Table
              sx={{
                color: '#696969',
                fontSize: 14,
                minWidth: 300,
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Monthly Cycle</TableCell>
                  <TableCell align="right">Your SSP Active Clients</TableCell>
                  {!onlyProvider && (
                    <TableCell align="right">Organization's Active Clients</TableCell>
                  )}
                </TableRow>
              </TableHead>
              {loading && <Loading count={loadingCount} />}
              {!!history && (
                <TableBody>
                  {history.map((row) => (
                    <TableRow key={row.fromDate}>
                      <TableCell component="th" scope="row">
                        {moment(row.fromDate)
                          .utc()
                          .format(DATE_FORMAT.date)}{' '}
                        -{' '}
                        {moment(row.toDate)
                          .utc()
                          .format(DATE_FORMAT.date)}
                      </TableCell>
                      <TableCell align="right">
                        {getActiveClients(row.activeClients, userId)}
                      </TableCell>
                      {!onlyProvider && <TableCell align="right">{row.count}</TableCell>}
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </div>
        <Divider />
        <Typography variant="body2" gutterBottom className="text-link py-5 px-5">
          *All dates are in UTC timezone.
        </Typography>
      </Container>
    </MainLayout>
  )
}
