/**
 * Due to Table being used in provider and admin, there is a need to refactor
 * for just client table as 90% of the functionality is not needed for provider
 * or admins
 */
import React, { Fragment, useEffect, useRef } from 'react'
import get from 'lodash/get'

import { Table, TableBody, TableCell, TableHead, TableRow, Skeleton, Grid } from 'components'
import ExpandIcon from '@mui/icons-material/ExpandMore'
import CollapseIcon from '@mui/icons-material/ExpandLess'

import styles from './Table.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser, setEdit, setForm } from 'store/modules/clients'
import { useOutletContext } from 'react-router'
import ClientsDetails from 'views/users/clients/ClientsDetails'

export default function ClientTableComponent({
  data = [],
  columns = [],
  sort = [],
  onSort = () => null,
  selectedRow: _selectedRow,
  rowQuantity = 5,
  highlightDetails,
  hideTableHead = false,
}) {
  const dispatch = useDispatch()
  const ref = useRef(null)
  const {
    loading,
    queryVars,
    allDataFetched,
    setLoading,
    currentOffset,
    fetch,
  } = useOutletContext()

  const selectedRow = useSelector((state) => state.clients.selectedUser)
  const onTableRowClick = (index, isSelected) => () => {
    const selected = isSelected ? null : index
    dispatch(selectUser(selected))
    dispatch(setEdit(false))
    dispatch(setForm(null))
  }

  useEffect(() => {
    let observerRefValue = null
    const observer = new IntersectionObserver(
      async ([entry]) => {
        if (entry.isIntersecting && !loading && !allDataFetched) {
          await setLoading(true)
          // we need logic below for users with fully loaded clients
          await fetch({ ...queryVars, offset: currentOffset })
          await observerRefValue.current?.focus()
        }
      },
      {
        root: null,
        threshold: 0.1,
      }
    )
    if (ref.current && !loading && !allDataFetched) {
      observer.observe(ref.current)
      observerRefValue = ref.current
    }

    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue)
      }
    }
    // eslint-disable-next-line
  }, [allDataFetched, loading])

  return (
    <div style={{ overflowX: 'auto' }}>
      <Table>
        {!hideTableHead && (
          <TableHead>
            <TableRow key="tablehead">
              {columns.map(({ header: Cell, width }, i) => (
                <TableCell key={`tablehead${i}`} style={{ width, backgroundColor: '#ffffff' }}>
                  <Cell sort={sort} onSort={onSort} />
                </TableCell>
              ))}
              <TableCell style={{ width: '20', backgroundColor: '#ffffff' }} />
            </TableRow>
          </TableHead>
        )}
        <TableBody data-test="table-body">
          {!data.length && !loading && (
            <TableRow>
              <TableCell colSpan={columns.length + 1}>
                <Grid container justifyContent="center" className="fill-width p-5">
                  No data to display
                </Grid>
              </TableCell>
            </TableRow>
          )}

          {data?.map((row, rowIndex) => {
            const isSelected = selectedRow === rowIndex
            return (
              <Fragment key={`data${rowIndex}`}>
                <TableRow
                  data-test={`table-row${rowIndex}`}
                  hover={true}
                  selected={isSelected}
                  className={styles.row}
                  onClick={onTableRowClick(rowIndex, isSelected)}
                >
                  {columns.map(({ body: Cell = () => null }, i) => (
                    <TableCell key={`data${rowIndex}${i}`}>
                      <Cell data={row} />
                    </TableCell>
                  ))}
                  <TableCell>
                    {isSelected && <CollapseIcon style={{ position: 'relative', right: '20px' }} />}
                    {!isSelected && <ExpandIcon style={{ position: 'relative', right: '20px' }} />}
                  </TableCell>
                </TableRow>

                {isSelected && (
                  <TableRow className={highlightDetails && 'bg-table-highlighted'}>
                    <TableCell colSpan={columns.length + 1}>
                      <ClientsDetails user={get(data, selectedRow, {})} />
                    </TableCell>
                  </TableRow>
                )}
              </Fragment>
            )
          })}
          {loading &&
            Array.from(new Array(rowQuantity)).map((_, index) => (
              <TableRow key={`loading${index}`}>
                {columns.map((col, cellIndex) => (
                  <TableCell key={`loading${index}${cellIndex}`}>
                    <Skeleton variant="rectangular" />
                  </TableCell>
                ))}
                <TableCell>
                  <Skeleton variant="rectangular" />
                </TableCell>
              </TableRow>
            ))}
          {!loading && !allDataFetched && <TableRow ref={ref} id="observer-ref-id" />}
        </TableBody>
      </Table>
    </div>
  )
}
