import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from 'utils/apollo'
import {
  FormHelperText,
  Card,
  Grid,
  CardContent,
  Button,
  CardActions,
  CardHeader,
  Menu,
  Typography,
  MenuItem,
} from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import get from 'lodash/get'
import { setFF } from 'store/modules/ff'
import { useNavigate, Link } from 'react-router-dom'

import CardOption from 'components/stripe/CardOption'
import AddCard from 'components/stripe/AddCard'
import AddIcon from '@mui/icons-material/Add'
import { setError, setShowError, setPending } from 'store/modules/credit-card'
import useCart from 'views/new-purchase/utils/useCart'
import useGTM from 'utils/hooks/useGTM'
import {
  SSP_SUBSCRIPTION_SKUS,
  PRODUCTS,
  ALL_FOCUS_SUBSCRIPTIONS_SKUS,
} from 'utils/constants/prices'

import formatMoney from '../new-purchase/utils/formatMoney'
import PurchaseLayout from './components/PurchaseLayout'

// purchase service libraries
import { gql } from '@apollo/client'
import {
  client,
  AUTH_FINALIZE_PURCHASE,
  AUTH_UPDATE_PURCHASE,
} from 'utils/apollo/purchaseService/client'
import { extendData } from '../../store/modules/new-purchase'
import { setOrganizationValue } from 'store/modules/organization'
import { TermsOfUse } from 'components'

const ITEM_HEIGHT = 48

const GET_CARDS = gql`
  query GetStripePaymentMethods {
    getStripePaymentMethods
  }
`

const useSelectedCard = () => {
  const [cards, setCards] = useState([])

  // fetch card everytime
  const { loading, refetch } = useQuery(GET_CARDS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { defaultStripePaymentMethodId, stripePaymentMethods = [] } = get(
        data,
        'getStripePaymentMethods',
        {}
      )
      setCards(
        stripePaymentMethods.map((paymentMethod) => ({
          ...paymentMethod,
          selected: defaultStripePaymentMethodId === paymentMethod.id,
          default: defaultStripePaymentMethodId === paymentMethod.id,
        }))
      )
    },
  })

  return {
    loading,
    refetch,
    cards,
    selectCard: (selectedPaymentMethod) =>
      setCards(
        cards.map((paymentMethod) => ({
          ...paymentMethod,
          selected: selectedPaymentMethod.id === paymentMethod.id,
        }))
      ),
  }
}

export default () => {
  const dispatch = useDispatch()
  const [agreement, setAgreement] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingText, setLoadingText] = useState(false)
  const [showPurchaseError, setShowPurchaseError] = useState(false)
  const [hasUpdatePurchaseError, setHasUpdatePurchaseError] = useState(false)
  const { disable, pending, error, showError: showCreditCardError, makeDefault } = useSelector(
    (state) => state.creditCard
  )
  const hasSspProducts = useSelector((state) => state.ff.hasSspProducts)
  const hasFocusProducts = useSelector((state) => state.ff.hasFocusProducts)
  const { refetch } = useSelector((state) => state.organization)
  const navigate = useNavigate()
  const {
    selectedAddOnsArray,
    clientLicensesArray,
    totalCharged,
    shipstationRates,
    subtotalCharge,
    tax,
    shippingCost,
  } = useCart()

  const [anchorEl, setAnchorEl] = useState(null)
  const [openAddCard, setOpenAddCard] = useState(false)
  const open = Boolean(anchorEl)

  const { cards, selectCard, refetch: refetchPaymentMethod } = useSelectedCard()

  const stripePaymentMethodId = cards?.find(({ selected }) => selected)?.id

  // get shipstation rates
  const { serviceCode } = get(shipstationRates, '[0]', {
    shipmentCost: 0,
    otherCost: 0,
    serviceCode: '',
  })

  // get all values from redux because we are not getting any saved values here
  const {
    onboardingProducts = [],
    fullName,
    email,
    phoneNumber,
    address1,
    address2,
    toPostalCode,
    toState,
    toCountry,
    toCity,
    coupon,
    uuid,
    appliedPromoCode,
  } = useSelector((state) => state.newPurchase.data)

  // user info
  const purchasedSkus = [
    ...selectedAddOnsArray,
    ...clientLicensesArray,
    ...onboardingProducts.map((item) => ({ sku: item, quantity: 1 })),
  ]
  const hasSspSubscription = SSP_SUBSCRIPTION_SKUS.some((key) => onboardingProducts.includes(key))
  const hasFocusSubscription = ALL_FOCUS_SUBSCRIPTIONS_SKUS.some((key) =>
    onboardingProducts.includes(key)
  )
  const hasPhysicalProduct = onboardingProducts.some(
    (sku) =>
      PRODUCTS[sku] &&
      (PRODUCTS[sku].type === 'physicalProduct' || PRODUCTS[sku].isPromoWithShipping)
  )

  // google tag manager
  const { purchase: gtmPurchase, pageView } = useGTM({})
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
    dispatch(setShowError(false))
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  if ((hasSspProducts && hasSspSubscription) || (hasFocusProducts && hasFocusSubscription)) {
    navigate('/purchase/success')
  }

  const hasShipping = selectedAddOnsArray.length || hasPhysicalProduct
  const authUpdatePurchase = {
    params: {
      coupon_id: coupon?.data?.id,
      shipping_address: hasShipping
        ? {
            full_name: fullName,
            email,
            phone_number: phoneNumber,
            address1,
            address2,
            postal_code: toPostalCode,
            country: toCountry,
            state: toState,
            city: toCity,
          }
        : {},
      shipment_method: hasShipping ? serviceCode : '',
      stripe_payment_method_id: stripePaymentMethodId,
      items: purchasedSkus.map(({ sku, quantity }) => ({
        sku,
        quantity,
      })),
    },
    uuid,
  }
  const [updatePurchase] = useMutation(AUTH_UPDATE_PURCHASE, {
    client,
  })
  const [finalizePurchase] = useMutation(AUTH_FINALIZE_PURCHASE, {
    client,
    variables: {
      params: {
        stripe_payment_method_id: stripePaymentMethodId,
      },
      uuid,
    },
  })

  const {
    hasPaidSspSubscription,
    hasPastDueSspSubscription,
    hasPendingSspSubscription,
    hasPaidFocusSubscription,
    hasPastDueFocusSubscription,
    hasPendingFocusSubscription,
    hasAllCanceledSspSubscription,
  } = useSelector((state) => get(state, 'organization', {}))

  const hasActiveSspSubscription =
    hasPastDueSspSubscription || hasPaidSspSubscription || hasPendingSspSubscription
  const hasActiveFocusSubscription =
    hasPaidFocusSubscription || hasPastDueFocusSubscription || hasPendingFocusSubscription

  const isNewUser = !hasActiveFocusSubscription && !hasActiveSspSubscription

  const onSubmitBeta = async (event) => {
    event.preventDefault()
    setLoading(true)
    dispatch(setError(null))
    dispatch(setShowError(false))
    try {
      // update purchase if haven't already on load
      // items that has shipping gets updated again
      if (hasShipping) {
        setLoadingText('updating purchase...')
        await updatePurchase({
          variables: { ...authUpdatePurchase },
        })
      }

      // finalize purchase
      setLoadingText('finalizing purchase...')
      await finalizePurchase()
      await refetch()

      // send to google tag manager / analytics
      await gtmPurchase({
        value: totalCharged,
        transaction_id: uuid,
        coupon: appliedPromoCode,
        shipping: shippingCost,
        tax,
      })

      // all purchases besides ssp can be redirected to success and not wait for flags to update
      if (hasSspSubscription) {
        dispatch(
          setFF({
            hasSspProducts: true,
            hasAssessmentProducts: true,
            showNewSSPSubscriptionPlan: isNewUser || hasAllCanceledSspSubscription,
            showNewSubscriptionPlan: isNewUser || hasAllCanceledSspSubscription,
          })
        )
        dispatch(
          setOrganizationValue({
            hasAllCanceledSspSubscription: false,
            hasPaidSspSubscription: true,
            isProcessingPayment: true,
          })
        )
      } else if (hasFocusSubscription) {
        dispatch(setFF({ hasFocusProducts: true, hasAssessmentProducts: true }))
        dispatch(
          setOrganizationValue({
            hasPaidFocusSubscription: true,
            hasAllCanceledFocusSubscription: false,
            isProcessingPayment: true,
          })
        )
      }

      navigate('/purchase/success')
    } catch (error) {
      console.error(error)
      await dispatch(setError(error.message))
      await dispatch(setShowError(true))
      setShowPurchaseError(true)
    } finally {
      await dispatch(setPending(false))
      setLoading(false)
    }
  }

  // now that internal accessories is released, redirect user to products
  const onBack = async () => {
    navigate(-1)
  }

  // when cards is changed, we want to select the latest card as the current payment method
  const [updateCard, setUpdateCard] = useState(false)
  const refetchAndSelectCard = async () => {
    await refetchPaymentMethod()
    if (cards && !makeDefault) {
      setUpdateCard(true)
    }
  }

  useEffect(() => {
    if (cards?.length && selectCard && updateCard) {
      selectCard(cards[0])
      setUpdateCard(false)
    }
    // eslint-disable-next-line
  }, [updateCard])

  useEffect(() => {
    dispatch(setPending(false))

    // on load: send virtual page load
    pageView({
      pageUrl: window.document.location.href,
      pageTitle: 'Internal Purchase - Enter billing info and confirm purchase',
    })
    // eslint-disable-next-line
  }, [])

  // onload if there is no shipping address, update purchase to get tax..
  useEffect(() => {
    const updateTax = async () => {
      setLoading(true)
      dispatch(setError(null))
      dispatch(setShowError(false))
      try {
        setLoadingText('updating purchase...')
        const result = await updatePurchase({
          variables: { ...authUpdatePurchase },
        })
        const tax = get(result, 'data.auth_update_purchase.stripe_invoice.tax', 0)
        const taxEnabled =
          get(result, 'data.auth_update_purchase.stripe_invoice.automatic_tax.enabled', null) ===
          true
        const taxComplete =
          get(result, 'data.auth_update_purchase.stripe_invoice.automatic_tax.status', null) ===
          'complete'

        if (taxEnabled && !taxComplete) {
          await dispatch(setError('Please update the credit card information'))
          await dispatch(setShowError(true))
        }

        await dispatch(
          extendData({
            totalTax: tax,
          })
        )
      } catch (error) {
        console.error('internal purchase on load update', error)
        await dispatch(setError(error.message))
        await dispatch(setShowError(true))
        await setShowPurchaseError(true)
        setHasUpdatePurchaseError(true)
      } finally {
        setLoading(false)
      }
    }

    // only run on load functions if feature flag is present
    setHasUpdatePurchaseError(false)
    if (!hasShipping && stripePaymentMethodId) {
      updateTax()
    }
    window.scrollTo(0, 0) // for mobile view to see promo code first
    // eslint-disable-next-line
  }, [stripePaymentMethodId])

  // we initialize showPurchaseError as false so we don't see errors on load
  const showError = showCreditCardError && showPurchaseError
  const changeCardText = cards?.filter(({ selected }) => !!selected).length
    ? 'Change Payment Method'
    : 'Add Payment Method'

  return (
    <PurchaseLayout
      title="Confirm your purchase"
      isConfirmPage
      loading={loading || pending}
      loadingText={loadingText}
    >
      <Card sx={{ p: 3 }}>
        <CardHeader title="Billing Information" />
        <CardContent>
          <Typography variant="caption" gutterBottom className="pb-5">
            Please select payment method, or add a new one. You can manage saved payment methods and
            select default on <Link to="/billing">Billing</Link> page.
          </Typography>
        </CardContent>

        <CardContent>
          {cards
            ?.filter(({ selected }) => !!selected)
            .map((item) => (
              <CardOption id={item.id} card={item.card} disableEdit={true} default={false} />
            ))}
          <Grid>
            <Button className="mb-5" onClick={handleOpen} disabled={pending} color="primary">
              {changeCardText}
            </Button>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={closeMenu}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                },
              }}
            >
              {cards
                ?.filter(({ selected }) => !selected)
                .map((item) => (
                  <MenuItem
                    sx={{
                      '&:hover': {
                        backgroundColor: '#f5f5f5',
                      },
                      height: 48,
                    }}
                    onClick={() => {
                      closeMenu()
                      selectCard(item)
                    }}
                  >
                    <CardOption
                      id={item.id}
                      card={item.card}
                      disableEdit={true}
                      default={false}
                      isMenuItem={true}
                    />
                  </MenuItem>
                ))}
              <MenuItem
                sx={{
                  '&:hover': {
                    backgroundColor: '#f5f5f5',
                  },
                  height: 48,
                }}
                onClick={() => {
                  closeMenu()
                  setOpenAddCard(!openAddCard)
                }}
              >
                <AddIcon
                  sx={{
                    paddingLeft: '1rem',
                    width: '4rem',
                  }}
                />
                <Typography
                  sx={{
                    paddingLeft: '1.75rem',
                  }}
                >
                  Add Payment
                </Typography>
              </MenuItem>
            </Menu>
          </Grid>

          {showError && !openAddCard && (
            <>
              <FormHelperText error>
                Sorry but we had an issue adding your payment method.
                <br />
                For reference: "{error}"
                <br />
                Please try again or contact support if this issue persists.
              </FormHelperText>
            </>
          )}

          {totalCharged !== subtotalCharge && (
            <Typography variant="caption" gutterBottom className="pt-5">
              You will be charged {formatMoney(totalCharged)} today.{' '}
            </Typography>
          )}
          <div className="my-5">
            <TermsOfUse disabled={pending} checked={agreement} onChange={setAgreement} />
          </div>
        </CardContent>
        <CardActions className="p-5">
          <Grid className="mt-5" container justifyContent="space-between">
            <Button disabled={pending} color="primary" className="mr-1" onClick={onBack}>
              Back
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={
                !agreement ||
                !cards?.find(({ selected }) => !!selected) ||
                disable ||
                hasUpdatePurchaseError
              }
              onClick={onSubmitBeta}
            >
              Confirm Purchase
            </Button>
          </Grid>
        </CardActions>
      </Card>
      <AddCard
        setOpen={setOpenAddCard}
        open={openAddCard}
        refetch={refetchAndSelectCard}
        authUpdatePurchaseObject={authUpdatePurchase}
        authUpdatePurchase={updatePurchase}
        hasShipping={hasShipping}
        setLoadingText={setLoadingText}
        isCardsEmpty={cards.length === 0}
      />
    </PurchaseLayout>
  )
}
