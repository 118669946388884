/**
 * This file is using cabal case as this is the best practice illustrated from nextjs
 *
 */
import React from 'react'
import {
  Button,
  FormControl,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  Stack,
  TableCell,
  Typography,
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'

// Icons
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'

// Type for props of CustomPagination
interface CustomPaginationProps {
  rowsPerPage: number
  count: number
  page: number
  onPageChange: (event: React.ChangeEvent<unknown>, value: number) => void
}

const PrevButton: React.FC = () => {
  return <Button startIcon={<KeyboardArrowLeft />}>Prev</Button>
}

const NextButton: React.FC = () => {
  return <Button endIcon={<KeyboardArrowRight />}>Next</Button>
}

const CustomPagination: React.FC<CustomPaginationProps> = ({
  rowsPerPage,
  count,
  page,
  onPageChange,
  ...props
}) => {
  const paginationCount = Math.ceil(count / rowsPerPage)

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    onPageChange(event, value - 1)
  }

  return (
    <Pagination
      {...props}
      count={paginationCount}
      color="primary"
      page={page + 1}
      onChange={handleChange}
      renderItem={(item) => (
        <PaginationItem
          slots={{
            previous: PrevButton,
            next: NextButton,
          }}
          {...item}
        />
      )}
    />
  )
}

interface CustomTablePaginationProps {
  totalAssessmentsCount: number
  page: number
  rowsPerPage: number
  handleChangePage: (event: React.ChangeEvent<unknown>, newPage: number) => void
  handleChangeRowsPerPage: (event: SelectChangeEvent<number>) => void
  colSpan: number
}

export const CustomTablePagination: React.FC<CustomTablePaginationProps> = ({
  totalAssessmentsCount,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  colSpan,
}) => {
  const displayMessage = totalAssessmentsCount
    ? ` Displaying ${1 + page * rowsPerPage} - ${Math.min(
        rowsPerPage * (page + 1),
        totalAssessmentsCount
      )} of ${totalAssessmentsCount} rows `
    : 'Displaying 0 rows'
  return (
    <TableCell
      colSpan={colSpan}
      sx={{
        backgroundColor: '#f5f5f5',
      }}
    >
      <Stack justifyContent="space-between" direction="row">
        <Typography variant="body2" alignContent="center">
          {displayMessage}
        </Typography>

        <CustomPagination
          rowsPerPage={rowsPerPage}
          count={totalAssessmentsCount}
          page={page}
          onPageChange={handleChangePage}
        />

        <Stack direction="row" alignItems="center" gap={2}>
          <Typography variant="body2">Rows per page: </Typography>
          <FormControl variant="standard">
            <Select
              labelId="rows-per-page-label-id"
              id="rows-per-page-id"
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              label="Rows"
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Stack>
    </TableCell>
  )
}
