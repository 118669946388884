/**
 * This function is only used in the clients table
 *
 * This modal has 2 steps:
 * 1. confirmation (this is shown to inperson clients)
 * 2. manage seats (this is shown to both)
 */
import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import {
  Button,
  Switch,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
} from '@mui/material'
import { TextField } from 'components'
import get from 'lodash/get'
import { useMutation } from 'utils/apollo'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { useOutletContext } from 'react-router'
import {
  CREATE_INVITATION,
  CREATE_SEAT,
  LICENSES_QUERY,
  UPDATE_SEAT,
  ASSIGN_SLOT_TO_USER,
  UNASSIGN_SLOT_FROM_USER,
  ASSIGN_SLOT_TO_INVITATION,
} from 'views/users/clients/queries'
import { useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'
import { LoadingModal } from 'components/LoadingPage'
import { maskValidationMessage } from 'utils/apollo/errors'

export function validateEmail(email) {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(email)
}

function transformArrayToMap(arr = []) {
  const result = {}

  arr.forEach((item) => {
    if (item.status === 'active') {
      result[item.productId] = true
    }
  })

  return result
}

const DialogTitle = ({ pageState, email }) => {
  const dialogTitle = {
    1: 'Manage Delivery',
    2: 'Manage In-Person Delivery',
    3: email ? 'Manage Remote Delivery' : 'Send Invitation for Remote Delivery',
  }

  return <MuiDialogTitle>{dialogTitle[pageState]}</MuiDialogTitle>
}

const DialogActions = ({
  onClose,
  pageState,
  handleUpdateSlot,
  handleSendInvitation,
  setPageState,
  isRemoteUser,
  email,
}) => {
  return (
    <MuiDialogActions>
      <Button color="secondary" onClick={onClose}>
        Cancel
      </Button>
      {pageState === 1 && (
        <>
          <Button onClick={() => setPageState(2)}>Manage In-Person Delivery</Button>
          <Button onClick={() => setPageState(3)}>Enable Remote Delivery</Button>
        </>
      )}
      {pageState === 2 && <Button onClick={handleUpdateSlot}>Save</Button>}
      {pageState === 3 && (
        <Button
          color="primary"
          onClick={handleSendInvitation}
          disabled={!isRemoteUser && (!email || (email && !validateEmail(email)))}
        >
          Submit
        </Button>
      )}
    </MuiDialogActions>
  )
}

export default function ManageSeats({ open, user, onClose: _onClose }) {
  const { enqueueSnackbar } = useSnackbar()
  const { hasSspProducts, hasFocusProducts } = useSelector((state) => state.ff)

  // parse variables from parameters
  const isRemoteUser = !!user.email
  const userAssignedSspSlot = user.slots?.find(
    ({ category, status }) => category === 'ssp' && status === 'assigned'
  )
  const userAssignedFocusSlot = user.slots?.find(
    ({ category, status }) => category === 'focus' && status === 'assigned'
  )
  const userSspSlotId = userAssignedSspSlot?.id
  const userFocusSlotId = userAssignedFocusSlot?.id
  const userUsedSspSlot = user.slots?.find(
    ({ category, status }) => category === 'ssp' && status === 'used'
  )
  const userUsedFocusSlot = user.slots?.find(
    ({ category, status }) => category === 'focus' && status === 'used'
  )

  // states
  const [products, setProducts] = useState([])
  const [productIds, setProductIds] = useState([])
  const [form, setForm] = useState({})
  // we need to set its own separate loading..
  const [loading, setLoading] = useState(false)

  // context states
  const {
    refetchOne,
    slotsInfo,
    setLoadingMessage,
    loadingMessage,
    hasCompletedFocusCertification,
    hasCompletedSspCertification,
  } = useOutletContext()
  const { showNewSubscriptionPlan } = useSelector((state) => state.ff)
  const [pageState, setPageState] = useState(!showNewSubscriptionPlan || isRemoteUser ? 3 : 1)
  const [switchTypes, setSwitchTypes] = useState({
    ssp: !!(userUsedSspSlot || userAssignedSspSlot),
    focus: !!(userUsedFocusSlot || userAssignedFocusSlot),
  })

  const [error, setError] = useState({ state: false, message: '' })

  // queries & mutations
  useQuery(LICENSES_QUERY, {
    variables: {
      filter: {
        isActive: true,
      },
      sort: [['product', 'order', 'ASC']],
    },
    onCompleted: (data) => {
      const products = get(data, 'getLicenses', [])
        .map(({ product, id: licenseId }) => {
          const isAssessment = get(product, 'category', '') === 'assessment'
          if (!isAssessment) {
            return { ...product, licenseId }
          }
          return null
        })
        .filter(Boolean)
      setProducts(products)
      // since this only happens on load..
      const onLoadProductIds = transformArrayToMap(user.seats)
      setProductIds(onLoadProductIds)
    },
  })
  const [assignSlotToUser] = useMutation(ASSIGN_SLOT_TO_USER)
  const [assignSlotToInvitation] = useMutation(ASSIGN_SLOT_TO_INVITATION)
  const [unassignSlotFromUser] = useMutation(UNASSIGN_SLOT_FROM_USER)
  const [createInvitation] = useMutation(CREATE_INVITATION)
  const [updateSeat] = useMutation(UPDATE_SEAT, {
    onError: (err) => {
      const message = 'Failed to assign program. Please refresh page and try again.'

      enqueueSnackbar(message, {
        variant: 'error',
        action: CloseSnackbarAction,
      })
      throw new Error(err)
    },
  })
  const [createSeat] = useMutation(CREATE_SEAT, {
    onError: (err) => {
      const message = 'Failed to assign program. Please refresh page and try again.'

      enqueueSnackbar(message, {
        variant: 'error',
        action: CloseSnackbarAction,
      })
      throw new Error(err)
    },
  })

  const handleProductIds = (productId) => (event) => {
    setProductIds({ ...productIds, [productId]: event.target.checked })
  }

  /**
   * if a client does not have email, then their initial page state is confirmation
   */

  const setFormValue = (key, { path = 'target.value' } = {}, overrideValues) => (e) => {
    if (overrideValues) {
      setForm({ ...form, ...overrideValues })
      return
    }
    const val = path === '' ? e : get(e, path)

    const nextForm = { ...form, [key]: val }
    setForm(nextForm)
  }
  const handleSwitch = (type) => (event) => {
    setSwitchTypes({ ...switchTypes, [type]: event.target.checked })
  }

  const onClose = async () => {
    try {
      await _onClose()
    } finally {
      // add a timeout to this, because it was switching state too fast
      if (showNewSubscriptionPlan) {
        setTimeout(() => setPageState(isRemoteUser ? 3 : 1), 500)
      }
    }
  }

  // get the first slot found and assign to user
  const sspSlotId = get(slotsInfo, 'availableSspSlots[0].id', null)
  const focusSlotId = get(slotsInfo, 'availableFocusSlots[0].id', null)
  const handleUpdateSlot = async () => {
    setLoading(true)
    setLoadingMessage('updating client...')
    try {
      // only update if user originally started without having slots
      if (switchTypes.ssp && sspSlotId && user.id && !userSspSlotId && !userUsedSspSlot) {
        try {
          await assignSlotToUser({
            variables: {
              userId: user.id,
              productCategory: 'ssp',
            },
          })
        } catch (e) {
          setError({
            state: true,
            message: 'Failed to assign client license to user. Please refresh page and try again.',
          })
        }
      } else if (!switchTypes.ssp && user.id && userSspSlotId) {
        try {
          await unassignSlotFromUser({
            variables: {
              userId: user.id,
              productCategory: 'ssp',
            },
          })
        } catch (e) {
          setError({
            state: true,
            message: 'Failed to assign client license to user. Please refresh page and try again.',
          })
        }
      }
      if (switchTypes.focus && focusSlotId && user.id && !userFocusSlotId && !userUsedFocusSlot) {
        try {
          await assignSlotToUser({
            variables: {
              userId: user.id,
              productCategory: 'focus',
            },
          })
        } catch (e) {
          setError({
            state: true,
            message: 'Failed to assign client license to user. Please refresh page and try again.',
          })
        }
      } else if (!switchTypes.focus && user.id && userFocusSlotId) {
        try {
          await unassignSlotFromUser({
            variables: {
              userId: user.id,
              productCategory: 'focus',
            },
          })
        } catch (e) {
          setError({
            state: true,
            message: 'Failed to assign client license to user. Please refresh page and try again.',
          })
        }
      }

      // on complete reset and go back..
      await onClose()
      await refetchOne({ filter: { ids: [user.id] } }, { stopLoading: true, moveToTop: false })
      !error?.state &&
        enqueueSnackbar('Update Successful', {
          variant: 'success',
          action: CloseSnackbarAction,
        })
    } finally {
      setTimeout(() => {
        setLoading(false)
        setLoadingMessage()
      }, 3000)
    }
  }
  const handleSendInvitation = async () => {
    setLoading(true)
    setLoadingMessage('updating deliveries...')
    setError({ state: false, message: '' })
    try {
      // user that already had their emails filled in will not be able to see this
      let invitationId
      if (form.email && !isRemoteUser) {
        const result = await createInvitation({
          variables: {
            invitation: {
              partialUserId: user.id,
              toEmail: form.email,
              firstName: user.firstName,
              lastName: user.lastName,
              roles: 'client',
              productIds: Object.keys(productIds)
                .filter((key) => productIds[key])
                .join(','),
            },
          },
        })
        invitationId = get(result, 'data.createInvitation.id', undefined)
      }
      // 1. update slots first - this will break if updated second
      if (showNewSubscriptionPlan) {
        // if there are no more ssp / ils seats present, refresh slot
        const hasSsp = Object.keys(productIds).filter(
          (key) =>
            productIds[key] &&
            products.find((product) => product.category === 'ssp' && product.id === parseInt(key))
        )
        const hasFocus = Object.keys(productIds).filter(
          (key) =>
            productIds[key] &&
            products.find((product) => product.category === 'focus' && product.id === parseInt(key))
        )
        const userSspSlot = user.slots?.find(({ category }) => category === 'ssp')
        const userFocusSlot = user.slots?.find(({ category }) => category === 'focus')

        // if its not used or assigned, then its available
        if (
          hasSsp.length &&
          !['used', 'assigned'].includes(userSspSlot?.status) &&
          !userAssignedSspSlot &&
          sspSlotId
        ) {
          try {
            if (invitationId) {
              await assignSlotToInvitation({
                variables: {
                  invitationId,
                  productCategory: 'ssp',
                },
              })
            } else {
              await assignSlotToUser({
                variables: {
                  userId: user.id,
                  productCategory: 'ssp',
                },
              })
            }
          } catch (e) {
            setError({
              state: true,
              message:
                'Failed to assign client license to user. Please refresh page and try again.',
            })
          }
        } else if (!hasSsp.length && userSspSlot?.status === 'assigned') {
          try {
            await unassignSlotFromUser({
              variables: {
                userId: user.id,
                productCategory: 'ssp',
              },
            })
          } catch (e) {
            setError({
              state: true,
              message:
                'Failed to assign client license to user. Please refresh page and try again.',
            })
          }
        }

        if (
          hasFocus.length &&
          !['used', 'assigned'].includes(userFocusSlot?.status) &&
          !userAssignedFocusSlot &&
          focusSlotId
        ) {
          try {
            if (invitationId) {
              await assignSlotToInvitation({
                variables: {
                  invitationId,
                  productCategory: 'focus',
                },
              })
            } else {
              await assignSlotToUser({
                variables: {
                  userId: user.id,
                  productCategory: 'focus',
                },
              })
            }
          } catch (e) {
            setError({
              state: true,
              message:
                'Failed to assign client license to user. Please refresh page and try again.',
            })
          }
        } else if (!hasFocus.length && userFocusSlot?.status === 'assigned') {
          try {
            await unassignSlotFromUser({
              variables: {
                userId: user.id,
                productCategory: 'focus',
              },
            })
          } catch (e) {
            setError({
              state: true,
              message:
                'Failed to assign client license to user. Please refresh page and try again.',
            })
          }
        }
      }

      if (isRemoteUser) {
        // 2. seats do not exist, adding..
        const filteredProductIds = Object.keys(productIds).filter((key) => productIds[key])
        for (const productId of filteredProductIds) {
          const userSeat = user.seats.find(
            ({ productId: seatProductId }) => seatProductId === parseInt(productId)
          )
          if (userSeat?.status === 'suspended') {
            await updateSeat({
              variables: {
                seat: {
                  id: userSeat.id,
                  status: 'active',
                },
              },
            })
          } else if (!userSeat) {
            const licenseId = products.find(({ id }) => parseInt(productId) === id)?.licenseId
            await createSeat({
              variables: {
                seat: {
                  licenseId,
                  userId: user.id,
                },
              },
            })
          }
        }

        // 2. seats exist, and have been updated
        for (const userSeats of user.seats) {
          const { id: seatId, status, productId: seatProductId } = userSeats
          if (status === 'active' && !productIds[seatProductId]) {
            await updateSeat({
              variables: {
                seat: {
                  id: seatId,
                  status: 'suspended',
                },
              },
            })
          }
        }
      } else if (!isRemoteUser && !form.email) {
        throw Error('Please enter a valid email address.')
      }

      // refetch..
      const successMessage = form.email ? 'Invitation was successfully sent' : 'Update Successful'
      !error?.state &&
        enqueueSnackbar(successMessage, {
          variant: 'success',
          action: CloseSnackbarAction,
        })
      await refetchOne({ filter: { ids: [user.id] } }, { stopLoading: true, moveToTop: false })
      await onClose()
    } catch (errors) {
      const message = maskValidationMessage(errors, 'ManageSeats')
      setError({ state: true, message })
    } finally {
      setTimeout(() => {
        setLoading(false)
        setLoadingMessage()
      }, 500)
    }
  }

  const dialogContent = {
    1: (
      <Typography variant="body1">
        For this <span className="font-semibold">in-person client</span>, do you want to:
      </Typography>
    ),
    2: (
      <>
        <Typography variant="body2" className="text-link">
          With In-Person Delivery, the client will not have direct access to the MyUnyte platform
          and the Unyte-iLs app.
        </Typography>
        <TableContainer>
          <Table aria-label="slots table">
            <TableHead>
              <TableRow>
                <TableCell>Program</TableCell>
                <TableCell align="center">Enable For This Client</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {hasSspProducts && hasCompletedSspCertification && (
                <TableRow>
                  <TableCell>SSP</TableCell>
                  <Tooltip
                    title={
                      userUsedSspSlot && 'Cannot unassign "in use" license for in-person clients.'
                    }
                  >
                    <TableCell align="center">
                      {!!slotsInfo.ssp.length || userSspSlotId ? (
                        <Switch
                          checked={!!switchTypes.ssp}
                          onChange={handleSwitch('ssp')}
                          disabled={userUsedSspSlot}
                        />
                      ) : (
                        'No available licenses'
                      )}
                    </TableCell>
                  </Tooltip>
                </TableRow>
              )}
              {hasFocusProducts && hasCompletedFocusCertification && (
                <TableRow>
                  <TableCell>ILS</TableCell>
                  <Tooltip
                    title={
                      userUsedFocusSlot && 'Cannot unassign "in use" license for in-person clients.'
                    }
                  >
                    <TableCell align="center">
                      {!!slotsInfo.focus.length || userFocusSlotId ? (
                        <Switch
                          checked={!!switchTypes.focus}
                          onChange={handleSwitch('focus')}
                          disabled={userUsedFocusSlot}
                        />
                      ) : (
                        'No available licenses'
                      )}
                    </TableCell>
                  </Tooltip>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    ),
    3: (
      <>
        {!isRemoteUser && (
          <TextField
            value={form.email}
            fullWidth
            onChange={setFormValue('email')}
            placeholder="Email"
            error={error?.state || (form.email && !validateEmail(form.email))}
            helperText={
              (error?.state || (form.email && !validateEmail(form.email))) &&
              (error.message || 'Please enter a valid email address.')
            }
          />
        )}
        <TableContainer>
          <Table aria-label="slots table">
            <TableHead>
              <TableRow>
                <TableCell>Program</TableCell>
                <TableCell align="center">Enable For This Client</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!slotsInfo.ssp.length &&
                !userSspSlotId &&
                !userUsedSspSlot &&
                hasSspProducts &&
                showNewSubscriptionPlan &&
                hasCompletedSspCertification && (
                  <TableRow>
                    <TableCell>SSP</TableCell>
                    <TableCell align="center">No available licenses</TableCell>
                  </TableRow>
                )}
              {!slotsInfo.focus.length &&
                !userFocusSlotId &&
                !userUsedFocusSlot &&
                hasFocusProducts &&
                showNewSubscriptionPlan &&
                hasCompletedFocusCertification && (
                  <TableRow>
                    <TableCell>ILS</TableCell>
                    <TableCell align="center">No available licenses</TableCell>
                  </TableRow>
                )}
              {products
                .filter(
                  (product) =>
                    !showNewSubscriptionPlan ||
                    (product.category === 'ssp' &&
                      (slotsInfo.ssp.length || userAssignedSspSlot || userUsedSspSlot)) ||
                    (product.category === 'focus' &&
                      (slotsInfo.focus.length || userAssignedFocusSlot || userUsedFocusSlot))
                )
                .map(({ name, id }, index) => (
                  <TableRow key={`product${id}${index}`}>
                    <TableCell>{name}</TableCell>
                    <TableCell align="center">
                      <Switch checked={!!productIds[id]} onChange={handleProductIds(id)} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    ),
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      {loading && <LoadingModal text={loadingMessage} />}
      <DialogTitle pageState={pageState} email={user.email} />
      <DialogContent>{dialogContent[pageState]}</DialogContent>
      <DialogActions
        onClose={onClose}
        pageState={pageState}
        handleUpdateSlot={handleUpdateSlot}
        handleSendInvitation={handleSendInvitation}
        setPageState={setPageState}
        email={form.email}
        isRemoteUser={isRemoteUser}
      />
    </Dialog>
  )
}
