import React, { useState } from 'react'
import { Card, Grid, FormControlLabel, Checkbox, Stack } from '@mui/material'

import FilterListIcon from '@mui/icons-material/FilterList'

import { useSelector } from 'react-redux'
import MyUnyteDateRangePicker from 'components/MyUnyteDateRangePicker'

export default function Filter({
  dateFrom: _dateFrom,
  dateTo: _dateTo,
  setFilter = () => null,
  form,
  setForm,
}) {
  const [showFilters, setShowFilters] = useState(false)
  const products = useSelector((state) => state.seats.products)

  const handleDateRangeSubmit = async ({ startDate, endDate }) => {
    console.log('handleSubmit', { startDate, endDate })
    await setForm({ ...form, date: [startDate, endDate] })
    await setFilter({
      productIds: form.selectedProducts,
      startedAt: {
        gtEq: startDate?.toISOString(),
        ltEq: endDate?.toISOString(),
      },
    })
  }

  return (
    <Card className="px-5 py-2 mb-2">
      <Grid
        style={{ cursor: 'pointer' }}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <FilterListIcon onClick={() => setShowFilters(!showFilters)} />
        </Grid>
        <Grid item>
          <MyUnyteDateRangePicker
            from={form.date[0] || _dateFrom}
            to={form.date[1] || _dateTo}
            handleDateRangeSubmit={handleDateRangeSubmit}
          />
        </Grid>
      </Grid>

      {showFilters && (
        <Stack spacing={0} pt={2}>
          {products
            .filter((product) => product.category !== 'assessment')
            .map(({ id, name }) => (
              <FormControlLabel
                key={`${id}-${name}-checkbox`}
                control={
                  <Checkbox
                    color="primary"
                    size="small"
                    checked={(form.selectedProducts || []).includes(id)}
                    onChange={async () => {
                      const selectedProducts = form.selectedProducts.includes(id)
                        ? form.selectedProducts.filter((_id) => _id !== id)
                        : form.selectedProducts.concat(id)
                      await setForm({ ...form, selectedProducts })
                      await setFilter({
                        productIds: selectedProducts,
                        startedAt: {
                          gtEq: form.date[0] ? form.date[0].format() : null,
                          ltEq: form.date[1] ? form.date[1].format() : null,
                        },
                      })
                    }}
                    value={id}
                  />
                }
                label={name}
              />
            ))}
        </Stack>
      )}
    </Card>
  )
}
