import React, { useState } from 'react'
import { PickerModal } from 'mui-daterange-picker-plus/dist'
import { DateRange } from 'mui-daterange-picker-plus/dist'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { Stack } from '@mui/material'
import { useOnValueChange } from 'utils/hooks/useOnValueChange'

interface ClientFilterDateRangePickerProps {
  from: string | ''
  to: string | ''
  handleDateRangeSubmit: HandleDateRangeSubmit // Add this line
}
// Type for the handler function
type HandleDateRangeSubmit = (dateRange: DateRange) => Promise<void>

const formatDate = (dateRangeValues: DateRange) => {
  console.log({ dateRangeValues })
  if (!dateRangeValues.startDate || !dateRangeValues.endDate) {
    return ''
  }

  const startDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  }).format(dateRangeValues.startDate)
  const endDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  }).format(dateRangeValues.endDate)

  return `${startDate} - ${endDate}`
}

const ClientFilterDateRangePicker: React.FC<ClientFilterDateRangePickerProps> = ({
  from,
  to,
  handleDateRangeSubmit,
}) => {
  // state + handlers for the Modal
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.currentTarget as HTMLButtonElement // Cast if you need specifics from the button
    setAnchorEl(target)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  console.log({ from, to })

  // state + handlers for the DateRange Value
  const [dateRangeOnChange, setDateRangeOnChange] = useState<DateRange>({
    startDate: new Date(),
    endDate: new Date(),
  })

  useOnValueChange(JSON.stringify({ from, to }), () => {
    if (from && to) {
      setDateRangeOnChange({ startDate: new Date(from), endDate: new Date(to) })
    }
  })

  const handleSetDateRangeOnChange = (dateRange: DateRange) => {
    setDateRangeOnChange(dateRange)
  }
  const handleSetDateRangeOnSubmit = async (dateRange: DateRange, ...rest: any) => {
    console.log('inside', dateRange, rest)
    await handleDateRangeSubmit(dateRange)
    handleClose()
  }
  console.log(new Date(from))

  return (
    <>
      <Stack direction="row" onClick={handleClick} sx={{ cursor: 'pointer' }}>
        <CalendarTodayIcon className="mr-2" />
        {formatDate(dateRangeOnChange)}
      </Stack>
      <PickerModal
        onChange={(range: DateRange) => handleSetDateRangeOnChange(range)}
        customProps={{
          onSubmit: (range: DateRange) => handleSetDateRangeOnSubmit(range),
          onCloseCallback: handleClose,
        }}
        initialDateRange={{
          startDate: new Date(from),
          endDate: new Date(to),
        }}
        // minDate={new Date(from)}
        // maxDate={new Date(to)}
        modalProps={{
          open,
          anchorEl,
          onClose: handleClose,
          slotProps: {
            paper: {
              sx: {
                borderRadius: '16px',
                boxShadow: 'rgba(0, 0, 0, 0.21) 0px 0px 4px',
              },
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }}
      />
    </>
  )
}

export default ClientFilterDateRangePicker
