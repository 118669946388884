import { matchPath } from 'react-router'

// todo change to is enabled
export const BILLING_TABS = [
  {
    text: 'Plans',
    url: '/billing',
    isEnabled: ({ showBillingHistory }) => !!showBillingHistory,
    isActive: (pathname) => matchPath({ path: '/billing' }, pathname),
  },
  {
    text: 'History',
    url: '/billing/history',
    isEnabled: ({ showBillingHistory }) => !!showBillingHistory,
    isActive: (pathname) => matchPath({ path: '/billing/history' }, pathname),
  },
  {
    text: 'SSP Active Clients',
    url: '/billing/active-ssp-clients',
    isEnabled: ({ hasSspProducts, showNewSSPSubscriptionPlan }) =>
      showNewSSPSubscriptionPlan !== true && !!hasSspProducts,
    isActive: (pathname) => matchPath({ path: '/billing/active-ssp-clients' }, pathname),
  },
  {
    text: 'ILS Client Licenses',
    url: '/billing/active-focus-clients',
    isEnabled: ({
      hasFocusProducts,
      showNewFocusSubscriptionPlan,
      hasCompletedFocusCertification,
    }) =>
      showNewFocusSubscriptionPlan !== true && !!hasFocusProducts && hasCompletedFocusCertification,
    isActive: (pathname) => matchPath({ path: '/billing/active-focus-clients' }, pathname),
  },
  {
    text: 'Client Licenses',
    url: '/billing/client-licenses',
    isEnabled: ({
      showNewSSPSubscriptionPlan,
      showNewFocusSubscriptionPlan,
      hasCompletedSspCertification,
      hasCompletedFocusCertification,
      hasSspProducts,
      hasFocusProducts,
    }) =>
      (showNewSSPSubscriptionPlan === true || showNewFocusSubscriptionPlan === true) &&
      (hasSspProducts || hasFocusProducts) &&
      (hasCompletedSspCertification || hasCompletedFocusCertification),
    isActive: (pathname) => matchPath({ path: '/billing/client-licenses' }, pathname),
  },
  {
    text: 'How Client Licenses Work',
    url: '/billing/how-client-licenses-work',
    isEnabled: ({
      showNewSSPSubscriptionPlan,
      showNewFocusSubscriptionPlan,
      hasCompletedSspCertification,
      hasCompletedFocusCertification,
      hasSspProducts,
      hasFocusProducts,
    }) =>
      (showNewSSPSubscriptionPlan === true || showNewFocusSubscriptionPlan === true) &&
      (hasSspProducts || hasFocusProducts) &&
      (hasCompletedSspCertification || hasCompletedFocusCertification),
    isActive: (pathname) => matchPath({ path: '/billing/how-client-licenses-work' }, pathname),
  },
]
