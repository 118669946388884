import { gql } from '@apollo/client'

export const UPDATE_SLOTS = gql`
  mutation UpdateSlotsForNewUser($slot: UpdateSlotInput!) {
    updateSlotAssignment(slot: $slot) {
      id
    }
  }
`

export const CREATE_INVITATION = gql`
  mutation createInvitation($invitation: CreateInvitationInput!) {
    createInvitation(invitation: $invitation) {
      toEmail
      roles
      id
    }
  }
`

export const LICENSES_QUERY = gql`
  query($filter: FilterLicensesInput, $sort: [[String!]]) {
    getLicenses(filter: $filter, sort: $sort) {
      id
      productId
      isActive
      product {
        id
        name
        order
        category
      }
    }
  }
`

export const UPDATE_SEAT = gql`
  mutation updateSeat($seat: UpdateSeatInput!) {
    updateSeat(seat: $seat) {
      status
      userId
      id
    }
  }
`

export const CREATE_SEAT = gql`
  mutation createSeat($seat: CreateSeatInput!) {
    createSeat(seat: $seat) {
      id
      status
    }
  }
`

export const ASSIGN_SLOT_TO_USER = gql`
  mutation assignSlotToUser($userId: Int!, $productCategory: String!) {
    assignSlotToUser(userId: $userId, productCategory: $productCategory) {
      id
      category
      status
    }
  }
`

export const ASSIGN_SLOT_TO_INVITATION = gql`
  mutation assignSlotToInvitation($invitationId: Int!, $productCategory: String!) {
    assignSlotToInvitation(invitationId: $invitationId, productCategory: $productCategory) {
      id
      category
      status
    }
  }
`

export const UNASSIGN_SLOT_FROM_USER = gql`
  mutation unassignSlotFromUser($userId: Int!, $productCategory: String!) {
    unassignSlotFromUser(userId: $userId, productCategory: $productCategory) {
      id
      category
      status
    }
  }
`

export const UNASSIGN_SLOT_FROM_INVITATION = gql`
  mutation unassignSlotFromInvitation($invitationId: Int!, $productCategory: String!) {
    unassignSlotFromInvitation(invitationId: $invitationId, productCategory: $productCategory) {
      id
      category
      status
    }
  }
`
