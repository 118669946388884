import React from 'react'

import Navbar from 'components/navbar/Navbar'
import Header from 'components/header/Header'
import IntroVideo from 'components/IntroVideo'

import styles from './Main.module.scss'

export type TABS = Array<{
  text: string
  url: string
}>

type Props = {
  // only title is required..
  title: string
  tabs: TABS
  user?: any
  hideHeader?: boolean
  hideTabs?: boolean
  children?: React.ReactNode
}

const MainLayout: React.FC<Props> = ({ title, tabs, hideHeader, hideTabs, children }) => {
  return (
    <div className={styles.content}>
      <IntroVideo />
      <Navbar />
      <div data-test="main-content-page" className={`${styles.main} main`}>
        <div className={styles.header}>
          <Header title={title} tabs={tabs || []} hideHeader={hideHeader} hideTabs={hideTabs} />
        </div>

        {children}
      </div>
    </div>
  )
}

export default MainLayout
