/**
 * This is the refactored component that prompts provider before they suspend a client
 */
import React, { useState } from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, Stack } from 'components'
import ManageSeatsModal from '../modals/ManageSeats'
import { useSelector } from 'react-redux'
import { LoadingModal } from 'components/LoadingPage'

export default function ConfirmPopup({
  title,
  body,
  buttonText,
  open,
  onClose,
  onConfirm,
  user,
  onUpdate,
  loading,
  loadingMessage,
}) {
  const { showNewSubscriptionPlan } = useSelector((state) => state.ff)
  const [showModal, setModalState] = useState(false)
  const isRemoteUser = !!user.email

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="create-client-dialog-title"
        aria-describedby="create-client-dialog-description"
      >
        {loading && loadingMessage && <LoadingModal text={loadingMessage} />}
        <DialogTitle id="create-client-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="create-client-dialog-description">{body}</DialogContentText>
        </DialogContent>
        <Stack direction="row" justifyContent="space-between" alignItems="center" px={2.5} py={2.5}>
          {!!isRemoteUser && (
            <Button color="primary" onClick={() => setModalState(true)}>
              Manage {!showNewSubscriptionPlan && 'Remote'} Delivery
            </Button>
          )}

          <Stack sx={{ marginLeft: 'auto' }} spacing={1.5} direction="row">
            <Button onClick={onClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={onConfirm} color="primary">
              {buttonText}
            </Button>
          </Stack>
        </Stack>
      </Dialog>
      <ManageSeatsModal
        open={showModal}
        user={user}
        onClose={() => {
          setModalState(false)
        }}
        refetchSeats={onUpdate}
      />
    </>
  )
}
