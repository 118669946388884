import React from 'react'
import { Stack, Box } from '@mui/material'
import { useNavigate, useOutletContext } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
// new purchase service
import { useMutation } from '@apollo/client'
import { client, AUTH_CREATE_PURCHASE } from 'utils/apollo/purchaseService/client'

import { extendData } from '../../../store/modules/new-purchase'
import useCart from '../../../views/new-purchase/utils/useCart'
import { PRODUCTS } from 'utils/constants/prices'
import useGTM from 'utils/hooks/useGTM'
import Button from './PurchaseCardButton'
import Card from './PurchaseCardComponent'
import CardHeader from './PurchaseCardHeader'

const MARGIN_LEFT = '0.5rem'

export default function PurchaseCard({
  title,
  oneTimeCost,
  costPerMonth,
  description,
  trainingMessage,
  subDescription,
  link,
  skus,
  marginLeft = MARGIN_LEFT,
  testName,
}) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { addToCart } = useGTM()
  const { loading, setLoading } = useOutletContext()
  const { uuid: userUuid, professionalCredentials, roles } = useSelector((state) => state.auth.user)
  const { uuid: purchaseUuid } = useSelector((state) => state.newPurchase.data)
  const {
    hasOrgSspCertificationCompleted,
    hasOrgFocusCertificationCompleted,
    hasOrgSspCertificationEnrolled,
    hasOrgFocusCertificationEnrolled,
  } = useSelector((state) => state.organization)
  const focusTrainingStatus =
    (hasOrgFocusCertificationCompleted && 'completed') ||
    (hasOrgFocusCertificationEnrolled && 'enrolled')
  const sspTrainingStatus =
    (hasOrgSspCertificationCompleted && 'completed') ||
    (hasOrgSspCertificationEnrolled && 'enrolled')

  const { calculateChargesAndPromoDiscounts } = useCart()
  const isInternalPurchase = true

  const mapSkuToPromoDiscounts = (purchasedSkus = []) => {
    const discountItems = calculateChargesAndPromoDiscounts(purchasedSkus, isInternalPurchase)
      .bundleDiscountItems

    const skuToPromoDiscounts = {}
    for (const discountItem of discountItems) {
      skuToPromoDiscounts[discountItem.applyToSku] = {
        ...discountItem,
      }
    }

    return skuToPromoDiscounts
  }
  const [createPurchase] = useMutation(AUTH_CREATE_PURCHASE, {
    client,
    variables: { params: { data: {} } },
  })

  const onClick = async () => {
    setLoading(true)
    const getNewUuid = async () => {
      const internalPurchaseData = await createPurchase()
      return get(internalPurchaseData, 'data.auth_create_purchase.uuid', null)
    }

    await dispatch(
      extendData({
        uuid: purchaseUuid || (await getNewUuid()),
        selectedAddOns: {},
        clientLicenses: {},
        onboardingProducts: [...skus],
        isInternalPurchase,
      })
    )

    const skuToPromoDiscounts = mapSkuToPromoDiscounts(skus)

    const items = skus.map((sku) => ({
      item_id: PRODUCTS[sku].sku,
      item_name: PRODUCTS[sku].cartTitle,
      price: PRODUCTS[sku].price,
      item_category: PRODUCTS[sku].gtmCategory,
      item_brand: 'Unyte',
      quantity: 1,
      item_variant: PRODUCTS[sku].gtmVariant,
      immediateChargePrice: PRODUCTS[sku].immediateChargePrice,
      discount: skuToPromoDiscounts[sku]?.discountAmount || 0,
    }))
    const value = items.reduce((a, b) => a + (b.immediateChargePrice - b.discount), 0)
    await addToCart({
      value,
      items,
      uuid: userUuid,
      professionalCredentials,
      roles,
      focusTrainingStatus,
      sspTrainingStatus,
    })

    setLoading(false)
    await navigate(link)
  }

  return (
    <Card backgroundColor="#eef7f2" data-test={testName}>
      <Stack direction="column" sx={{ height: '100%' }}>
        <CardHeader sx={{ marginLeft: MARGIN_LEFT }} title={title} color="#3c7e7d" />
        <CardHeader
          title={`$${oneTimeCost || `${costPerMonth}/month`}`}
          color="#ce0093"
          subheader={description}
          sx={{ padding: '16px 16px 0 16px', marginLeft: MARGIN_LEFT }}
        />
        {trainingMessage && (
          <CardHeader
            sx={{
              marginLeft,
              minHeight: '20px',
            }}
            subheader={trainingMessage}
          />
        )}
        {subDescription && (
          <CardHeader
            sx={{
              marginLeft,
              minHeight: '100px', // to "fill out" 3-month plan (no subDescription)
            }}
            subheader={subDescription}
          />
        )}
        <Box sx={{ marginTop: 'auto', textAlign: 'center' }}>
          <Button onClick={onClick} disabled={loading}>
            Purchase Now
          </Button>
        </Box>
      </Stack>
    </Card>
  )
}
