/**
 * This is a page for updating existing assessment
 */
import React, { useState, useCallback, useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { Container, Typography, Grid, GlobalStyles } from '@mui/material'
import Alert from '@mui/material/Alert'
import * as Survey from 'survey-react'
import get from 'lodash/get'
import { useMutation } from 'utils/apollo'
import { useGetUserWithoutRefresh } from 'utils/hooks/useGetUser'

import 'survey-react/modern.css'
import Header from './components/Header'
import { useSnackbar } from 'notistack'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { ACTIONS, COMPLETED_CSS, INTAKE_CSS, SURVEY_CSS } from './utils/constants'
import { autoSaveAssessment } from '../../store/modules/assessments'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'utils/moment'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { ASSESSMENT_STATUS } from 'utils/constants/assessmentStatus'
import { useOnValueChange } from 'utils/hooks/useOnValueChange'
import { getAssessmentScore } from './utils/get-assessment-score'
import { UPDATE_SESSION } from './constants/gql'
import globalStyles from 'styles/GlobalStyles'

Survey.StylesManager.applyTheme('modern')

const HeaderTitle = ({ pageTitle, name }) => (
  <span>
    {pageTitle} | <span className="text-link font-semibold">{name}</span>
  </span>
)
export default function UpdateAssessment() {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // react component states and ref
  const componentRef = useRef(null)
  const [showPrintObjects, setShowPrintObjects] = useState(false)

  const { userId: _userId, sessionId: _sessionId } = useParams()
  const productEvents = useSelector((state) => state.assessments.productAssessments)
  const sessionId = parseInt(_sessionId)
  const userId = parseInt(_userId)

  const {
    SENT_TO_CLIENT,
    IN_PROGRESS,
    COMPLETED,
    isSent,
    isInProgress,
    isCompleted,
  } = ASSESSMENT_STATUS

  // search for assessment data
  const {
    setHideHeader,
    setTitle,
    refetch,
    setLoadingState,
    onLoadKPI,
    loadAssessmentsForUpsert,
  } = useOutletContext()

  const { selectedUser } = useGetUserWithoutRefresh(userId)

  const users = useSelector((state) => get(state, 'assessments.userData', []))
  const { fullName, email } = users.find(({ id }) => parseInt(userId) === id) ?? {}
  const name = email ? `${fullName} (${email})` : fullName

  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted: () => {
      onLoadKPI()
    },
  })
  const [assessmentData, setAssessmentData] = useState({})

  // we need to watch this state and rerender..
  const { survey, sessionData = {}, title, isIntakeForm, updatedAt, metadata } = assessmentData

  useOnValueChange(JSON.stringify({ userId, sessionId, productEvents, sessionData }), async () => {
    setHideHeader(true)
    if (userId && sessionId) {
      const response = await loadAssessmentsForUpsert({
        userIds: [parseInt(userId)],
        sessionId,
      })
      setAssessmentData({
        ...response,
        isInProgressState: isInProgress(response.sessionData.status),
        isCompletedState: isCompleted(response.sessionData.status),
      })

      if (isInProgress(response.sessionData.status)) {
        setTitle(<HeaderTitle pageTitle={'Continue Assessment'} name={name} />)
      } else {
        setTitle(<HeaderTitle pageTitle={'View Assessment'} name={name} />)
      }
    }
  })

  // new assessment Data retreives data on load

  // if a user clicks back, we never return with a filter
  const onClick = (statusPassIn) => async (event, options = {}) => {
    // if you've sent to client, status will be locked as sent to client until client completes assessments
    const status =
      isSent(sessionData.status) && isInProgress(statusPassIn) ? SENT_TO_CLIENT : statusPassIn
    await setLoadingState(true)
    const score =
      status === 'Completed' ? getAssessmentScore({ ...model.data }, metadata) : undefined
    const data = { answers: { ...model.data, score }, status, currentPageNo: model.currentPageNo }
    try {
      const session = {
        data: JSON.stringify(data).replace(/'/g, '`'),
        id: sessionId,
        metadata: { userAgent: navigator.userAgent },
      }
      await updateSession({ variables: { session } })
      await refetch()
      if (options.fromBack) {
        navigate(`/assessments`, { state: { hideGettingStarted: true } })
      } else {
        navigate(`/assessments/${userId}`, { state: { hideGettingStarted: true } })
      }
      enqueueSnackbar(ACTIONS[status].message, {
        variant: ACTIONS[status].variant,
        action: CloseSnackbarAction,
      })
    } catch (error) {
      if (!error.message.includes('Not Authorized')) {
        console.error(error)
      }
      enqueueSnackbar(ACTIONS['error'].message, {
        variant: ACTIONS['error'].variant,
        action: CloseSnackbarAction,
      })
    } finally {
      setLoadingState(false)
    }
  }

  // is first page does not work here because surveyJS thinks we're on first page on load
  const model = new Survey.Model(survey)
  model.progressBarType = '{0} of {1}'
  const lastVisitedPage = sessionData?.currentPageNumber || sessionData?.currentPageNo
  model.showNavigationButtons = !(isIntakeForm && lastVisitedPage === 0)
  model.showProgressBar = isInProgress(sessionData.status) ? 'bottom' : 'off'

  // surveyJS does not know the correct progress text unless we specifically specify it
  const handleProgressText = (sender, options) => {
    // eslint-disable-next-line
    options.text = options.text
  }
  const onAfterRenderQuestionInput = (sender, options) => {
    // show nav button after input
    model.showNavigationButtons = !(isIntakeForm && model.currentPageNo === 0)

    options.htmlElement.onclick = (click) => {
      const targetClassName = get(click, 'target.className', null)
      const clickValue = get(click, 'target.value', null)
      if (targetClassName === 'sv-visuallyhidden') {
        model.data = { ...model.data, [click.target.name]: parseInt(click.target.value) }
        if (!isIntakeForm) {
          if (model.isLastPage) {
            model.doComplete()
          } else {
            model.nextPage()
          }
        } else {
          if (clickValue === 'Start') {
            model.nextPage()
          }
        }
      }
    }
  }

  const autoSave = async (event) => {
    model.showNavigationButtons = model.isFirstPage && isIntakeForm ? 'none' : 'bottom'
    try {
      const surveyData = get(event, 'valuesHash', {})
      const score = getAssessmentScore({ ...surveyData }, metadata)
      const data = {
        answers: { ...surveyData, score },
        status: IN_PROGRESS,
        currentPageNo: model.currentPageNo,
      }
      const session = {
        data: JSON.stringify(data).replace(/'/g, '`'),
        id: sessionId,
        metadata: { userAgent: navigator.userAgent },
      }
      // do not auto update sent to client or completed
      if (isInProgress(sessionData.status)) {
        await dispatch(autoSaveAssessment({ session }))
      }
    } catch (error) {
      if (!error.message.includes('Not Authorized')) {
        console.error(error)
      }
      enqueueSnackbar('Unable to save assessment, please refresh page and try again', {
        variant: 'error',
      })
    }
  }

  const handleOnBeforeGetContent = () => {
    setShowPrintObjects(true)
  }

  const reactToPrintContent = useCallback(() => {
    return componentRef.current
    // eslint-disable-next-line
  }, [componentRef.current])

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    onBeforeGetContent: handleOnBeforeGetContent,
  })

  const setStateThenPrint = async () => {
    await setShowPrintObjects(true)
    await handlePrint()
    await setShowPrintObjects(false)
  }

  return (
    <Container className="py-5">
      {isSent(sessionData.status) && (
        <Alert severity="info">
          Read only - this assessment has been sent to your client and can no longer be edited by
          you
        </Alert>
      )}
      <Header
        onSend={onClick(SENT_TO_CLIENT)}
        onSave={onClick(IN_PROGRESS)}
        title={title}
        data={sessionData}
        handlePrint={setStateThenPrint}
        isIntakeForm={isIntakeForm}
      />

      <div ref={componentRef} className={showPrintObjects ? 'pt-3 px-8' : ''}>
        {showPrintObjects && (
          <>
            {isIntakeForm && <GlobalStyles styles={globalStyles} />}
            <Grid container alignItems="stretch" justifyContent="center" spacing={3}>
              <Grid item>
                <img
                  src="/images/unyte-logo.png"
                  alt="Unyte"
                  style={{ width: '50px', marginTop: '25px' }}
                />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h5">{title}</Typography>
                <Grid item>
                  <Typography variant="body1">{`${selectedUser.fullName} (${selectedUser.email})`}</Typography>
                  <Typography variant="body1">
                    {updatedAt
                      ? moment
                          .withoutTimezone(updatedAt)
                          .utcOffset(0)
                          .format('MM/DD/YYYY')
                      : ''}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        <Survey.Survey
          model={model}
          mode={!isInProgress(sessionData.status) ? 'display' : 'default'}
          onComplete={onClick(COMPLETED)}
          data={sessionData.answers}
          questionsOnPageMode={isInProgress(sessionData.status) ? 'questionPerPage' : 'singlePage'}
          currentPageNo={lastVisitedPage}
          css={
            (!isIntakeForm && isCompleted(sessionData.status) && COMPLETED_CSS) ||
            (isIntakeForm && !isCompleted(sessionData.status) && INTAKE_CSS) ||
            (!isIntakeForm && SURVEY_CSS)
          }
          onAfterRenderQuestionInput={onAfterRenderQuestionInput}
          onCurrentPageChanged={autoSave}
          onProgressText={handleProgressText}
        />
      </div>
      <div style={{ height: '100px' }} />
    </Container>
  )
}
