import get from 'lodash/get'
import { isEmpty } from 'utils/isEmpty'
import { parseMetadata } from './parse-metadata'

export const getAssessmentScore = (answers, metadata = {}) => {
  // parse metadata
  const { subScales, isCATS, isAce, isBBCSS, catsScoring, maximumScore } = parseMetadata(metadata)

  // calculate score here
  let sum = !isCATS
    ? Object.values(answers)
        .map(parseFloat)
        .reduce((sum, val) => sum + (!isNaN(val) ? val : 0), 0)
    : Object.entries(answers).reduce((acc, curr) => {
        if (catsScoring.includes(curr[0])) {
          return acc + curr[1]
        }
        return acc
      }, 0)
  sum = sum % 10000
  const newScale = {}
  if (subScales && isBBCSS) {
    Object.entries(subScales).forEach(([scale, questions]) => {
      const nonZeroQuestions = questions.filter((question) => get(answers, `${question}`, 0))
      const mean =
        nonZeroQuestions.reduce((sum, question) => sum + get(answers, `${question}`, 0), 0.0) /
        nonZeroQuestions.length

      // customization of ACE assessment questions to exclude from the rating system
      newScale[scale] = mean
    })
  } else if (subScales && !isBBCSS) {
    Object.entries(subScales).forEach(([scale, questions]) => {
      /**
       * 1. filter out 0, because not applicable should not be counted
       * 2. calculate mean score
       * 3. transform score (eg Ace)
       */

      const sum = questions.reduce((sum, question) => sum + get(answers, `${question}`, 0), 0.0)
      if (isAce) {
        newScale[scale] = sum % 10000
      }
      newScale[scale] = sum
    })
  }

  return {
    sum,
    maximumScore,
    subScaleScores: !isEmpty(newScale) ? { ...newScale } : {},
  }
}
