import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  TextField,
} from 'components'
import DialogTitleWithClose from 'components/DialogTitleWithClose'

export default function LeaveCommentDialog({
  isOpen,
  handleClose,
  onCommentInput,
  onSubmit,
  buttonText = 'Submit',
}) {
  return (
    <Dialog fullWidth maxWidth={'sm'} open={isOpen} onClose={handleClose}>
      <DialogTitleWithClose id="comment-dialog-title" onClose={handleClose}>
        <Typography variant="h5" color="primary">
          Please tell us more about why you felt that way
        </Typography>
      </DialogTitleWithClose>
      <DialogContent>
        <DialogContentText>
          <TextField
            inputProps={{ maxLength: 1000 }}
            label="Leave your comment here"
            multiline
            onChange={onCommentInput}
            rows={5}
            variant="outlined"
          />
        </DialogContentText>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={onSubmit}>
            {buttonText}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
