import React from 'react'
import moment from 'utils/moment'
import { Active, Stack, Typography } from 'components'
import formatMoney from 'views/new-purchase/utils/formatMoney'
import LINKS from 'utils/constants/links'
import DATE_FORMAT from 'utils/constants/formats'
import { ALL_SSP_SUBSCRIPTION_SKUS } from 'utils/constants/prices'

const skuToPlanSlotsAmount = {
  sspYearToYear_Yearly: 25,
  sspMonthToMonth_Yearly: 25,
  sspMonthToMonth_3Monthly: 6,
  focusYearToYear_Yearly: 25,
  focusMonthToMonth_Yearly: 25,
}

const ChangeOrCancel = () => (
  <a
    href={LINKS.billing.changeOrCancel}
    className="underline text-link font-normal"
    target="_blank"
    rel="noopener noreferrer"
  >
    change or cancel
  </a>
)

export default function PlanInformation({
  subscription,
  showNewSSPSubscriptionPlan,
  showNewFocusSubscriptionPlan,
}) {
  const { currentProductStartedDate, currentPlanRenewalDate, amount, interval, sku } = subscription
  const SSP_SUBSCRIPTION = ALL_SSP_SUBSCRIPTION_SKUS.includes(sku)

  const activeClients = () => {
    if (SSP_SUBSCRIPTION) {
      if (showNewSSPSubscriptionPlan === true) {
        return (
          <>
            <Typography variant="body2">
              Your base plan includes {skuToPlanSlotsAmount[subscription.sku]} client licenses per
              subscription term.
            </Typography>
          </>
        )
      }
      return (
        <>
          <Typography variant="body2">
            Your plan includes 5 <Active strong /> clients.
          </Typography>
          <Typography variant="body2">
            Additional <Active strong /> clients are billed at $10 per <Active strong /> client.
          </Typography>
        </>
      )
    } else {
      if (showNewFocusSubscriptionPlan === true) {
        return (
          <>
            <Typography variant="body2">
              Your base plan includes {skuToPlanSlotsAmount[subscription.sku]} client licenses per
              subscription term.
            </Typography>
          </>
        )
      }
      return (
        <Typography variant="body2">
          Your plan includes 50 ILS program <Active product="focus" strong /> per subscription term.
        </Typography>
      )
    }
  }

  return (
    <Stack className="pb-6" direction="column">
      <Typography variant="h5">
        {SSP_SUBSCRIPTION
          ? 'Safe and Sound Protocol (SSP) Subscription'
          : 'Integrated Listening System (ILS) Subscription'}
      </Typography>
      <Typography variant="body2">
        Your plan started {moment(currentProductStartedDate, 'MM/DD/YYYY').format(DATE_FORMAT.date)}
        .
      </Typography>
      {currentPlanRenewalDate && (
        <Typography variant="body2">
          Your plan automatically renews on{' '}
          {moment(currentPlanRenewalDate, 'MM/DD/YYYY').format(DATE_FORMAT.date)}.
        </Typography>
      )}
      <Typography variant="body2">
        You are billed at {formatMoney(amount / 100)}/{interval} + tax.
      </Typography>
      {activeClients()}
      <Typography variant="body2">
        How do I <ChangeOrCancel /> my subscription?
      </Typography>
    </Stack>
  )
}
