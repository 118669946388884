import React from 'react'
import MainLayout from 'components/containers/main/Main'
import { Typography, Grid, Link, Button } from 'components'
import { useSelector } from 'react-redux'
import { gql, useQuery } from 'utils/apollo'
import moment from 'utils/moment'
import usePayments from './hooks/usePayments'
import DATE_FORMAT from 'utils/constants/formats'
import get from 'lodash/get'
import AddPaymentMethod from 'components/stripe/AddPaymentMethod'
import CardOption from 'components/stripe/CardOption'
import Loading from 'components/Loading'
import EMAILS from 'utils/constants/emails'
import formatMoney from 'views/new-purchase/utils/formatMoney'
import PlanInformation from './PlanInformation'
import { BILLING_TABS } from './tabs'
import { useGetUserWithoutRefresh } from 'utils/hooks/useGetUser'

const GET_CARDS = gql`
  query GetStripePaymentMethods {
    getStripePaymentMethods
  }
`

const SUBSCRIPTION_MESSAGES = {
  sspPayPal:
    'Your SSP subscription was paid with PayPal and is therefore unavailable for viewing here.',
  sspAustralia:
    'Your SSP subscription is currently managed by iLs Australia and is therefore unavailable for viewing here.',
  focusAustralia:
    'Your ILS subscription is currently managed by iLs Australia and is therefore unavailable for viewing here.',
  sspComplimentary: 'You currently have a complimentary SSP subscription.',
  sspUnyteEmployee: 'You currently have a complimentary SSP subscription as a Unyte employee.',
  focusComplimentary: 'You currently have a complimentary ILS subscription.',
  focusUnyteEmployee: 'You currently have a complimentary ILS subscription as a Unyte employee.',
  default: 'This information is not available at this time.',
}

export default function Settings() {
  const { data, loadingGetCards, refetch } = useQuery(GET_CARDS)
  const { activeSubscriptions, loading: paymentLoading, unpaidInvoices } = usePayments()

  // show billing if the user has either certification completed & NOT sspTemporary

  const {
    hasSspProducts,
    hasFocusProducts,
    showBillingHistory,
    showNewSSPSubscriptionPlan,
    showNewFocusSubscriptionPlan,
  } = useSelector((state) => state.ff)
  const {
    selectedUser: { hasCompletedFocusCertification, hasCompletedSspCertification },
  } = useGetUserWithoutRefresh()
  const { products } = useSelector((state) => state.organization)

  const loading = paymentLoading || loadingGetCards
  const { defaultStripePaymentMethodId, stripePaymentMethods: unsortedPaymentMethods } = get(
    data,
    'getStripePaymentMethods',
    { stripePaymentMethods: [] }
  )

  // put default to the front - there was a case where default card did not exist in payment methods
  const defaultCard = unsortedPaymentMethods.find(({ id }) => id === defaultStripePaymentMethodId)
  const rest = unsortedPaymentMethods.filter(({ id }) => id !== defaultStripePaymentMethodId)
  const stripePaymentMethods = defaultCard ? [defaultCard, ...rest] : [...rest]

  const tabs = BILLING_TABS.filter(({ isEnabled }) =>
    isEnabled({
      showBillingHistory,
      hasSspProducts,
      hasFocusProducts,
      showNewSSPSubscriptionPlan,
      showNewFocusSubscriptionPlan,
      hasCompletedFocusCertification,
      hasCompletedSspCertification,
    })
  )

  const currentPlansMessages = Object.keys(products).map((key) => SUBSCRIPTION_MESSAGES[key])

  if (!(currentPlansMessages.length && currentPlansMessages.every((message) => Boolean(message)))) {
    currentPlansMessages.push(SUBSCRIPTION_MESSAGES.default)
  }

  // show message when a user don't have any active subscriptions
  //   OR has does not have ALL active skus in whitelist
  //   - users with legacy subscription should not see unpaid invoices
  return (
    <MainLayout title="My Billing" tabs={tabs}>
      <Grid className="px-5">
        {!loading && showBillingHistory && !!unpaidInvoices.length && (
          <Grid className="py-5">
            <Typography variant="h5" className="py-2">
              Unpaid Invoices
            </Typography>
            {unpaidInvoices.map(
              ({ created, hostedInvoiceUrl, amountDue, status, paid }) =>
                status === 'open' &&
                paid === false && (
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={3}
                  >
                    <Grid item>
                      <Typography variant="body2" className="text-gray px-2">
                        Invoice due {moment(created).format(DATE_FORMAT.date)} of amount{' '}
                        {formatMoney(amountDue / 100)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <a target="_blank" rel="noopener noreferrer" href={hostedInvoiceUrl}>
                        <Button color="primary" variant="contained">
                          View and Pay
                        </Button>
                      </a>
                    </Grid>
                  </Grid>
                )
            )}
          </Grid>
        )}

        <Typography variant="h5" className="pb-3 pt-4">
          Current Plan{Object.keys(products).length > 1 && 's'}
        </Typography>
        {loading && <Loading rows={2} width={['450px']} height={['30px', '90px']} />}
        {!loading &&
          (!showBillingHistory || !activeSubscriptions.length) &&
          !unpaidInvoices.length && (
            <Typography variant="body2" className="text-gray px-2">
              {currentPlansMessages.map((message) => (
                <>
                  {message}
                  <br />
                </>
              ))}
              Please contact{' '}
              <a href={`mailto:${EMAILS.supportEmail}`} className="text-link">
                {EMAILS.supportEmail}
              </a>{' '}
              if you have any questions.
            </Typography>
          )}

        {!loading && showBillingHistory && !!unpaidInvoices.length && (
          <Typography variant="body2" className="text-gray px-2 py-5">
            You have unpaid invoices, please go to{' '}
            <Link className="text-link" to="/billing/history">
              billing history
            </Link>{' '}
            for more information.
            <br />
            Please contact{' '}
            <a href={`mailto:${EMAILS.supportEmail}`} className="text-link">
              {EMAILS.supportEmail}
            </a>{' '}
            if you have any questions.
          </Typography>
        )}

        {!loading &&
          showBillingHistory &&
          activeSubscriptions.length >= 0 &&
          activeSubscriptions.map((item) => (
            <PlanInformation
              subscription={item}
              showNewSSPSubscriptionPlan={showNewSSPSubscriptionPlan}
              showNewFocusSubscriptionPlan={showNewFocusSubscriptionPlan}
            />
          ))}
      </Grid>
      <Grid className="p-5">
        <Typography variant="h5" className="pb-2">
          Payment Method
        </Typography>
        <AddPaymentMethod refetch={refetch} isCardsEmpty={stripePaymentMethods.length === 0} />
        {loading && (
          <Loading
            rows={3}
            columns={3}
            height={['45px', '45px', '45px']}
            width={['65px', '300px', '65px']}
          />
        )}
        {!loading &&
          (stripePaymentMethods.length === 0 ? (
            <Typography variant="body1" className="py-5 px-2">
              No Payment Methods.
            </Typography>
          ) : (
            stripePaymentMethods.map((paymentMethod) => (
              <CardOption
                {...paymentMethod}
                default={paymentMethod.id === defaultStripePaymentMethodId}
                disableEdit={paymentMethod.id === defaultStripePaymentMethodId}
                refetch={refetch}
              />
            ))
          ))}
      </Grid>
    </MainLayout>
  )
}
