/**
 * parse metadata for assessments
 */
import get from 'lodash/get'

export const parseMetadata = (metadata) => {
  // gets
  const assessmentType = get(metadata, 'assessmentType', '')
  const catsScoring = get(metadata, 'catsScoring', [])
  const isAIS = get(metadata, 'isAIS', false)
  const isAce = get(metadata, 'isAce', false)
  const isBBCSS = get(metadata, 'shortTitle', '') === 'BBCSS'
  const isCATS = get(metadata, 'isCATS', false)
  const isIntakeForm = get(metadata, 'isIntakeForm', false)
  const maximumScore = get(metadata, 'maximumScore', 0)
  const meanMax = get(metadata, 'maximumRateValue', 0)
  const subScaleOrder = get(metadata, 'subScaleOrder', [])
  const subScales = get(metadata, 'subScales', null)
  const survey = get(metadata, 'survey', {})
  const variant = get(metadata, 'variant', null)
  const numberOfItems = get(metadata, 'numberOfItems', 0)

  // transform
  const assessmentName = variant ? `${assessmentType} - ${variant}` : assessmentType

  // output
  return {
    assessmentName,
    catsScoring,
    isAce,
    isBBCSS,
    isCATS,
    isAIS,
    isIntakeForm,
    meanMax,
    maximumScore,
    subScaleOrder,
    subScales,
    survey,
    variant,
    numberOfItems,
  }
}
