import React, { useState } from 'react'
import {
  Typography,
  CardMedia,
  CardActions,
  Card,
  Button,
  Menu,
  MenuItem,
  PageLoader,
  Box,
} from 'components'
import EditIcon from '@mui/icons-material/Edit'
import { gql, useMutation } from 'utils/apollo'

const UPDATE_BILLING = gql`
  mutation UpdateBilling($stripe: String) {
    updateBilling(stripeDefaultPaymentMethodId: $stripe)
  }
`
const DELETE_BILLING = gql`
  mutation DeleteBilling($stripe: String) {
    updateBilling(stripeDetachPaymentMethodId: $stripe)
  }
`

const ITEM_HEIGHT = 48

export default function SavedCard({
  id,
  card,
  default: _default,
  refetch = () => null,
  disableEdit,
  isMenuItem,
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [loading, setLoading] = useState(false)
  const open = Boolean(anchorEl)
  const [updateBilling] = useMutation(UPDATE_BILLING)
  const [deleteBilling] = useMutation(DELETE_BILLING)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const makeDefault = async () => {
    setLoading(true)
    await updateBilling({
      variables: {
        stripe: id,
      },
    })
    await refetch()
    setLoading(false)
    closeMenu()
  }

  const deleteCard = async () => {
    setLoading(true)
    await deleteBilling({
      variables: {
        stripe: id,
      },
    })
    await refetch()
    setLoading(false)
    closeMenu()
  }

  const { brand, last4, exp_month, exp_year } = card
  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: isMenuItem ? 'transparent' : '#FFF',
      }}
    >
      <CardActions disableSpacing>
        <CardMedia
          sx={{
            height: '3rem',
            width: '4rem',
          }}
          title="cc-logo"
          image={`/images/creditcard/${brand.toLowerCase().replace(/ /g, '-')}.png`}
        />
        <Typography className="px-5">•••• •••• •••• {last4}</Typography>
        <Typography
          sx={{
            width: '10rem',
            px: 5,
          }}
        >
          Expiry: {exp_month}/{exp_year}
        </Typography>
        <Box
          sx={{
            width: '4rem',
          }}
        >
          {_default && (
            <Button variant="contained" disabled size="small">
              Default
            </Button>
          )}
          {!disableEdit && (
            <Button aria-label="edit" onClick={handleClick} size="small">
              <EditIcon />
            </Button>
          )}
        </Box>
        {!disableEdit && (
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={closeMenu}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '20ch',
              },
            }}
          >
            <MenuItem key="make-default" onClick={makeDefault} disabled={loading}>
              Make Default
            </MenuItem>
            <MenuItem
              key="delete"
              onClick={deleteCard}
              sx={{
                color: '#c53030',
              }}
              disabled={loading}
            >
              Delete
            </MenuItem>
          </Menu>
        )}
        {loading && <PageLoader />}
      </CardActions>
    </Card>
  )
}
