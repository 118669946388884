import React, { useState } from 'react'
import { Card, Grid, Typography, Box, Stack, Button } from 'components'
import PlayCircleOutline from '@mui/icons-material/PlayCircleFilledWhiteOutlined'
import NewUpdates from 'components/NewUpdates'
import { NEW_UPDATE_VIDEO } from 'views/assessments/utils/constants'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { useNavigate } from 'react-router'

function GettingStartedWithAssessments({ gridProps }) {
  const navigate = useNavigate()
  const [showVideo, setShowVideo] = useState()
  const authUserId = useSelector((state) => get(state, 'auth.user.id', ''))

  return (
    <>
      {showVideo && (
        <NewUpdates
          title="Introduction to Assessments"
          src={NEW_UPDATE_VIDEO}
          type={`assessment${authUserId}`}
          override={showVideo}
          overrideClose={() => setShowVideo(false)}
        />
      )}
      <Grid item {...gridProps} key="getting-started-with-assessments-card">
        <Card
          sx={{ height: '100%' }}
          data-test="dashboard-card-getting-started-with-assessments"
          elevation={0}
        >
          <Grid
            container
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: '#edf2f7',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                backgroundColor: '#edf2f7',
              }}
              p={2}
            >
              <Stack alignItems="center">
                <Typography className="pt-3" variant="h5">
                  Get Started with Unyte Assessments
                </Typography>
                <Typography className="pt-10" variant="body2">
                  Welcome! We're here to help you get things rolling.
                </Typography>
                <Button
                  color="primary"
                  sx={{
                    alignContent: 'center',
                  }}
                  startIcon={<PlayCircleOutline />}
                  onClick={() => {
                    setShowVideo(true)
                  }}
                >
                  <Typography variant="caption">Watch Our Getting Started Video</Typography>
                </Button>
                <Typography className="pt-2 pb-10" variant="body2" textAlign="center">
                  <span style={{ fontWeight: 500 }}>Why use Unyte Assessments?</span> Unyte
                  Assessments will help you better understand your clients, inform program planning,
                  and measure changes over time.
                </Typography>
                <Button
                  variant="contained"
                  sx={{ width: '200px' }}
                  onClick={() => navigate('/assessments')}
                >
                  Get Started Now
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Card>
      </Grid>
    </>
  )
}

export default GettingStartedWithAssessments
