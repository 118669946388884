import React, { useState } from 'react'
import get from 'lodash/get'
import { useSnackbar } from 'notistack'
import { Box, Stack, Typography, Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { extendUser } from 'store/modules/auth'
import { gql, useMutation } from 'utils/apollo'
import CloseSnackbarAction from 'components/CloseSnackbarAction'

const UPDATE_EMAIL_SUBSCRIPTION_STATUS = gql`
  mutation ChangeEmailListSubscription($isSubscribed: Boolean!, $emailSubscriptionType: String!) {
    changeEmailListSubscription(
      isSubscribed: $isSubscribed
      emailSubscriptionType: $emailSubscriptionType
    )
  }
`

const SUBSCRIPTION_STATUS_LABELS = {
  true: {
    successSnackbarText: 'You have been unsubscribed from Unyte Success Digest',
    currentStatusText: 'You are currently subscribed to Unyte Success Digest',
    buttonVariant: 'text',
    buttonText: 'Unsubscribe',
  },
  false: {
    successSnackbarText: 'You have been subscribed to Unyte Success Digest',
    currentStatusText: 'You are not currently subscribed to Unyte Success Digest',
    buttonVariant: 'contained',
    buttonText: 'Subscribe',
  },
}

export default function SuccessDigestEmailNewsletter() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [updateEmailSubscriptionStatus] = useMutation(UPDATE_EMAIL_SUBSCRIPTION_STATUS)
  const user = useSelector((state) => get(state, 'auth.user'))

  const isSubscribedToSuccessDigest = get(
    user,
    'productPreferences.emailNewsletter.monthlySuccessDigest.isActive',
    false
  )
  const {
    successSnackbarText,
    currentStatusText,
    buttonVariant,
    buttonText,
  } = SUBSCRIPTION_STATUS_LABELS[isSubscribedToSuccessDigest]

  const onSubmit = async () => {
    setLoading(true)
    try {
      const res = await updateEmailSubscriptionStatus({
        variables: {
          isSubscribed: !isSubscribedToSuccessDigest,
          emailSubscriptionType: 'monthlySuccessDigest',
        },
      })

      if (res.errors) {
        const errorMessage = get(res, 'errors[0].message', 'Server error.. Please try again later.')
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          action: CloseSnackbarAction,
        })
        return
      }

      dispatch(
        extendUser({
          ...user,
          productPreferences: {
            ...user.productPreferences,
            emailNewsletter: res.data.changeEmailListSubscription,
          },
        })
      )

      enqueueSnackbar(successSnackbarText, { variant: 'success', action: CloseSnackbarAction })
    } catch (e) {
      const errorMessage = e.message || 'Server error.. Please try again later.'
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        action: CloseSnackbarAction,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box sx={{ maxWidth: '38rem', border: '1px solid lightgrey', borderRadius: '0.4rem' }}>
      <Stack direction="column" padding="1rem">
        <Typography variant="h5">Provider Newsletter</Typography>
        <img
          width="200"
          alt="Unyte-Success-Digest"
          src="/images/dashboard/Unyte_Success_Digest.png"
        />
        <Typography>
          Unyte Success Digest is a monthly, provider-only newsletter designed to support your
          delivery of Unyte's therapeutic programs, keeping you up to date with the latest news,
          resources and helpful information you need to know.
        </Typography>
        <Stack direction="row" alignItems="center" marginTop={2} spacing={2}>
          <Typography>{currentStatusText}</Typography>
          <Button variant={buttonVariant} onClick={onSubmit} disabled={loading}>
            {buttonText}
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}
