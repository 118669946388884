import { gql } from '@apollo/client'

export const GET_LATEST_PROVIDER_PROFILE_INFO = gql`
  query GET_LATEST_PROVIDER_PROFILE_INFO {
    getMe {
      providerProfileInfo
    }
  }
`

export const UPDATE_PROVIDER_PROFILE_INFO = gql`
  mutation UPDATE_PROVIDER_PROFILE_INFO($user: UpdateUserInput!) {
    updateUser(user: $user) {
      providerProfileInfo
    }
  }
`

export const GET_CONNECTEE_MATCHES = gql`
  query GET_CONNECTEE_MATCHES(
    $filter: FilterConnecteeMatch
    $sort: [[String]]
    $offset: Int
    $limit: Int
  ) {
    getConnecteeMatches(filter: $filter, sort: $sort, offset: $offset, limit: $limit) {
      connecteeSubmission {
        additionalInfo
        city
        country
        email
        firstName
        howToContact
        id
        lastName
        phone
        providerAgeGroups
        providerCertifications
        providerClinicalSpecialties
        providerDeliveryOptions
        providerGender
        providerInsurance
        providerModalities
        providerLanguage
        state
        status
        uuid
      }
      createdAt
      expiredAt
      id
      providerId
      rejectReason
      otherRejectReason
      rejectedAt
      status
      updatedAt
    }
  }
`

export const GET_ACTIVE_CONNECTEE_MATCH = gql`
  query GET_ACTIVE_CONNECTEE_MATCH(
    $filter: FilterConnecteeMatch
    $sort: [[String]]
    $offset: Int
    $limit: Int
  ) {
    getConnecteeMatches(filter: $filter, sort: $sort, offset: $offset, limit: $limit) {
      connecteeSubmission {
        additionalInfo
        city
        country
        email
        firstName
        howToContact
        id
        lastName
        phone
        providerAgeGroups
        providerCertifications
        providerClinicalSpecialties
        providerDeliveryOptions
        providerGender
        providerInsurance
        providerModalities
        providerLanguage
        state
        status
        uuid
      }
      createdAt
      expiredAt
      id
      providerId
      rejectReason
      otherRejectReason
      rejectedAt
      status
      updatedAt
    }
  }
`

export const GET_CONNECTEE_MATCH_HISTORY = gql`
  query GET_CONNECTEE_MATCH_HISTORY(
    $filter: FilterConnecteeMatch
    $sort: [[String]]
    $offset: Int
    $limit: Int
  ) {
    getConnecteeMatches(filter: $filter, sort: $sort, offset: $offset, limit: $limit) {
      connecteeSubmission {
        additionalInfo
        city
        country
        email
        firstName
        howToContact
        id
        lastName
        phone
        providerAgeGroups
        providerCertifications
        providerClinicalSpecialties
        providerDeliveryOptions
        providerGender
        providerInsurance
        providerModalities
        providerLanguage
        state
        status
        uuid
      }
      createdAt
      expiredAt
      id
      providerId
      rejectReason
      otherRejectReason
      rejectedAt
      status
      updatedAt
    }
  }
`

export const UPDATE_CONNECTEE_MATCH = gql`
  mutation UPDATE_CONNECTEE_MATCH($connecteeMatch: ConnecteeMatchInput!) {
    updateConnecteeMatch(connecteeMatch: $connecteeMatch) {
      connecteeSubmission {
        additionalInfo
        city
        country
        email
        firstName
        howToContact
        id
        lastName
        phone
        providerAgeGroups
        providerCertifications
        providerClinicalSpecialties
        providerDeliveryOptions
        providerGender
        providerInsurance
        providerModalities
        providerLanguage
        state
        status
        uuid
      }
      createdAt
      expiredAt
      id
      providerId
      rejectReason
      rejectedAt
      status
      updatedAt
    }
  }
`
