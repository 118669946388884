import React from 'react'
import TableSortLabel from '@mui/material/TableSortLabel'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import { Tooltip } from 'components'
import HelpIcon from '@mui/icons-material/Help'
import get from 'lodash/get'

export default ({ sort = [], sortKey, title, selectedItem, tooltipMessage, onChange }) => {
  const SORT_ORDER = {
    'DESC NULLS LAST': 'desc',
    'ASC NULLS LAST': 'asc',
  }
  const key = sortKey || title
  const [sortData] = sort
  const alreadySortedByCurrentField = sortData && sortData.some((item) => item === key)
  const direction = SORT_ORDER[get(sortData, '[1]', '')]

  const handleClick = () => {
    if (!onChange) {
      return
    }

    if (alreadySortedByCurrentField && sortData.length === 2) {
      const [column, direction] = sortData
      const newDirection = direction.includes('DESC') ? 'ASC NULLS LAST' : 'DESC NULLS LAST'
      onChange({ sort: [[column, newDirection]] })
    } else {
      onChange({ sort: [[key, 'DESC NULLS LAST']] })
    }
  }

  return (
    <TableSortLabel
      direction={direction}
      active={alreadySortedByCurrentField}
      onClick={handleClick}
      hideSortIcon={true}
      fontSize="small"
    >
      {tooltipMessage && (
        <Tooltip title={tooltipMessage} placement="top">
          <HelpIcon color="disabled" fontSize="small" />
        </Tooltip>
      )}
      {title}
      {!alreadySortedByCurrentField && <UnfoldMoreIcon fontSize="small" />}
      {selectedItem ? selectedItem : null}
    </TableSortLabel>
  )
}
