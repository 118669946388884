/**
 * To Do: merge this file with Products
 *
 */
import React, { useEffect, useState } from 'react'
import {
  Grid,
  Card,
  Button,
  CardHeader,
  Divider,
  Hidden,
  FormHelperText,
  Typography,
} from 'components'
import MainLayout from 'components/containers/main/Main'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import { getDearProducts } from 'store/modules/new-purchase'
import Product from './components/Product'
import Cart from 'views/new-purchase/components/Cart'
import { useNavigate, useNavigationType } from 'react-router-dom'
import Loading from '../../components/Loading'
import { extendData } from 'store/modules/new-purchase'
// new purchase service
import { useMutation } from '@apollo/client'
import { client, AUTH_CREATE_PURCHASE } from 'utils/apollo/purchaseService/client'
import LoadingPage from '../../components/LoadingPage'
import EMAILS from 'utils/constants/emails'
import useGTM from 'utils/hooks/useGTM'

export default function Accessories() {
  // get accessories
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loadingText, setLoadingText] = useState('fetching accessories...')
  const products = useSelector((state) => get(state, 'newPurchase.data.products', []))

  // if quantity > 1 show cart
  const { selectedAddOns = {} } = useSelector((state) => state.newPurchase.data)
  const hasAddOns = Object.keys(selectedAddOns).some((key) => selectedAddOns[key] > 0)

  const navigate = useNavigate()
  const navigationType = useNavigationType()

  // on load, if we detect a refresh, go to dashboard
  const { gtm, pageView, addToCart } = useGTM()
  const { uuid: userUuid, professionalCredentials, roles } = useSelector((state) => state.auth.user)

  // Fetch dear products based on subscription
  const {
    hasOrgFocusCertificationEnrolled,
    hasOrgSspCertificationEnrolled,
    hasOrgFocusCertificationCompleted,
    hasOrgSspCertificationCompleted,
    hasPaidFocusSubscription,
  } = useSelector((state) => get(state, 'organization', {}))
  const hasSspOrFocusCategories =
    hasOrgSspCertificationEnrolled ||
    (hasOrgFocusCertificationCompleted && hasPaidFocusSubscription)
  const focusTrainingStatus =
    (hasOrgFocusCertificationCompleted && 'completed') ||
    (hasOrgFocusCertificationEnrolled && 'enrolled')
  const sspTrainingStatus =
    (hasOrgSspCertificationCompleted && 'completed') ||
    (hasOrgSspCertificationEnrolled && 'enrolled')
  const fetchDearProducts = async () => {
    const fetchList = [
      hasOrgSspCertificationEnrolled && 'sspAccessory',
      hasOrgFocusCertificationCompleted && hasPaidFocusSubscription && 'focusAccessory',
      hasSspOrFocusCategories && 'accessory',
    ].filter(Boolean)
    await setLoading(true)
    await dispatch(getDearProducts({ productCategories: fetchList }))
    await setLoading(false)
  }
  useEffect(() => {
    fetchDearProducts()

    // on load: send virtual page load
    pageView({
      pageUrl: window.document.location.href,
      pageTitle: 'Internal Purchase - Select accessories',
    })
    // eslint-disable-next-line
  }, [])

  // set change url to here
  useEffect(() => {
    dispatch(
      extendData({
        redirectUrl: '/purchase/accessories',
      })
    )
    // eslint-disable-next-line
  }, [])

  const onBack = () => {
    navigate('/store')
  }

  const [createPurchase] = useMutation(AUTH_CREATE_PURCHASE, {
    client,
    variables: { params: { data: {} } },
  })
  const onContinue = async () => {
    setLoading(true)
    setLoadingText('creating purchase...')
    try {
      const internalPurchaseData = await createPurchase()
      const uuid = get(internalPurchaseData, 'data.auth_create_purchase.uuid', null)

      if (uuid) {
        await dispatch(extendData({ uuid }))
        const selectedAddOnsArray = products
          .map(({ SKU, PriceTier1, Name, Weight, WeightUnits }) => ({
            id: SKU,
            name: Name,
            price: PriceTier1,
            quantity: get(selectedAddOns, SKU, 0),
            weight: Weight,
            weightUnits: WeightUnits,
            tax: 0,
            total: get(selectedAddOns, SKU, 0) * PriceTier1,
          }))
          .filter(({ quantity }) => Boolean(quantity))

        const gtmItems = gtm?.items || []
        const items = [...selectedAddOnsArray, ...gtmItems]
        const value = items.reduce((sum, item) => sum + (item.price - item.discount), 0)
        // GA4
        selectedAddOnsArray.length &&
          (await addToCart({
            uuid: userUuid,
            value,
            items,
            professionalCredentials,
            roles,
            focusTrainingStatus,
            sspTrainingStatus,
          }))
        await navigate('/purchase/shipping')
      } else {
        setShowError(true)
      }
    } catch (error) {
      console.error(error)
      setShowError(true)
      setErrorMessage(error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (navigationType === 'POP') {
      navigate('/store')
      return
    }
    // eslint-disable-next-line
  }, [])

  return (
    <LoadingPage loading={loading} text={loadingText}>
      <MainLayout title="Accessories">
        <Grid container alignItems="stretch" justifyContent="flex-start" className="p-5">
          <Grid
            item
            xs={12}
            sm={12}
            lg={9}
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: 'inherit',
            }}
          >
            <Card elevation={0}>
              <CardHeader
                title="Please select your accessories"
                titleTypographyProps={{
                  fontWeight: '500',
                }}
              />
              <div className="pb-5 px-5">
                {showError && (
                  <FormHelperText className="my-2" error={true}>
                    There was an issue creating your purchase. For assistance, please contact our
                    Client Success team at{' '}
                    <a className="text-link" href={`mailto:${EMAILS.supportEmail}`}>
                      {EMAILS.supportEmail}
                    </a>
                    . For reference: {errorMessage}
                  </FormHelperText>
                )}
                <Grid container justifyContent="space-between" alignItems="center" className="p-5">
                  <Button color="primary" className="w-25" onClick={onBack}>
                    Back
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={!hasAddOns}
                    onClick={onContinue}
                  >
                    Continue
                  </Button>
                </Grid>
                <Divider />
              </div>
            </Card>
          </Grid>
          <Hidden mdUp>
            <Grid item lg={3} />
          </Hidden>
          <Grid item container xs={9} justifyContent="flex-start" alignItems="flex-start">
            {loading &&
              Array.from({ length: 20 }, () => (
                <Grid item xs={4}>
                  <Loading columns={1} rows={1} width={['100%']} height={['100%']} />
                </Grid>
              ))}
          </Grid>
          <Grid container item xs={12}>
            {!hasSspOrFocusCategories && (
              <Typography variant="body2" sx={{ mx: 3 }}>
                Please contact{' '}
                <a href="mailto:success@unyte.com" className="text-link">
                  success@unyte.com
                </a>{' '}
                if you are looking for any specific accessories, or if you require additional
                assistance.
              </Typography>
            )}
            {hasSspOrFocusCategories && (
              <Grid
                item
                container
                md={hasAddOns ? 6 : 9}
                // md={6}
                lg={9}
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={3}
                className="pr-5"
              >
                <Hidden mdUp>
                  <Grid item xs={12} md={6} lg={3}>
                    <Card variant="outlined">
                      <Cart hasAccessory disabled />
                    </Card>
                  </Grid>
                </Hidden>
                {!!products.length &&
                  products.map((product, index) => (
                    <Product key={`product${index}`} product={product} index={index} />
                  ))}
              </Grid>
            )}
            {!loading && (
              <Hidden mdDown>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={3}
                  sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    backgroundColor: 'inherit',
                  }}
                >
                  <Card
                    sx={{
                      position: 'sticky',
                      zIndex: 1,
                      backgroundColor: 'inherit',
                      top: '12%',
                    }}
                    variant="outlined"
                  >
                    <Cart hasAccessory disabled />
                  </Card>
                </Grid>
              </Hidden>
            )}
          </Grid>
        </Grid>
      </MainLayout>
    </LoadingPage>
  )
}
