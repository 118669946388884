import React from 'react'
import { useDispatch } from 'react-redux'

import { Grid, Card, CardHeader, CardContent, Link, Typography } from 'components'
import Stepper from 'components/Stepper'
import { setIntroVideoState, setProduct } from 'store/modules/app'

import ROLES from 'utils/constants/roles'
import { includesSome } from 'utils/includes'

const ALLOWED_ROLES = [...ROLES.PROVIDER_ROLES, ROLES.CLIENT]

/**
 * TODO: UW-1375: Update SSP related copy
 * https://unytehealth.atlassian.net/browse/UW-1375
 */
export default function GettingStarted({ gridProps, roles, data }) {
  const dispatch = useDispatch()

  if (!includesSome(roles, ALLOWED_ROLES)) {
    return null
  }
  const handleIntroVideoClick = () => {
    dispatch(setIntroVideoState(true))
    dispatch(setProduct(data.product))
  }

  const type = data.product === 'Focus' ? 'ILS' : data.product

  const providerSteps = [
    <Typography fontWeight={400} variant="body2">
      Watch this short{' '}
      <span
        style={{
          color: '#616161',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        onClick={handleIntroVideoClick}
      >
        {type} intro video
      </span>
      .
    </Typography>,
    <Typography fontWeight={400} variant="body2">
      Click on{' '}
      <Link
        to="/clients"
        style={{
          color: '#616161',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
      >
        Clients
      </Link>{' '}
      in the left menu and add a Client.
    </Typography>,
    <Typography fontWeight={400} variant="body2">
      Install the Unyte-iLs app on your iOS or Android device and sign in with your MyUnyte account
      info.
    </Typography>,
    <Typography fontWeight={400} variant="body2">
      Check out the{' '}
      <Link
        style={{
          color: '#616161',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        to="/resources"
      >
        Resources
      </Link>{' '}
      available to you.
    </Typography>,
  ]

  const clientSteps = [
    <Typography fontWeight={400} variant="body2">
      Watch this short{' '}
      <span
        style={{
          color: '#616161',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        onClick={handleIntroVideoClick}
      >
        intro video
      </span>
      .
    </Typography>,
    <Typography fontWeight={400} variant="body2">
      Install the Unyte-iLs app on your iOS or Android device and sign in using your MyUnyte account
      info.
    </Typography>,
    <Typography fontWeight={400} variant="body2">
      Consult your {type} provider for next steps. Do not start the{' '}
      {data.product === 'Focus'
        ? 'Integrated Listening System (ILS)'
        : 'Safe and Sound Protocol (SSP)'}{' '}
      without your provider's instructions.
    </Typography>,
    <Typography fontWeight={400} variant="body2">
      Check out the{' '}
      <Link
        style={{
          color: '#616161',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        to="/resources"
      >
        Resources
      </Link>{' '}
      available to you.
    </Typography>,
    <Typography fontWeight={400} variant="body2">
      Breathe.
    </Typography>,
  ]

  const steps = includesSome(roles, ROLES.PROVIDER_ROLES) ? providerSteps : clientSteps

  return (
    <Grid item {...gridProps} key="getting-started-card">
      <Card
        data-test={`dashboard-card-getting-started-${data.product}`}
        elevation={0}
        sx={{
          height: '100%',
          width: '100%',
          backgroundColor: '#edf2f7',
        }}
        p={2}
      >
        <CardHeader
          title={`Getting Started with ${type}`}
          titleTypographyProps={{ variant: 'h5' }}
        />
        <CardContent>
          <Stepper steps={steps} backgroundColor="#edf2f7" connector={null} />
        </CardContent>
      </Card>
    </Grid>
  )
}

export const ProviderGettingStarted = ({ gridProps, type }) => {
  const dispatch = useDispatch()
  const handleIntroVideoClick = () => {
    dispatch(setIntroVideoState(true))
    dispatch(setProduct(type))
  }

  const steps = [
    <Typography fontWeight={400} variant="body2">
      Watch this short{' '}
      <span
        style={{
          color: '#616161',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        onClick={handleIntroVideoClick}
      >
        {type} intro video
      </span>
      .
    </Typography>,
    <Typography fontWeight={400} variant="body2">
      Click on{' '}
      <Link
        to="/clients"
        style={{
          color: '#616161',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
      >
        Clients
      </Link>{' '}
      in the left menu and add a Client.
    </Typography>,
    <Typography fontWeight={400} variant="body2">
      Install the Unyte-iLs app on your iOS or Android device and sign in with your MyUnyte account
      info.
    </Typography>,
    <Typography fontWeight={400} variant="body2">
      Check out the{' '}
      <Link
        style={{
          color: '#616161',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        to="/resources"
      >
        Resources
      </Link>{' '}
      available to you.
    </Typography>,
  ]

  return (
    <Grid item {...gridProps} key="getting-started-card">
      <Card
        data-test={`dashboard-card-getting-started-${type}`}
        sx={{
          height: '100%',
          width: '100%',
          backgroundColor: '#edf2f7',
        }}
        p={2}
        elevation={0}
      >
        <CardHeader
          title={`Getting Started with ${type}`}
          titleTypographyProps={{ variant: 'h5' }}
        />
        <CardContent>
          <Stepper steps={steps} backgroundColor="#edf2f7" connector={null} />
        </CardContent>
      </Card>
    </Grid>
  )
}

export const ClientGettingStarted = ({ gridProps, type }) => {
  const dispatch = useDispatch()

  const handleIntroVideoClick = () => {
    dispatch(setIntroVideoState(true))
    dispatch(setProduct(type))
  }
  const steps = [
    <Typography fontWeight={400} variant="body2">
      Watch this short{' '}
      <span
        style={{
          color: '#616161',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        onClick={handleIntroVideoClick}
      >
        intro video
      </span>
      .
    </Typography>,
    <Typography fontWeight={400} variant="body2">
      Install the Unyte-iLs app on your iOS or Android device and sign in using your MyUnyte account
      info.
    </Typography>,
    <Typography fontWeight={400} variant="body2">
      Consult your {type} provider for next steps. Do not start the{' '}
      {type === 'Focus' ? 'Integrated Listening System (ILS)' : 'Safe and Sound Protocol (SSP)'}{' '}
      without your provider's instructions.
    </Typography>,
    <Typography fontWeight={400} variant="body2">
      Check out the{' '}
      <Link
        style={{
          color: '#616161',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        to="/resources"
      >
        Resources
      </Link>{' '}
      available to you.
    </Typography>,
    <Typography fontWeight={400} variant="body2">
      Breathe.
    </Typography>,
  ]

  return (
    <Grid item {...gridProps} key="getting-started-card">
      <Card
        data-test={`dashboard-card-getting-started-${type}`}
        sx={{
          height: '100%',
          width: '100%',
          backgroundColor: '#edf2f7',
        }}
        p={2}
        elevation={0}
      >
        <CardHeader
          title={`Getting Started with ${type}`}
          titleTypographyProps={{ variant: 'h5' }}
        />
        <CardContent>
          <Stepper steps={steps} backgroundColor="#edf2f7" connector={null} />
        </CardContent>
      </Card>
    </Grid>
  )
}
