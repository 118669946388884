import React from 'react'
import { Outlet, matchPath } from 'react-router'
import { gql, useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import { useGetUserWithoutRefresh } from 'utils/hooks/useGetUser'
import MainLayout from 'components/containers/main/Main'

const TALENTLMS_URL_QUERY = gql`
  query getTalentLmsLoginInfo {
    getTalentLmsLoginInfo
  }
`

export interface AcademyContextType {
  showTalentLMSSSOButton: boolean
  hasSspCertification: boolean
  hasCompletedSspCertification: boolean
  hasFocusCertification: boolean
  hasCompletedFocusCertification: boolean
  hasVoiceProCertification: boolean
  hasCompletedVoiceProCertification: boolean
  error: string
  loading: boolean
  talentURL: string | undefined
}
export default function AcademyLayout() {
  const organization = useSelector((state) => get(state, 'organization', {}))
  const { hasPastDueSspSubscription, hasPastDueFocusSubscription } = organization
  const user = useSelector((state) => get(state, 'auth.user', {}))
  const showTalentLMSSSOButton = get(user, 'organization.settings.talentLMSSSOEnabled', false)

  // gql
  const { data, error: _error, loading } = useQuery(TALENTLMS_URL_QUERY, {
    skip: !showTalentLMSSSOButton,
  })
  const error = get(_error, 'graphQLErrors[0].message', '')
  const talentURL = get(data, 'getTalentLmsLoginInfo.loginUrl', '')

  // custom hook to grab certification status
  const {
    selectedUser: {
      hasSspCertification,
      hasCompletedSspCertification,
      hasFocusCertification,
      hasCompletedFocusCertification,
      hasVoiceProCertification,
      hasCompletedVoiceProCertification,
    },
  } = useGetUserWithoutRefresh()

  const TABS = [
    {
      text: 'On-demand Training',
      url: '/academy',
      isEnabled: true,
      isActive: (pathname) => matchPath({ path: '/academy' }, pathname),
    },
    {
      text: 'Live Training',
      url: '/academy/live-training',
      isEnabled: hasSspCertification || hasCompletedFocusCertification,
      /**
       * 1. no ssp no focus
       */
      isActive: (pathname) => matchPath({ path: '/academy/live-training' }, pathname),
    },
    {
      text: 'Certifications',
      url: '/academy/certifications',
      isEnabled: false,
      isActive: (pathname) => matchPath({ path: '/academy/certifications' }, pathname),
    },
  ].filter(({ isEnabled }) => !!isEnabled)

  return (
    <MainLayout title="Academy" tabs={TABS}>
      <Outlet
        context={{
          hasCompletedSspCertification,
          hasCompletedFocusCertification,
          hasCompletedVoiceProCertification,
          showTalentLMSSSOButton,
          hasSspCertification,
          hasFocusCertification,
          hasVoiceProCertification,
          error,
          loading,
          talentURL,
          hasPastDueSspSubscription,
          hasPastDueFocusSubscription,
        }}
      />
    </MainLayout>
  )
}
