import React from 'react'
import { includesSome } from 'utils/includes'
import { Link, Grid, Card, CardHeader, CardContent, Typography, Box } from 'components'
import ROLES from 'utils/constants/roles'
import { useSelector } from 'react-redux'

export default function SubscriptionUnpaid({ gridProps, type = '' }) {
  const { roles } = useSelector((state) => state.auth.user)
  const isBilling = includesSome(roles, [ROLES.BILLING])

  return (
    <Grid item {...gridProps}>
      <Card
        data-test={`dashboard-card-${type.toLowerCase()}-subscription-unpaid`}
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: '#edf2f7',
          padding: '1rem',
        }}
        elevation={0}
      >
        <CardHeader
          title={`${type} Subscription Payment Required`}
          titleTypographyProps={{ variant: 'h5' }}
        />
        <CardContent>
          <Box display="flex" p={0.5}>
            <div className="w-3 m-auto">
              <div
                style={{
                  width: '12px',
                  height: '12px',
                  borderRadius: '50%',
                  backgroundColor: 'currentColor',
                  marginTop: 3,
                  color: '#ff6600',
                }}
              />
            </div>
            <Typography variant="body2" className="py-1 px-3">
              Unfortunately your access to {type} has been disabled due to an unpaid subscription.
              You and your clients will be unable to access {type} until this matter is resolved.
            </Typography>
          </Box>
          {isBilling && (
            <Typography variant="body2" className="py-1">
              To re-activate your {type} subscription, please review and pay any outstanding
              invoices under{' '}
              <Link to="/billing" className="text-link">
                View Billing
              </Link>
              .
            </Typography>
          )}
          {!isBilling && (
            <Typography variant="body2" className="py-1">
              Please request that your plan Administrator review and pay any outstanding invoices.{' '}
            </Typography>
          )}
          <Typography variant="body2" className="py-1">
            For assistance, please contact our Client Success team at{' '}
            <a className="text-link" href="mailto:success@unyte.com">
              success@unyte.com
            </a>
            .
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  )
}
