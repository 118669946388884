/**
 *
 */
import React, { useMemo, useEffect } from 'react'
import { useLocation, Navigate, useParams } from 'react-router-dom'
import { useAuthenticator } from '@aws-amplify/ui-react'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'

import { useDispatch, useSelector } from 'react-redux'
import { includesSome } from 'utils/includes'
import NotFound from 'views/404'
import { useSnackbar } from 'notistack'
import { extendUser } from '../../store/modules/auth'
import { loadFFValues } from '../../store/modules/ff'
import { gql, useQuery } from '@apollo/client'
import PageLoader from '../PageLoader'
import ROLES from 'utils/constants/roles'

// product fruits
import useGTM from 'utils/hooks/useGTM'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { setActiveClients } from 'store/modules/activeClients'

const SIGN_IN_GET_ME = gql`
  query getMe {
    getMe {
      id
      email
      uuid
      firstName
      lastName
      fullName
      gender
      createdAt
      updatedAt
      organizationId
      roles
      organization {
        id
        name
        createdAt
        settings
      }
      providerId
      lastLoginAt
      isSuspended
      address1
      address2
      birthYear
      city
      state
      country
      phone
      dob
      otherProfessionalCredentials
      professionalCredentials
      zip
      clientsCount
      productPreferences
      providerProfileInfo
      tags {
        value
      }
      avatar
      avatarThumb
    }
  }
`

const GET_MULTIPLE_PROVIDERS = gql`
  query GetMultipleProviders($filter: FilterUsersInput, $limit: Int) {
    getUsers(filter: $filter, limit: $limit) {
      roles
    }
  }
`

export default function PrivateRouteWrapper({ roles: _roles = [], ff, notAuthorized, children }) {
  const location = useLocation()
  const { search } = useParams()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { route } = useAuthenticator((context) => [context.route])
  const featureFlags = useSelector((state) => state.ff)
  const authUser = useSelector((state) => get(state, 'auth.user', {}))
  const firstName = authUser?.firstName
  const country = authUser?.country
  const createdAt = authUser?.createdAt
  const id = authUser?.id
  const professionalCredentials = authUser?.professionalCredentials
  const otherProfessionalCredentials = authUser?.otherProfessionalCredentials
  const uuid = authUser?.uuid
  const clientsCount = authUser?.clientsCount
  const organizationId = get(authUser, 'organization.id', '')
  const loggedIn = authUser?.id
  const currentUserRoles = authUser?.roles || []
  const roles = useMemo(() => [].concat(_roles), [_roles])
  const enabledByFeatureFlag = !ff || !!featureFlags[ff]

  // get localstorage state
  const authState = window.localStorage.getItem('AuthState')

  // TODO SKIP if we already have data..
  const { loading: loadingGetMe, error } = useQuery(SIGN_IN_GET_ME, {
    skip: loggedIn,
    async onCompleted(data) {
      await dispatch(extendUser(data?.getMe))
      await dispatch(loadFFValues())
    },
  })

  // feature flags will always load or the app breaks
  const loading = !featureFlags.hasFFLoaded || loadingGetMe
  const isProvider = authUser?.roles?.some((role) => ROLES.PROVIDER_ROLES.includes(role))

  // Check if we have more than one provider in organization
  useQuery(GET_MULTIPLE_PROVIDERS, {
    fetchPolicy: 'cache-and-network',
    variables: { filter: { anyRoles: ['provider', 'provider_all_clients'] }, limit: 2 },
    skip: !isProvider,
    onCompleted: async (data) => {
      const providers = get(data, 'getUsers', [])

      dispatch(
        setActiveClients({
          // has to be more than 1, because current logged in provider + another in org..
          hasMultipleProviders: providers.length > 1,
        })
      )
    },
  })

  // need optional coaelescing here for cypress
  const _productsPreferences = get(authUser, 'productPreferences', {})
  const { hasSspProducts, hasFocusProducts } = useSelector((state) => state.ff)
  const productsPreferences = [
    get(_productsPreferences, 'sspCertification.enrolledAt', false) && 'sspEnrolled',
    get(_productsPreferences, 'sspCertification.completedAt', false) && 'sspCompleted',
    get(_productsPreferences, 'focusCertification.enrolledAt', false) && 'focusEnrolled',
    get(_productsPreferences, 'focusCertification.completedAt', false) && 'focusCompleted',
    hasSspProducts && 'sspActiveSubscription',
    hasFocusProducts && 'focusActiveSubscription',
  ]
    .filter(Boolean)
    .join(',')
  const strRoles = authUser?.roles?.toString()

  // gtm info
  const { login: loginGTM } = useGTM()
  const userUuid = get(authUser, 'uuid', '')
  const {
    hasOrgSspCertificationCompleted,
    hasOrgFocusCertificationCompleted,
    hasOrgSspCertificationEnrolled,
    hasOrgFocusCertificationEnrolled,
    loadedOrganization,
  } = useSelector((state) => state.organization)
  const focusTrainingStatus =
    (hasOrgFocusCertificationCompleted && 'completed') ||
    (hasOrgFocusCertificationEnrolled && 'enrolled')
  const sspTrainingStatus =
    (hasOrgSspCertificationCompleted && 'completed') ||
    (hasOrgSspCertificationEnrolled && 'enrolled')

  useEffect(() => {
    // add user guiding
    if (window.userGuiding && isProvider) {
      const userGuidingObject = {
        roles: strRoles,
        firstName,
        country,
        clientsCount,
        createdAt,
        id,
        organizationId,
        professionalCredentials,
        otherProfessionalCredentials,
        uuid,
        productsPreferences,
      }
      window.userGuiding.identify(authUser.uuid, userGuidingObject)
    }

    if (loadedOrganization) {
      loginGTM({
        uuid: isProvider ? userUuid : undefined,
        professionalCredentials,
        focusTrainingStatus,
        sspTrainingStatus,
        roles: authUser?.roles,
      })
    }

    // eslint-disable-next-line
  }, [isProvider, authUser])

  const isAuthorizedInFF = (notAuthorized) => {
    if (notAuthorized === 'hasProducts') {
      const PRODUCTS_FF = ['hasSspProducts', 'hasFocusProducts']
      return PRODUCTS_FF.some((product_FF) => featureFlags[product_FF])
    }

    return featureFlags[notAuthorized]
  }

  // only show loading sign to authenticated users
  if ((route === 'authenticated' || authState === 'success') && loading && !error) {
    return <PageLoader />
  } else if (!enabledByFeatureFlag) {
    return <NotFound />
  } else if (
    route !== 'authenticated' &&
    !(process.env.REACT_APP_STAGE === 'test' && window.Cypress)
  ) {
    return (
      <Navigate to="/sign-in" state={{ from: location, deepLinkSearchParam: search }} replace />
    )
  } else if (notAuthorized && !isAuthorizedInFF(notAuthorized)) {
    enqueueSnackbar('Access to this page is not authorized', {
      variant: 'error',
      action: CloseSnackbarAction,
    })
    return <Navigate to="/" state={{ from: location }} />
  } else if (currentUserRoles.length && roles.length && !includesSome(currentUserRoles, roles)) {
    enqueueSnackbar('Access to this page is not authorized', {
      variant: 'error',
      action: CloseSnackbarAction,
    })
    return <Navigate to="/" />
  }

  return (
    <>
      <Helmet>
        <title>MyUnyte - your online dashboard for managing SSP and ILS programs.</title>
      </Helmet>
      {children}
    </>
  )
}
