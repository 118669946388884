import React, { useState } from 'react'
import roles from 'utils/constants/roles'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import { useSnackbar } from 'notistack'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import MainLayout from 'components/containers/main/Main'
import LoadingPage from 'components/LoadingPage'
import { gql } from '@apollo/client'
import { useQuery } from 'utils/apollo'
import JoinToMyUnyteCommunity from './JoinToMyUnyteCommunity'

const GET_BETTERMODE_JWT = gql`
  query GET_BETTERMODE_JWT {
    getBetterModeUrlWithToken
  }
`

export default function Community() {
  const [urlWithToken, setUrlWithToken] = useState()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const user = useSelector((state) => state?.auth?.user)
  const isProvider = user.roles.some((role) => roles.PROVIDER_ROLES.includes(role))
  const providerProfileInfo = useSelector((state) =>
    get(state, 'auth.user.providerProfileInfo', {})
  )
  const organizationSettings = useSelector((state) =>
    get(state, 'auth.user.organization.settings', {})
  )

  useQuery(GET_BETTERMODE_JWT, {
    fetchPolicy: 'cache-and-network',
    skip: !isProvider || !user.providerProfileInfo?.isCommunityTosAccepted,
    onCompleted: (data) => {
      if (data.getBetterModeUrlWithToken.length) {
        setUrlWithToken(data.getBetterModeUrlWithToken)
      }
    },
    onError: (err) => {
      console.error(err)
      enqueueSnackbar('Failed to sign in to Community. Please try again later', {
        variant: 'error',
        action: CloseSnackbarAction,
      })
      navigate('/')
    },
  })

  if (
    !isProvider ||
    organizationSettings?.isCommunityDisabledForAllUsers ||
    !providerProfileInfo?.canAccessCommunity
  ) {
    navigate('/')
  }

  if (!user.providerProfileInfo?.isCommunityTosAccepted) {
    return <JoinToMyUnyteCommunity />
  }

  return (
    <LoadingPage loading={loading} text="loading..." backgroundColor="#eef7f3">
      <MainLayout>
        {urlWithToken && (
          <iframe
            role="application"
            src={urlWithToken}
            width="100%"
            height="100%"
            title="Community iframe"
            allow="autoplay; encrypted-media"
            allowFullScreen
            style={{ border: 'none', height: 'calc(100vh - 67px)' }}
            onLoad={() => setLoading(false)}
          />
        )}
      </MainLayout>
    </LoadingPage>
  )
}
