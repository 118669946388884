import React from 'react'
import { IconButton, InputBase, Paper } from '@mui/material'
import { Close, Search } from '@mui/icons-material'

const SearchBar = ({ keywords, handleClick, handleTextChange, placeholder }) => {
  return (
    <Paper
      sx={{
        p: 0.25,
        my: 1,
        backgroundColor: '#eef7f3',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      }}
      elevation={0}
      aria-label="search"
    >
      <InputBase
        startAdornment={
          <IconButton aria-label="search" disabled>
            <Search fontSize="small" />
          </IconButton>
        }
        endAdornment={
          keywords && (
            <IconButton aria-label="close" onClick={handleClick}>
              <Close fontSize="small" />
            </IconButton>
          )
        }
        placeholder={placeholder}
        value={keywords || ''}
        inputProps={{ 'aria-label': 'search resources' }}
        onChange={handleTextChange}
        fullWidth
        sx={{ paddingTop: '3px' }}
      />
    </Paper>
  )
}

export default SearchBar
