import React from 'react'
import { Grid, Card, CardHeader, CardContent, Box, Typography } from 'components'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import EMAILS from 'utils/constants/emails'
import ROLES from 'utils/constants/roles'

/**
 * TODO: UW-1375: Update SSP related copy
 * https://unytehealth.atlassian.net/browse/UW-1375
 */
export default function NoProducts({ gridProps }) {
  const currentUserRoles = useSelector((state) => get(state, 'auth.user.roles', []))
  const isClient = currentUserRoles.some((role) => ROLES.CLIENT_ROLES.includes(role))

  return (
    <Grid item {...gridProps}>
      <Card
        data-test="dashboard-card-no-products"
        sx={{
          height: '100%',
          width: '100%',
          backgroundColor: '#edf2f7',
          padding: '1rem',
        }}
        elevation={0}
      >
        <CardHeader title={'No Program Access'} titleTypographyProps={{ variant: 'h5' }} />
        <CardContent>
          <Box display="flex">
            {!isClient && (
              <div>
                <Typography variant="body2">
                  You currently don't have access to any available programs.
                </Typography>
                <Typography variant="body2">You may not have an active subscription.</Typography>
                <Typography variant="body2" className="pt-5" gutterBottom>
                  Please contact{' '}
                  <a className="text-link" href={`mailto:${EMAILS.supportEmail}`}>
                    Unyte-iLs support
                  </a>{' '}
                  for more details.
                </Typography>
              </div>
            )}

            {isClient && (
              <div>
                <Typography variant="body2">
                  You currently don't have access to any available programs.
                </Typography>
                <Typography variant="body2" className="py-3">
                  Please contact your Provider for more details.
                </Typography>
              </div>
            )}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  )
}
