import React from 'react'
import { Card, Grid, Typography, Box, Stack, Button } from 'components'
import { useNavigate } from 'react-router'

export default function ContinueWithAssessments({ gridProps }) {
  const navigate = useNavigate()

  return (
    <>
      <Grid item {...gridProps} key="continue-with-assessments-card">
        <Card
          sx={{ height: '100%' }}
          data-test="dashboard-card-continue-with-assessments"
          elevation={0}
        >
          <Grid container sx={{ width: '100%', height: '100%', backgroundColor: '#edf2f7' }}>
            <Box p={4} sx={{ width: '100%', height: '100%', backgroundColor: '#edf2f7' }}>
              <Stack alignItems="center">
                <Typography variant="body2" textAlign="center" fontWeight={500}>
                  A Gentle Reminder about Unyte Assessments
                </Typography>
                <Typography className="pt-7 pb-3" variant="body2" textAlign="center">
                  No assessment activity over the past 30 days.
                </Typography>
                <Typography className="pb-8" variant="body2" textAlign="center">
                  Unyte Assessments is a versatile tool that will help you understand your clients,
                  inform program planning, and measure changes over time.
                </Typography>
                <Button
                  className="mb-5"
                  variant="contained"
                  sx={{ width: '200px' }}
                  onClick={() => navigate('/assessments')}
                >
                  Start New Assessment
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Card>
      </Grid>
    </>
  )
}
