import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import ProgressBar from './ProgressBar'
import { Grid, Card, Container, Box } from 'components'
import Cart from './Cart'
import { removeCoupon } from 'store/modules/new-purchase'
import LoadingPage from '../../../components/LoadingPage'
import PurchaseFooter from './PurchaseFooter'

export default ({
  children,
  disableCartEdit,
  hideCartEdit,
  hideProgress,
  isConfirmPage,
  isPurchaseComplete,
  loading,
  loadingText,
  next,
  step,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isPurchaseComplete) {
      dispatch(removeCoupon())
    }
    // eslint-disable-next-line
  }, [])

  return (
    <LoadingPage loading={loading} text={loadingText} backgroundColor="#eef7f3">
      <Box sx={{ display: { sm: 'block', xs: 'block', md: 'none' } }}>
        <div style={{ width: '100%', height: '50px', backgroundColor: '#008080' }} />
        <ProgressBar hideProgress={hideProgress} />
        <Container maxWidth="lg">
          <Grid container spacing={3} className="p-3">
            <Grid item xs={12}>
              <Cart
                disabled={disableCartEdit}
                hideEdit={hideCartEdit}
                isConfirmPage={isConfirmPage}
                isPurchaseComplete={isPurchaseComplete}
                next={next}
                step={step}
              />
            </Grid>
            <Grid item xs={12}>
              <Card p={4} elevation={0}>
                {children}
              </Card>
            </Grid>
          </Grid>
        </Container>
        <PurchaseFooter />
      </Box>
      <Box sx={{ display: { sm: 'none', md: 'block' } }}>
        <div style={{ width: '100%', height: '50px', backgroundColor: '#008080' }} />
        <ProgressBar hideProgress={hideProgress} />
        <Container maxWidth="lg">
          <Grid container className="py-3">
            <Grid item md={7} className="pl-5 pr-3">
              <Card p={4} elevation={0}>
                {children}
              </Card>
            </Grid>
            <Grid item md={5} className="pr-5 pl-3">
              <Cart
                disabled={disableCartEdit}
                hideEdit={hideCartEdit}
                isConfirmPage={isConfirmPage}
                isPurchaseComplete={isPurchaseComplete}
                next={next}
                step={step}
              />
            </Grid>
          </Grid>
        </Container>
        <PurchaseFooter />
      </Box>
    </LoadingPage>
  )
}
