import React, { useState, useEffect } from 'react'
import get from 'lodash/get'
import { useQuery } from 'utils/apollo'
import { gql } from '@apollo/client'
import Details from './InvitationDetails'
import moment from 'utils/moment'
import deepTrim from 'utils/deepTrim'
import { set } from 'utils'
import SearchBar from '../components/Search'
import formats from 'utils/constants/formats'

import { Grid, UnyteTable, Divider } from 'components'
import SortLabel from '../components/table/SortLabel'
import { Chip, Stack, Typography } from '@mui/material'
import { Done } from '@mui/icons-material'

// Todo: move the colours into a constant file.
const DELIVERY_STATUS = [
  {
    filterName: 'Delivered',
    colour: 'rgb(110, 231, 183)',
  },
  {
    filterName: 'Permanently Failed',
    colour: 'rgb(235, 148, 134)',
  },
  {
    filterName: 'Temporarily Failed',
    colour: 'rgb(255, 172, 28)',
  },
  {
    filterName: 'Queued for Sending',
    colour: 'rgb(252, 211, 77)',
  },
]

const GET_INVITATIONS = gql`
  query getInvitations($filter: FilterInvitationsInput, $sort: [[String!]], $offset: Int) {
    getInvitations(filter: $filter, sort: $sort, offset: $offset) {
      createdAt
      acceptedAt
      expiresAt
      id
      remindedAt
      remindedTimes
      sentAt
      toEmail
      updatedAt
      userId
      firstName
      lastName
      user {
        id
        fullName
        email
      }
      mailgunMessage {
        mailgunStatus
        updatedAt
        metadata
      }
    }
  }
`

export default function Invitations({ roles }) {
  const [selectedInvitation, setSelectedInvitation] = useState(null)
  const [queryVars, setQueryVars] = useState({
    // sort: [],
    filter: {
      anyRoles: roles,
      any: '',
      status: 'pending',
      emailStatus: '',
    },
  })

  const { loading: invitationLoading, data: invitationResponse, refetch } = useQuery(
    GET_INVITATIONS,
    {
      variables: deepTrim(queryVars),
      fetchPolicy: 'cache-and-network',
    }
  )
  const [pending, setPending] = useState(false)

  useEffect(() => {
    setPending(invitationLoading)
  }, [invitationLoading])
  const invitationData = get(invitationResponse, 'getInvitations', [])

  const [filter, setFilter] = useState()
  const handleClick = (event) => {
    const newStatusfilter = event?.target?.textContent !== filter ? event.target.textContent : ''
    setFilter(newStatusfilter)
    setQueryVars(set(queryVars, 'filter.emailStatus', newStatusfilter))
  }

  const onSearchbarChange = (event) => {
    const newObject = {
      ...queryVars,
      filter: { ...queryVars.filter, any: event.target.value },
    }
    setQueryVars(newObject)
  }
  const onClear = () => {
    const newObject = {
      ...queryVars,
      filter: { ...queryVars.filter, any: '' },
    }
    setQueryVars(newObject)
  }

  return (
    <div className="px-5">
      <Grid container className="py-2" justifyContent="space-between" spacing={3}>
        <Grid item sm={5} xs={12}>
          <SearchBar
            placeholder="Search by email"
            handleTextChange={onSearchbarChange}
            handleClick={onClear}
            keywords={get(queryVars, 'filter.any', '')}
          />
        </Grid>
        <Grid item sm={7} xs={12}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            height="100%"
            justifyContent="flex-end"
            divider={<Divider orientation="vertical" flexItem />}
          >
            <Typography variant="h6">Filters</Typography>
            <>
              {DELIVERY_STATUS.filter((tag) => tag.filterName !== 'Queued for Sending').map(
                (tag, index) => {
                  return (
                    <Chip
                      key={index}
                      avatar={filter === tag.filterName && <Done />}
                      label={tag.filterName}
                      onClick={handleClick}
                      sx={{ backgroundColor: tag.colour }}
                    />
                  )
                }
              )}
            </>
          </Stack>
        </Grid>
      </Grid>
      <UnyteTable
        loading={pending}
        rowQuantity={10}
        data={!pending ? invitationData : []}
        sort={queryVars.sort}
        onSort={({ sort }) => setQueryVars((old) => ({ ...old, sort }))}
        selectedRow={selectedInvitation}
        onSelectRow={setSelectedInvitation}
        columns={[
          {
            header: ({ onSort, sort }) => (
              <SortLabel sort={sort} title="Email" sortKey="toEmail" onChange={onSort} />
            ),
            body: ({ data }) => (
              <>
                <b>
                  {data.firstName} {data.lastName}
                </b>
                <br />
                {data.toEmail}
              </>
            ),
          },
          {
            header: () => 'Delivery Status',
            body: ({ data }) =>
              DELIVERY_STATUS.filter(
                (tag) => tag.filterName === data.mailgunMessage?.mailgunStatus
              ).map((deliveryStatus) => {
                return (
                  <Chip
                    key={deliveryStatus.filterName}
                    label={deliveryStatus.filterName}
                    sx={{ backgroundColor: deliveryStatus.colour }}
                  />
                )
              }),
          },
          {
            header: ({ onSort, sort }) => (
              <SortLabel
                sort={sort}
                title="Invitation Created"
                sortKey="createdAt"
                onChange={onSort}
              />
            ),
            body: ({ data }) => moment(data?.createdAt).format(formats.dateAndTime),
          },
          {
            header: ({ onSort, sort }) => (
              <SortLabel
                sort={sort}
                title="Last Reminded"
                sortKey="remindedAt"
                onChange={onSort}
                tooltipMessage="Invitation emails are automatically sent up to 4 times over a 48 day period, until accepted."
              />
            ),
            body: ({ data }) => {
              // if there is no mailgun message (very old invitation), use updatedAt
              return data.mailgunMessage?.updatedAt
                ? moment(data.mailgunMessage?.updatedAt).format(formats.dateAndTime)
                : moment(data?.createdAt).format(formats.dateAndTime)
            },
          },
        ]}
        detailsComponent={
          queryVars.filter.status === 'pending'
            ? ({ data: selectedUser }) => (
                <Details
                  user={selectedUser}
                  onUpdate={() => {
                    refetch()
                    setSelectedInvitation(null)
                  }}
                />
              )
            : null
        }
        showDetails={(row) => !row.acceptedAt}
      />
    </div>
  )
}
