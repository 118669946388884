/**
 * This file will host the professional credentials for:
 * - new purchase
 * - verify professional credentials modal
 * - profile > edit profesional credential
 */

export const PROFESSIONAL_CREDENTIALS = [
  'Acupuncturist (L.Ac, NCCAOM Board Certified), Licensed',
  'Addiction and Rehabilitation Counselor (LCDC, CRC), Licensed',
  'Art Therapist, Registered, Board Certified (ATR-BC)',
  'Audiologist (AuD), Licensed',
  'Chiropractor (DC), Licensed',
  'Certified Craniosacral Therapist (C-CST)',
  'Dance / Movement / Art Therapist, Registered or Board Certified',
  'Dentists (DDS)',
  'Developmental and Neuro-Optometric Rehabilitation Optometrist (FCOVD, FAAO), Licensed',
  'Educator, School Counselor (M. Ed), Licensed',
  'Certified Eye Movement Desensitization & Reprocessing Therapist (C-EMDR)',
  'Feldenkrais Practitioner®',
  'Massage Therapist (LMT), Licensed',
  'Mental Health Counselor/Therapist Associate (AMFT, LPCA, LMHCA)',
  'Mental Health Counselor/Therapist (LMFT, LPC, LMHC), Licensed',
  'Music Therapist, Board Certified (MT-BC, RMT)',
  'Naturopathic Doctor (ND), Licensed',
  'Neuro-Optometric Rehabilitation Developmental Optometrist (FNORA), Licensed',
  'Nurse (NP, RPN, RN, LPN), Licensed',
  'Occupational Therapist (OT/L), Licensed',
  'Certified Occupational Therapy Assistant (COTA/L), Licensed',
  'Physical Therapist (PT), Licensed',
  'Physical Therapy Assistant (PTA), Licensed',
  'Physician (MD, DO), Licensed',
  'Physician Assistant (PA), Licensed',
  'Psychiatrist (MD, DO), Licensed',
  'Psychologist (PsyD, PhD, EdD), Licensed',
  'Psychotherapist, Licensed',
  'Recreation Therapist (RT), Licensed',
  'Registered Dietitian (RD)',
  'Rolfer® or Structural Integrator',
  'Social Worker (LCSW, LMSW), Registered or Licensed',
  'Somatic Experiencing Practitioner (SEP)',
  'Speech Language Pathologist (SLP), Licensed',
  'Speech Language Pathology Assistant (SLPA), Licensed',
  'Veterinarian (DVM)',
  'Certified Vision Therapist (COVT), Licensed',
  'Certified Yoga Therapist (C-IAYT)',
  'Other',
]
