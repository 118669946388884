import React from 'react'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import ROLES from 'utils/constants/roles'
import LINKS from 'utils/constants/links'
import { Collapse, Card, Button, Grid, Typography, CardContent, CardMedia } from 'components'
import MainLayout from 'components/containers/main/Main'

export function ProductCard({ description, name, metadata }) {
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const borderSX = {
    borderTop: 10,
    borderBottom: 10,
    borderColor: metadata.borderColor,
  }

  return (
    <Card
      justify="center"
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <CardMedia
        sx={metadata?.borderColor ? borderSX : null}
        height="200"
        component="img"
        src={metadata.pictureUrl}
      />
      <CardContent style={{ paddingBottom: '0px' }}>
        <Typography fontWeight={500} className={`mt-2 mb-3`}>
          {name}
        </Typography>
        <Typography
          sx={{
            whiteSpace: 'pre-wrap',
            fontSize: '14px',
            color: '#616161',
          }}
          paragraph
        >
          {description}
        </Typography>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Typography
            sx={{
              whiteSpace: 'pre-wrap',
              fontSize: '14px',
              color: '#616161',
            }}
            style={{ color: '#616161' }}
            paragraph
          >
            {metadata.expandedContent}
          </Typography>
        </Collapse>
        <Grid container direction="row" justifyContent="space-around" alignItems="center">
          <Grid
            item
            sx={{
              height: '1px',
              backgroundColor: 'rgba(0, 0, 0, 0.12)',
              border: 'none',
              margin: 0,
              width: '37%',
            }}
          />
          <Grid item>
            <Button onClick={handleExpandClick} aria-label="show more">
              <Typography variant="button">{expanded ? 'Less' : 'More'}</Typography>
            </Button>
          </Grid>
          <Grid
            item
            sx={{
              height: '1px',
              backgroundColor: 'rgba(0, 0, 0, 0.12)',
              border: 'none',
              margin: 0,
              width: '37%',
            }}
          />
        </Grid>
        <div className="mt-1">
          <Typography
            sx={{
              whiteSpace: 'pre-wrap',
              fontSize: '12px',
              color: '#616161',
              textAlign: 'center',
            }}
          >
            This program requires the Unyte Health app.
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="py-3"
          >
            <Grid item>
              <a target="_blank" rel="noopener noreferrer" href={LINKS.download.playStore.href}>
                <img
                  alt="Android app"
                  style={{
                    height: 36,
                    width: 107,
                  }}
                  src={LINKS.download.playStore.imgSrc}
                />
              </a>
            </Grid>
            <Grid item>
              <a target="_blank" rel="noopener noreferrer" href={LINKS.download.appStore.href}>
                <img
                  alt="App Store"
                  style={{
                    height: 36,
                    width: 119,
                  }}
                  src={LINKS.download.appStore.imgSrc}
                />
              </a>
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </Card>
  )
}

export default function Products() {
  const data = useSelector((state) => state.seats.products)
  const isClient = useSelector((state) => get(state, 'auth.user.roles', []).includes(ROLES.CLIENT))
  const filteredData = data?.filter((item) => item?.category !== 'assessment').filter(Boolean)

  return (
    <MainLayout
      title="Programs"
      sx={{
        justifyContent: 'center',
        padding: 20,
        paddingTop: 15,
      }}
    >
      <Grid container className="p-5">
        {filteredData
          ?.sort((a, b) => a.order - b.order)
          .map(({ name, description, metadata, id }, index) => (
            <Grid item xs={12} md={6} lg={4} sx={{ padding: '0.5rem' }} key={`product-${index}`}>
              <ProductCard name={name} id={id} description={description} metadata={metadata} />
            </Grid>
          ))}
        {!filteredData.length && isClient && (
          <div>
            <Typography gutterBottom variant="body2" className="py-2">
              Sorry but you currently don't have access to any available programs.
            </Typography>
            <Typography gutterBottom variant="body2" className="py-2">
              Please contact your Provider for more details.
            </Typography>
          </div>
        )}
        {!filteredData.length && !isClient && (
          <div>
            <Typography gutterBottom variant="body2" className="py-2">
              Sorry but you currently don't have access to any available programs. You might not
              have an active subscription.
            </Typography>
            <Typography gutterBottom variant="body2" className="py-2">
              Please contact Unyte-iLs support for more details.
            </Typography>
          </div>
        )}
      </Grid>
    </MainLayout>
  )
}
