import React from 'react'
import { Avatar, Badge, Tooltip, Stack, Typography } from 'components'
import { useSelector } from 'react-redux'

const Wrapper = ({ children, invisible, isSuspended }) => {
  const { showNewSubscriptionPlan } = useSelector((state) => state.ff)
  const badgeColor = isSuspended ? 'error' : 'primary'
  const badgeText = isSuspended ? 'Suspended' : 'Active'
  const badgeInvisible = isSuspended ? false : invisible

  return !showNewSubscriptionPlan ? (
    <Tooltip
      title={
        !invisible && !isSuspended ? (
          <>
            An <span className="font-semibold">active client</span> is a client who has listened to
            30 minutes or more of any SSP music within your monthly billing cycle.
          </>
        ) : (
          ''
        )
      }
    >
      <Badge
        color={badgeColor}
        badgeContent={badgeText}
        data-test="client-active-badge"
        invisible={badgeInvisible}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ '& .MuiBadge-badge': { fontSize: 10 } }}
      >
        {children}
      </Badge>
    </Tooltip>
  ) : (
    <Badge
      color={'error'}
      badgeContent={'Suspended'}
      data-test="client-active-badge"
      invisible={!isSuspended}
      anchorOrigin={{
        vertical: 'top',

        horizontal: 'left',
      }}
      sx={{ '& .MuiBadge-badge': { fontSize: 10 } }}
    >
      {children}
    </Badge>
  )
}

export default ({ firstName, lastName, isSuspended, email, activeClientsFilter }) => (
  <Stack direction="row" sx={{ ml: 2 }}>
    <Wrapper invisible={!activeClientsFilter || isSuspended} isSuspended={isSuspended}>
      <Avatar firstName={firstName} lastName={lastName} suspended={isSuspended} />
    </Wrapper>
    <Stack direction="column" mx={1}>
      <Typography variant="body2" sx={{ fontWeight: 600 }}>
        {firstName} {lastName}
      </Typography>
      <Typography variant="body2">{email}</Typography>
    </Stack>
  </Stack>
)
