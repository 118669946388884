import React from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';

interface CloseSnackbarActionProps {
  key: string | number;
}

const CloseSnackbarAction: React.FC<CloseSnackbarActionProps> = ({ key }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton
      aria-label="close-snackbar"
      onClick={() => closeSnackbar(key)}
      color="inherit"
      className="text-white"
      size="large"
    >
      <CloseIcon />
    </IconButton>
  );
}

export default CloseSnackbarAction;
