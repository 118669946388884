import React from 'react'
import { Card, Grid, Typography } from '@mui/material'

export default ({ data }) => (
  // we need to use padding here or grid will be pushed
  <Grid item container xs={12} sm={8} spacing={1} pl={4}>
    {data
      .filter(({ value }) => !!value)
      .map(({ label, value }, index) => {
        return (
          <Grid item xs={12} sm={6} key={`previewUserDetails-${index}`}>
            <Typography variant="subtitle2" fontWeight={400}>
              {label}
            </Typography>
            <Typography variant="body2">{value}</Typography>
          </Grid>
        )
      })}
  </Grid>
)

export const PreviewSlot = ({ data }) => {
  if (!data?.length) {
    return null
  }
  return (
    <Grid item container xs={12} sm={4} spacing={1} justifyContent="flex-end">
      <Card sx={{ backgroundColor: '#f3f4f6', py: 1, px: 2 }} elevation={0}>
        {data.map(({ label, value }, index) => (
          <div key={`preview${label}${index}`}>
            <Typography variant="subtitle2">{label}</Typography>
            <Typography variant="body2">{value}</Typography>
          </div>
        ))}
      </Card>
    </Grid>
  )
}
