/**
 * To Do: merge this file with Products (UW-7402)
 *
 */
import React, { useEffect, useState } from 'react'
import { Grid, Card, Button, CardHeader, Divider, Hidden, FormHelperText } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import ClientLicenseProduct from './components/ClientLicenseProduct'
import Cart from 'views/new-purchase/components/Cart'
import { useNavigate, useNavigationType } from 'react-router-dom'
import Loading from '../../components/Loading'
import { extendData } from 'store/modules/new-purchase'
import { gql, useMutation } from '@apollo/client'
import { client, AUTH_CREATE_PURCHASE } from 'utils/apollo/purchaseService/client'
import LoadingPage from '../../components/LoadingPage'
import EMAILS from 'utils/constants/emails'
import useGTM from 'utils/hooks/useGTM'

const AUTH_UPDATE_PURCHASE = gql`
  mutation AUTH_UPDATE_PURCHASE($params: AuthUpdatePurchaseInput!, $uuid: String!) {
    auth_update_purchase(params: $params, uuid: $uuid) {
      uuid
      stripe_invoice
      stripe_invoice_id
    }
  }
`

export default function ClientLicensesStoreFront() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loadingText, setLoadingText] = useState()
  const products = useSelector((state) => get(state, 'newPurchase.data.products', []))

  // TODO: separate logic from selectedAddOns (UW-7402)
  const { clientLicenses = {} } = useSelector((state) => state.newPurchase.data)
  const hasClientLicenses = Object.keys(clientLicenses).some((key) => clientLicenses[key] > 0)

  const navigate = useNavigate()
  const navigationType = useNavigationType()

  // on load, if we detect a refresh, go to dashboard
  const { gtm, pageView, addToCart } = useGTM()
  const { uuid: userUuid, professionalCredentials, roles } = useSelector((state) => state.auth.user)

  const { showNewSubscriptionPlan, hasSspProducts, hasFocusProducts } = useSelector(
    (state) => state.ff
  )
  const {
    hasOrgSspCertificationCompleted,
    hasOrgFocusCertificationCompleted,
  } = useSelector((state) => get(state, 'organization', {}))

  const clientLicenseProducts = [
    showNewSubscriptionPlan &&
      (hasSspProducts || hasOrgSspCertificationCompleted) && {
        SKU: 'sspSlot',
        PriceTier1: 25,
        Name: 'SSP Client License',
        testName: 'ssp-licenses-purchase-card',
      },
    showNewSubscriptionPlan &&
      (hasFocusProducts || hasOrgFocusCertificationCompleted) && {
        SKU: 'focusSlot',
        PriceTier1: 25,
        Name: 'ILS Client License',
        testName: 'ils-licenses-purchase-card',
      },
  ].filter(Boolean)

  useEffect(() => {
    if (!products.length) {
      dispatch(extendData({ products: clientLicenseProducts }))
    }
  }, [products])

  const [getTax] = useMutation(AUTH_UPDATE_PURCHASE, {
    client,
  })

  useEffect(() => {
    // on load: send virtual page load
    pageView({
      pageUrl: window.document.location.href,
      pageTitle: 'Internal Purchase - Select Client Licenses',
    })
    // eslint-disable-next-line
  }, [])

  const onBack = () => {
    navigate('/store')
  }

  const [createPurchase] = useMutation(AUTH_CREATE_PURCHASE, {
    client,
    variables: { params: { data: {} } },
  })

  const onContinue = async () => {
    setLoading(true)
    setLoadingText('creating purchase...')
    try {
      const internalPurchaseData = await createPurchase()
      const uuid = get(internalPurchaseData, 'data.auth_create_purchase.uuid', null)

      if (uuid) {
        await dispatch(extendData({ uuid }))

        const clientLicenseProductsArray = clientLicenseProducts
          .map(({ SKU, PriceTier1, Name }) => ({
            id: SKU,
            name: Name,
            price: PriceTier1,
            quantity: get(clientLicenses, SKU, 0),
            tax: 0,
            total: get(clientLicenses, SKU, 0) * PriceTier1,
          }))
          .filter(({ quantity }) => Boolean(quantity))

        const gtmItems = gtm?.items || []
        const items = [...clientLicenseProductsArray, ...gtmItems]
        const value = items.reduce((sum, item) => sum + (item.price - item.discount), 0)
        // GA4
        clientLicenseProductsArray.length &&
          (await addToCart({
            uuid: userUuid,
            value,
            items,
            professionalCredentials,
            roles,
          }))

        const taxResult = await getTax({
          variables: {
            params: {
              items: clientLicenseProductsArray.map(({ id, quantity }) => ({
                sku: id,
                quantity,
              })),
            },
            uuid: uuid,
          },
        })

        const tax = get(taxResult, 'data.auth_update_purchase.stripe_invoice.tax', 0)

        await dispatch(
          extendData({
            totalTax: tax,
          })
        )

        await navigate('/purchase/confirm')
      } else {
        setShowError(true)
      }
    } catch (error) {
      console.error(error)
      setShowError(true)
      setErrorMessage(error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (navigationType === 'POP') {
      navigate('/store')
      return
    }
    // eslint-disable-next-line
  }, [])

  return (
    <LoadingPage loading={loading} text={loadingText}>
      <Grid container alignItems="stretch" justifyContent="flex-start" className="p-5">
        <Grid
          item
          xs={12}
          sm={12}
          lg={9}
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: 'inherit',
          }}
        >
          <Card elevation={0}>
            <CardHeader
              title="Purchase Client Licenses"
              titleTypographyProps={{
                fontWeight: 500,
              }}
            />
            <div className="pb-5 px-5">
              {showError && (
                <FormHelperText className="my-2" error={true}>
                  There was an issue creating your purchase. For assistance, please contact our
                  Client Success team at{' '}
                  <a className="text-link" href={`mailto:${EMAILS.supportEmail}`}>
                    {EMAILS.supportEmail}
                  </a>
                  . For reference: {errorMessage}
                </FormHelperText>
              )}
              <Grid container justifyContent="space-between" alignItems="center" className="p-5">
                <Button color="primary" className="w-25" onClick={onBack}>
                  Back
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!hasClientLicenses}
                  onClick={onContinue}
                >
                  Continue
                </Button>
              </Grid>
              <Divider />
            </div>
          </Card>
        </Grid>
        <Hidden mdUp>
          <Grid item lg={3} />
        </Hidden>
        <Grid item container xs={9} justifyContent="flex-start" alignItems="flex-start">
          {loading &&
            Array.from({ length: 20 }, () => (
              <Grid item xs={4}>
                <Loading columns={1} rows={1} width={['100%']} height={['100%']} />
              </Grid>
            ))}
        </Grid>
        <Grid container item xs={12}>
          <Grid
            item
            container
            md={6}
            lg={9}
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
            className="pr-5"
          >
            <Hidden mdUp>
              <Grid item xs={12} md={6} lg={3}>
                <Card variant="outlined">
                  <Cart hasAccessory disabled />
                </Card>
              </Grid>
            </Hidden>
            {!!clientLicenseProducts.length &&
              clientLicenseProducts.map((product, index) => (
                <ClientLicenseProduct key={`product${index}`} product={product} index={index} />
              ))}
          </Grid>

          {!loading && (
            <Hidden mdDown>
              <Grid
                item
                xs={12}
                md={6}
                lg={3}
                sx={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  backgroundColor: 'inherit',
                }}
              >
                <Card
                  sx={{
                    position: 'sticky',
                    zIndex: 1,
                    backgroundColor: 'inherit',
                    top: '12%',
                  }}
                  variant="outlined"
                >
                  <Cart hasAccessory disabled />
                </Card>
              </Grid>
            </Hidden>
          )}
        </Grid>
      </Grid>
    </LoadingPage>
  )
}
