import React, { useState } from 'react'
import { Button, Card, CardHeader, Container, Stack, Grid, Typography } from '../../components'
import ThumbUpOffAltRoundedIcon from '@mui/icons-material/ThumbUpOffAlt'
import ThumbDownOffAltRoundedIcon from '@mui/icons-material/ThumbDownOffAltRounded'
import { gql } from '@apollo/client'
import { useMutation } from 'utils/apollo'
import { useSnackbar } from 'notistack'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import LeaveCommentDialog from './LeaveCommentDialog'

const TEXT_VARIANT = 'subtitle2'
const APPROVE_RATING = 'Approve'
const DISAPPROVE_RATING = 'Disapprove'

const ThumbsIcon = ({ isThumbsDown, onClick, showClickedState }) => {
  const Component = isThumbsDown ? ThumbDownOffAltRoundedIcon : ThumbUpOffAltRoundedIcon

  const handleOnClick = () => {
    const value = isThumbsDown ? DISAPPROVE_RATING : APPROVE_RATING
    onClick(value)
  }

  const iconText = isThumbsDown ? 'No' : 'Yes'
  return (
    <Button
      variant="outline"
      sx={{
        background: showClickedState ? '#C5C4C4' : 'none',
      }}
      onClick={handleOnClick}
    >
      <Stack direction="column">
        <Component
          sx={{
            width: '30px',
            height: '30px',
            color: '#008080',
          }}
        />
        <Typography variant={TEXT_VARIANT} textAlign="center">
          {iconText}
        </Typography>
      </Stack>
    </Button>
  )
}

const RATE_RESOURCE = gql`
  mutation AddRating($rating: RatingInput!) {
    addRating(rating: $rating)
  }
`

export default function RateResource({ resource }) {
  const [isClicked, setIsClicked] = useState(false)
  const [showCommentInput, setShowCommentInput] = useState(false)
  const [comment, setComment] = useState('')
  const [addRating] = useMutation(RATE_RESOURCE)
  const { enqueueSnackbar } = useSnackbar()

  const submitRating = async (rating) => {
    try {
      await addRating({
        variables: {
          rating: {
            id: resource.id,
            name: resource.title,
            type: 'resource',
            value: rating,
            comment: comment,
          },
        },
      })
      setShowCommentInput(false)
    } catch (err) {
      enqueueSnackbar('Failed to submit rating. Please refresh and try again.', {
        variant: 'error',
        action: CloseSnackbarAction,
      })
    }
  }

  const handleRatingClicked = async (rating) => {
    setIsClicked(true)
    if (rating === DISAPPROVE_RATING) {
      setShowCommentInput(true)
    } else {
      await submitRating(rating)
    }
  }

  const handleCloseCommentInput = () => {
    setIsClicked(false)
    setShowCommentInput(false)
  }

  const handleOnCommentInput = (e) => {
    setComment(e.target.value)
  }

  const showThankYouMessage = isClicked && !showCommentInput
  return (
    <Grid
      container
      sx={{
        marginTop: '40px',
        paddingBottom: '20px',
      }}
      justifyContent="center"
    >
      <LeaveCommentDialog
        isOpen={showCommentInput}
        handleClose={handleCloseCommentInput}
        onCommentInput={handleOnCommentInput}
        onSubmit={() => submitRating(DISAPPROVE_RATING)}
        buttonText={comment.length ? 'Submit' : 'No Thanks'}
      />
      <Grid item>
        <Card
          sx={{
            display: 'table-cell',
            verticalAlign: 'middle',
            width: '270px',
            height: '100px',
            background: 'white',
            border: '1px solid #008080',
            color: '#edf2f7',
          }}
          variant="outlined"
        >
          <Container
            sx={{
              color: 'rgba(0, 0, 0, 0.87)',
            }}
          >
            {showThankYouMessage && (
              <CardHeader
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  padding: '0px',
                }}
                title={'Thanks! Your response has been recorded.'}
                titleTypographyProps={{
                  variant: TEXT_VARIANT,
                }}
              />
            )}
            {!showThankYouMessage && (
              <>
                <CardHeader
                  sx={{
                    width: '100%',
                    textAlign: 'center',
                    padding: '0px',
                  }}
                  title={'Did you find this resource helpful?'}
                  titleTypographyProps={{ variant: TEXT_VARIANT }}
                />
                <Stack direction="row" alignItems="center" justifyContent="center">
                  <ThumbsIcon onClick={handleRatingClicked} />
                  <ThumbsIcon
                    isThumbsDown
                    onClick={handleRatingClicked}
                    showClickedState={showCommentInput}
                  />
                </Stack>
              </>
            )}
          </Container>
        </Card>
      </Grid>
    </Grid>
  )
}
