import { Button, Stack, Typography } from '@mui/material'
import React from 'react'

export default function PurchaseFooter() {
  return (
    <Stack
      direction="column"
      alignItems="center"
      textAlign="center"
      mt="auto"
      sx={{ color: 'gray' }}
      p={2}
    >
      <img src="/images/unyte-logo.png" alt="Logo" style={{ width: '100px' }} />
      <Typography variant="body2" pt={3}>
        © {new Date().getFullYear()} Unyte Health US Inc.
      </Typography>
      <Stack direction="row">
        <Button
          href="https://integratedlistening.com/about/privacy-notice/"
          target="_blank"
          size="large"
        >
          <Typography variant="button" color="">
            Privacy Notice
          </Typography>
        </Button>
        <Button
          href="https://integratedlistening.com/about/terms-of-use/"
          target="_blank"
          size="large"
        >
          <Typography variant="button">Terms of Use</Typography>
        </Button>
        <Button
          href="https://integratedlistening.com/about/terms-of-service/"
          target="_blank"
          size="large"
        >
          <Typography variant="button">Terms of Service</Typography>
        </Button>
      </Stack>
      <Typography variant="caption" maxWidth={700} pb={2} pt={5} sx={{ color: 'grey' }}>
        Statements on Unyte site have not been evaluated by the FDA, and the products and services
        are not intended to diagnose, treat, cure, or prevent any disease. Unyte products are not
        medical devices or medical instruments.
      </Typography>
      <Typography variant="caption" maxWidth={700} sx={{ color: 'grey' }}>
        It is solely the responsibility of each user (whether a professional user or personal user)
        to determine whether the products and/or services may be beneficial for their
        patients/clients or themselves.
      </Typography>
    </Stack>
  )
}
