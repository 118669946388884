import get from 'lodash/get'
import React, { useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import {
  Autocomplete,
  Card,
  Dialog,
  DialogActions,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  capitalize,
  Checkbox,
} from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { EditProviderProfile, ProviderProfileContext } from '../MyProfile'
import GroupsIcon from '@mui/icons-material/Groups'
import ComputerIcon from '@mui/icons-material/Computer'
import formatAddress, { formatRestrictionAddress, isAddressEmpty } from 'utils/formatAddress'
import SelectCountry from 'components/ecommerce/shipping/SelectCountry'
import MuiPhoneNumber from 'mui-phone-number'
import SelectState from 'components/ecommerce/shipping/SelectState'
import { getCountries, getStates } from 'utils/constants/getIsoRegion'
import { useSnackbar } from 'notistack'
import CloseSnackbarAction from 'components/CloseSnackbarAction'

import LearnMore from './LearnMore'
import { useOutletContext } from 'react-router'
import DialogTitleWithClose from 'components/DialogTitleWithClose'

const COUNTRY_NAMES = getCountries().map((c) => c.name)

const steps = [
  {
    label: 'Confirm your Professional Info',
    Component: () => <MissingProfessionalInfo />,
  },
  {
    label: 'Confirm your Business Address Info',
    Component: () => <CurrentAddressForm />,
  },
  {
    label: 'Confirm your Delivery Preferences',
    Component: () => <SelectDeliveryPreferences />,
  },
  {
    label: 'Confirm your In-Person Delivery Info',
    Component: () => <InPersonDeliveryInfo />,
  },
  {
    label: 'Confirm your Remote Delivery Info',
    Component: () => <MissingDeliveryPreferences />,
  },
]

const isValidBusinessAddress = (address) => {
  if (!address) {
    return false
  } else {
    const { address1, country, state, postalCode, city } = address
    return !!address1 && !!country && !!state && !!postalCode && !!city
  }
}

export default function Wizard({
  open,
  handleClose,
  errors,
  onError,
  resetForm,
  providerProfileInfo,
  handleRemoveRemoteDeliveryModel,
}) {
  const {
    form,
    setForm,
    handleLists,
    handleTextField,
    handleSave,
    wizardState,
    activeStep,
    setActiveStep,
    wizardErrors,
    setShowErrors,
    setWizardErrors,
    setLoading,
    handleCheckBox,
    hasCurrentAddressSelected,
    showRestrictions,
    setWizardState,
  } = useContext(ProviderProfileContext)

  const [acknowledgement, setAcknowledgement] = useState(false)
  const handleCloseDialog = () => {
    // if we're not on the last page, close unchecked
    if (activeStep !== steps.length) {
      handleCheckBox('openToNewClients')({ target: { checked: false } })
    } else {
      setActiveStep(0)
    }

    handleClose()
    setAcknowledgement(false)
  }

  const handleSaveForm = async () => {
    await setLoading(true)

    /**
     * 1. check for errors
     * 2. format addresses (if any)
     * 3. save
     */
    // check for errors: we have to externally check business address here
    const hasInPersonDeliverySelected = form?.deliveryModels?.find((model) => model === 'In Person')
    const inPersonAddresses = form.addresses?.map((address) => {
      if (hasInPersonDeliverySelected) {
        return { ...address, isInPersonLocation: 'Yes' }
      } else if (address.locationName === 'Business Address') {
        return { ...address, isInPersonLocation: 'No' }
      } else {
        return undefined
      }
    })
    const businessAddress = form?.addresses?.find(
      (address) => address?.locationName === 'Business Address'
    )
    const isBusinessInfo = wizardState?.includes('BUSINESS_ADDRESS')
    const isRemoteRestriction = activeStep === 4

    const _wizardErrors = {
      ...wizardErrors,
      businessAddress: isBusinessInfo && !isValidBusinessAddress(businessAddress),
      // do not allow user to continue when they have selected restrictions but have not provided any
      remoteRestrictions:
        isRemoteRestriction &&
        showRestrictions === 'Yes' &&
        form.deliveryModels.some((key) => key === 'Remote') &&
        !form.remoteRestrictions.length,

      // delivery models should not be empty during this step..
      deliveryModels: activeStep === 2 && !get(form, 'deliveryModels', []).length,
    }

    // we only want to check errors of the fields in current step
    const hasErrors = Object.values(_wizardErrors).find((val) => !!val)
    // if we don't have errors, go next
    if (hasErrors) {
      setWizardErrors(_wizardErrors)
      await setShowErrors(true)
      await setLoading(false)
      return
    }

    // update remote restrictions only when we're on last step
    const remoteRestrictions =
      activeStep !== 4
        ? form.remoteRestrictions
        : showRestrictions === 'No'
        ? []
        : form.remoteRestrictions

    const saveObj = { ...form, remoteRestrictions, addresses: inPersonAddresses.filter(Boolean) }

    if (activeStep === 2) {
      const currentTime = new Date()
      const remoteRestrictionsSelectedNoAt =
        !remoteRestrictions?.length && activeStep === 2 ? currentTime.toISOString() : undefined
      const addressesSelectedNoAt =
        !inPersonAddresses?.length && activeStep === 2 ? currentTime.toISOString() : undefined

      saveObj['remoteRestrictionsSelectedNoAt'] = remoteRestrictionsSelectedNoAt
      saveObj['addressesSelectedNoAt'] = addressesSelectedNoAt
    }

    // save and go next..
    await handleSave(saveObj)
    await setForm(saveObj)
    await setActiveStep((prevActiveStep) => prevActiveStep + 1)
    await setWizardErrors({})
    await setShowErrors(false)
    await setLoading(false)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    setWizardErrors({})
    setWizardState()
    setShowErrors(false)
  }

  // this is the final step, we want to save the form with open to clients = true here
  const { enqueueSnackbar } = useSnackbar()
  const handleLastStep = async () => {
    try {
      await handleCheckBox('openToNewClients')({ target: { checked: true } })
      await handleSave({ openToNewClients: true })
      await setWizardErrors({})
    } catch (error) {
      console.error('error at final step', error)
    } finally {
      await enqueueSnackbar('Update successful', {
        variant: 'success',
        action: CloseSnackbarAction,
      })
      await handleClose()
      await setActiveStep(0)
    }
  }

  const handleAcknowledgement = () => {
    setAcknowledgement(!acknowledgement)
  }

  const disableConfirm =
    !!form.deliveryModels.find((key) => key === 'In Person') &&
    activeStep === 3 &&
    // current business address is not used
    !hasCurrentAddressSelected &&
    // no extra addresses
    form.addresses.length === 1

  const isEditMode = wizardState?.includes('EDIT')
  return (
    <Dialog
      open={open}
      maxWidth="lg"
      scroll="body"
      fullWidth
      sx={{ padding: '1rem' }}
      onClose={handleCloseDialog}
    >
      <DialogTitleWithClose onClose={handleCloseDialog}>
        Unyte Client Connections
      </DialogTitleWithClose>
      {!acknowledgement && (
        <Card
          elevation={0}
          variant="outlined"
          sx={{
            padding: '3rem',
            display: 'flex',
            placeContent: 'center',
            minHeight: '40vh',
            flexDirection: 'column',
          }}
        >
          <Typography variant="body1" align="left" sx={{ fontWeight: 600, marginBottom: '2rem' }}>
            Unyte Client Connections connects you with clients for your practice.
          </Typography>
          <Typography variant="body1" align="left" sx={{ marginBottom: '2rem' }}>
            If you enroll in this program, in the coming months we will attempt to connect you to
            appropriate clients.
          </Typography>
          <Typography variant="body1" align="left" sx={{ marginBottom: '2rem' }}>
            In order to participate in Unyte Client Connections, we need to confirm some
            information.
          </Typography>
          <Typography variant="body1" align="left" sx={{ marginBottom: '2rem' }}>
            This should only take a few minutes, and helps ensure that we connect you with
            appropriate clients for your practice.
          </Typography>
          <Typography variant="body1" align="left" sx={{ marginBottom: '2rem' }}>
            <span className="font-semibold">Please note:</span> This data will not be shared with
            potential clients without your explicit permission.
          </Typography>
          <Box sx={{ display: 'flex', my: 2 }}>
            <Button
              onClick={handleAcknowledgement}
              variant="contained"
              sx={{ marginRight: 'auto' }}
            >
              Get Started Now
            </Button>
          </Box>
        </Card>
      )}
      {acknowledgement && activeStep !== steps.length && (
        <Card elevation={0} variant="outlined" sx={{ padding: '1rem' }}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                  componentsProps={{
                    label: {
                      sx: { fontSize: index === activeStep ? '1.75rem' : '0.875rem' },
                    },
                  }}
                >
                  {step.label}
                </StepLabel>
                <StepContent>
                  {step.Component && (
                    <step.Component
                      form={form}
                      errors={errors}
                      handleLists={handleLists}
                      handleTextField={handleTextField}
                      onError={onError}
                      handleSave={handleSave}
                      resetForm={resetForm}
                      providerProfileInfo={providerProfileInfo}
                      handleRemoveRemoteDeliveryModel={handleRemoveRemoteDeliveryModel}
                    />
                  )}
                  {/* do not show default buttons when we are editing in-person, delivery, and last step */}
                  {activeStep !== steps.length && !isEditMode && (
                    <Box sx={{ my: 2 }}>
                      <div>
                        <Button
                          variant="contained"
                          // disable when we select in person but have no addresses
                          disabled={disableConfirm}
                          onClick={handleSaveForm}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          Confirm
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                          Back
                        </Button>
                      </div>
                    </Box>
                  )}
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Card>
      )}
      {acknowledgement && activeStep === steps.length && (
        <>
          <Card
            elevation={0}
            variant="outlined"
            sx={{
              padding: '16px 24px',
            }}
          >
            <LearnMore />
            <Typography mt={2} mb={1}>
              Thanks for joining Unyte Client Connections.
            </Typography>
          </Card>
          <DialogActions>
            <Button onClick={handleLastStep} sx={{ m: 1 }} variant="contained">
              Enroll Me Now
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

// if we're missing professional info, show missing fields, otherwise show
const MissingProfessionalInfo = () => {
  // taken from myProfile
  const { form, setWizardErrors, wizardErrors } = useContext(ProviderProfileContext)

  useEffect(() => {
    setWizardErrors({
      modalities: !form.modalities.length,
      clinicalSpecialties: !form.clinicalSpecialties.length,
      population: !form.population.length,
      acceptInsurance: !form.acceptInsurance,
      languagesSpoken: !form.languagesSpoken.length,
      gender: !form.gender,
    })
    // eslint-disable-next-line
  }, [form])

  return (
    <>
      <Typography variant="body2" my={2}>
        Please confirm your professional information.
      </Typography>
      <EditProviderProfile errors={wizardErrors} showAll isWizard />
    </>
  )
}

const SelectDeliveryPreferences = () => {
  const {
    form,
    handleLists,
    handleSave,
    setWizardErrors,
    wizardErrors,
    showErrors,
    setShowErrors,
  } = useContext(ProviderProfileContext)
  const [deliveryPreferences, setDeliveryPreferences] = useState({
    'In Person': form.deliveryModels.some((key) => key === 'In Person'),
    Remote: form.deliveryModels.some((key) => key === 'Remote'),
  })

  const handleDeliveryModel = (type) => (event) => {
    const newDeliveryPreferences = { ...deliveryPreferences, [type]: event.target.checked }
    setDeliveryPreferences(newDeliveryPreferences)

    const newValues = Object.entries(newDeliveryPreferences).reduce((accumulator, [key, value]) => {
      if (value) {
        return [...accumulator, key]
      } else {
        return accumulator
      }
    }, [])

    setShowErrors(false)
    setWizardErrors({})
    const currentTime = new Date()
    const addressesSelectedNoAt = !newValues?.includes('In Person')
      ? currentTime.toISOString()
      : undefined
    const remoteRestrictions = !newValues?.includes('Remote') ? [] : form?.remoteRestrictions
    const remoteRestrictionsSelectedNoAt = !newValues?.includes('Remote')
      ? currentTime.toISOString()
      : undefined

    const saveValues = {
      deliveryModels: newValues,
      addressesSelectedNoAt,
      remoteRestrictionsSelectedNoAt,
      remoteRestrictions,
    }
    handleLists('deliveryModels')('', newValues)
    handleSave(saveValues)
  }

  useEffect(() => {
    setWizardErrors({
      deliveryModels: !deliveryPreferences['In Person'] && !deliveryPreferences['Remote'],
    })
    // eslint-disable-next-line
  }, [deliveryPreferences])

  // show errors when we have deliveryModel is true
  const hasErrors = showErrors && !!wizardErrors.deliveryModels

  return (
    <>
      <Typography variant="body2" my={2}>
        Please confirm how you work with clients. You may select both options if applicable.
      </Typography>
      <Stack justifyContent="flex-start" direction="column">
        <FormControlLabel
          label={
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="body1">I work with clients in-person</Typography>
              <GroupsIcon color={hasErrors ? 'secondary' : 'primary'} fontSize="large" />
            </Stack>
          }
          sx={{ my: 1 }}
          control={
            <Checkbox
              checked={deliveryPreferences['In Person']}
              onChange={handleDeliveryModel('In Person')}
              inputProps={{ 'aria-label': 'has-current-address-as-in-person-delivery' }}
            />
          }
        />
        <FormControlLabel
          label={
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="body1">I work with clients remotely</Typography>
              <ComputerIcon color={hasErrors ? 'secondary' : 'primary'} fontSize="large" />
            </Stack>
          }
          sx={{ my: 1 }}
          control={
            <Checkbox
              checked={deliveryPreferences['Remote']}
              onChange={handleDeliveryModel('Remote')}
              inputProps={{ 'aria-label': 'has-current-address-as-in-person-delivery' }}
            />
          }
        />
      </Stack>
    </>
  )
}

const CurrentAddressForm = () => {
  const { form, setWizardState } = useContext(ProviderProfileContext)
  const addresses = get(form, 'addresses', [])
  const businessAddress =
    addresses.find((address) => address?.locationName === 'Business Address') || {}
  const _businessAddressIndex = addresses.findIndex(
    (address) => address?.locationName === 'Business Address'
  )
  const businessAddressIndex =
    _businessAddressIndex === -1 ? addresses.length : _businessAddressIndex

  useEffect(() => {
    setWizardState('BUSINESS_ADDRESS')
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Typography variant="body2" my={2}>
        Please confirm your registered business address.
      </Typography>
      <Typography variant="body2" my={2}>
        Note: we require your business address even if you only deliver services remotely.
      </Typography>
      <SingleAddressForm openFromProps idx={businessAddressIndex} address={businessAddress} />
    </>
  )
}

/**
 * If the step is 2,  then don't show location and add more
 * We are also using this component to edit basic info's business address
 */
export const SingleAddressForm = ({ idx, address, openFromProps = false }) => {
  const {
    form,
    wizardState,
    setWizardState,
    upsertAddress,
    handleLists,
    setShowErrors,
    wizardErrors,
    setWizardErrors,
    showErrors,
    hasCurrentAddressSelected,
    handleSave,
    activeStep,
  } = useContext(ProviderProfileContext)

  const { autoFocusLabelName, setAutoFocusLabelName } = useOutletContext()

  const _addresses = get(form, 'addresses', [])

  // this is for adding addresses in the wizard
  const addresses = hasCurrentAddressSelected
    ? _addresses
    : _addresses.filter((address) => address.locationName === 'Business Address')

  const isBusinessInfo = wizardState?.includes('BUSINESS_ADDRESS')
  const isBasicInfo = wizardState?.includes('BASIC_INFO')
  const isInPersonDeliveryInfo = activeStep === 3
  const isEditMode = wizardState?.includes('EDIT')
  const isNewInPersonDeliveryInfo = isInPersonDeliveryInfo && idx === form.addresses.length

  // open by default when..
  // - a NEW in person delivery info
  // - Business address
  // - basic info
  const handleEdit = () => {
    const _wizardState = wizardState || 'IN_PERSON_DELIVERY_INFO'
    setWizardState(`${_wizardState}/EDIT_${idx}`)
    setAutoFocusLabelName('Address')
  }
  const [currentAddress, setCurrentAddress] = useState(address)
  const open = wizardState?.includes('EDIT')

  const handleAddresses = (type) => (event) => {
    if (type === 'country') {
      const isoCountry = getCountries().find((country) => country.name === event.target.value)?.code
      setCurrentAddress({
        ...currentAddress,
        country: event.target.value,
        isoCountry,
        state: undefined,
        isoState: undefined,
      })

      // if we're in business address section, we have
    } else if (type === 'state') {
      const isoState = getStates(currentAddress.isoCountry).find(
        (state) => state.name === event.target.value
      )?.subCode
      setCurrentAddress({
        ...currentAddress,
        state: event.target.value,
        isoState,
      })
    } else if (type === 'phoneNumber') {
      setCurrentAddress({
        ...currentAddress,
        phoneNumber: event,
      })
    } else {
      setCurrentAddress({
        ...currentAddress,
        [type]: event.target.value,
      })
    }
  }

  // this leaves edit mode
  const handleRemove = () => {
    const newAddresses = form.addresses.filter((_, index) => idx !== index)
    handleLists('addresses')('', newAddresses)
    setCurrentAddress(address)
    setWizardState(get(wizardState.split('/'), '[0]', wizardState))
  }

  const handleCancel = async () => {
    // if we don't have any addresses, don't reset errors
    if (addresses.length) {
      await setWizardErrors({})
    }
    await setWizardState(get(wizardState.split('/'), '[0]', wizardState))
    await setShowErrors(false)
    await setCurrentAddress(address)
  }

  const handleAdd = async () => {
    // stop adding if we have empty fields
    const currentErrors = {
      locationName: !isBasicInfo && !isBusinessInfo && !currentAddress.locationName,
      address1: !currentAddress.address1,
      country: !currentAddress.country,
      state: !currentAddress.state,
      postalCode: !currentAddress.postalCode,
      city: !currentAddress.city,
    }
    const hasErrors = Object.values(currentErrors).find((val) => !!val)

    if (hasErrors) {
      // NOTE: setting the wizard error here triggers rerender
      await setWizardErrors(currentErrors)
      await setShowErrors(true)
      return
    }
    const isBusinessAddress = isBasicInfo || isBusinessInfo
    const formattedCurrentAddress = {
      ...currentAddress,
      locationName: isBusinessAddress ? 'Business Address' : currentAddress.locationName,
    }
    await upsertAddress(idx, 'addresses', formattedCurrentAddress)
    if (isNewInPersonDeliveryInfo) {
      await setCurrentAddress({ ...address })
    }

    // if we are in basic info, we want to save this form instead..
    if (isBasicInfo) {
      if (form.addresses.length === idx) {
        await handleSave({
          ...form,
          addresses: [...form.addresses, formattedCurrentAddress],
        })
      } else {
        await handleSave({
          ...form,
          addresses: [
            ...form.addresses.map((address) =>
              address.locationName === 'Business Address' ? formattedCurrentAddress : address
            ),
          ],
        })
      }
    }
    await setWizardErrors({})
    await setWizardState(get(wizardState.split('/'), '[0]', wizardState))
    await setShowErrors(false)
  }

  /**
   * Do not show other addresses when we are in:
   * - edit mode
   * - business address
   */
  if (
    (isEditMode && !wizardState.includes(`EDIT_${idx}`)) ||
    (isInPersonDeliveryInfo && !hasCurrentAddressSelected && idx === 0)
  ) {
    return null
  }

  const hasErrors = Object.values(wizardErrors).find((val) => !!val)
  const errors = showErrors && hasErrors ? wizardErrors : {}

  // this is shown in step 4
  const formattedLocationName =
    (currentAddress.locationName && capitalize(currentAddress?.locationName)) ||
    (isBusinessInfo &&
      isAddressEmpty(currentAddress) &&
      'We do not have your business address on file...')

  // this will be shown when in step 2
  const formattedAddress = !isAddressEmpty(currentAddress)
    ? formatAddress(currentAddress)
    : isBusinessInfo
    ? 'We do not have your business address on file...'
    : ''

  const ERROR_CSS = showErrors
    ? { fontWeight: 500, color: '#f50057', textTransform: 'uppercase' }
    : {}

  return (
    <>
      {!open && !isEditMode && (
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          {isInPersonDeliveryInfo && (
            <Grid item xs={3}>
              <Typography variant="subtitle1" fontWeight={500} sx={{ ...ERROR_CSS }}>
                {formattedLocationName}
              </Typography>
            </Grid>
          )}
          <Grid item xs={6}>
            <Typography variant="body1" sx={{ ...ERROR_CSS }}>
              {formattedAddress}
            </Typography>
          </Grid>
          {/* {idx === 0 && <Grid item xs />} */}
          {/* we will block editing business address here */}
          {((idx !== 0 && idx !== form?.addresses?.length) || !isInPersonDeliveryInfo) && (
            <Grid container item xs={3} justifyContent="flex-end">
              <Button
                onClick={handleEdit}
                color={errors.inPersonDelivery ? 'secondary' : 'primary'}
                variant={errors.inPersonDelivery ? 'outlined' : 'text'}
              >
                {isAddressEmpty(currentAddress) && isBusinessInfo
                  ? 'Add Business Address'
                  : 'Edit Address'}
              </Button>
            </Grid>
          )}
        </Grid>
      )}
      {/* Editing the address above will go here */}
      {open && (
        <Stack
          direction="column"
          alignItems="flex-start"
          mx={openFromProps ? 0 : 2}
          px={openFromProps ? 0 : 2}
        >
          <Stack direction="column" className="w-full">
            {isInPersonDeliveryInfo && (
              <TextField
                label="Location Name"
                placeholder="Location/practice name eg. Location 1, Downtown location"
                value={currentAddress.locationName}
                onChange={handleAddresses('locationName')}
                error={errors.locationName}
              />
            )}
            <TextField
              autoFocus={autoFocusLabelName === 'Address'}
              label="Address"
              placeholder="Address"
              value={currentAddress.address1 || ''}
              onChange={handleAddresses('address1')}
              error={errors.address1}
            />
            <TextField
              label="Apartment, suite, etc. (optional)"
              placeholder="Apartment, suite, etc. (optional)"
              value={currentAddress.address2 || ''}
              onChange={handleAddresses('address2')}
            />
            <Grid item sx={{ marginBottom: '4px', marginTop: '8px' }}>
              <SelectCountry
                formClassName="w-full"
                label="Country"
                showFullName
                value={currentAddress.country}
                onChange={handleAddresses('country')}
                error={errors.country}
              />
            </Grid>
            <Grid container direction="row" alignItems="flex-end" justifyContent="space-between">
              <Grid item sx={{ flex: 1, maxWidth: '32%', marginBottom: '4px', marginTop: '8px' }}>
                <SelectState
                  label="State/Province/Region"
                  showFullStateName
                  country={currentAddress.country}
                  value={currentAddress.state}
                  onChange={handleAddresses('state')}
                  error={errors.state}
                />
              </Grid>
              <Grid item sx={{ flex: 1, maxWidth: '32%' }}>
                <TextField
                  className="w-full"
                  label="City"
                  placeholder="City"
                  value={currentAddress.city || ''}
                  onChange={handleAddresses('city')}
                  error={errors.city}
                />
              </Grid>
              <Grid item sx={{ flex: 1, maxWidth: '32%' }}>
                <TextField
                  className="w-full"
                  label="Zip/Postal Code"
                  placeholder="Zip/Postal Code"
                  value={currentAddress.postalCode || ''}
                  onChange={handleAddresses('postalCode')}
                  error={errors.postalCode}
                />
              </Grid>
            </Grid>
            {isInPersonDeliveryInfo && (
              <MuiPhoneNumber
                variant="outlined"
                label="Phone (optional)"
                placeholder="Phone (optional)"
                value={currentAddress.phoneNumber || ''}
                name="phonemask"
                disableAreaCodes
                preferredCountries={['ca', 'us']}
                defaultCountry={'us'}
                onChange={handleAddresses('phoneNumber')}
              />
            )}
          </Stack>
        </Stack>
      )}

      {isEditMode && (
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ marginLeft: openFromProps ? '-1rem !important' : 'inherit' }}
        >
          <Stack direction="row" spacing={2}>
            <Button onClick={handleAdd}>Save</Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </Stack>
          {wizardState !== `IN_PERSON_DELIVERY_INFO/EDIT_${form.addresses.length}` &&
            !openFromProps && (
              <Button color="secondary" onClick={handleRemove} disabled={idx === 0}>
                Remove
              </Button>
            )}
        </Stack>
      )}
      {isNewInPersonDeliveryInfo && !open && (
        <Stack alignContent="flex-start">
          <Box>
            <Button
              onClick={handleEdit}
              color={errors.inPersonDelivery ? 'secondary' : 'primary'}
              variant={errors.inPersonDelivery ? 'outlined' : 'text'}
              sx={{ marginLeft: '-0.5rem' }}
            >
              Add In-Person Delivery Addresses
            </Button>
          </Box>
        </Stack>
      )}
    </>
  )
}

const InPersonDeliveryInfo = () => {
  const {
    form,
    wizardState,
    setWizardState,
    setWizardErrors,
    hasCurrentAddressSelected,
    setHasCurrentAddressSelected,
  } = useContext(ProviderProfileContext)
  const addresses = get(form, 'addresses', [])

  // do we have in person delivery model selected?
  const hasInPersonDeliverySelected = form.deliveryModels.find((key) => key === 'In Person')
  const isEditMode = wizardState?.includes('EDIT')

  // we cannot go next if we don't have any in person addresses here
  useEffect(() => {
    setWizardState('IN_PERSON_DELIVERY_INFO')
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (hasInPersonDeliverySelected && !addresses?.length) {
      setWizardErrors({ inPersonDelivery: true })
    } else {
      setWizardErrors({})
    }
    // eslint-disable-next-line
  }, [form, hasCurrentAddressSelected])

  const handleSwitch = (event) => {
    setHasCurrentAddressSelected(event.target.checked)
  }

  const businessAddress = addresses.find((address) => address.locationName === 'Business Address')
  const otherAddresses = addresses.filter((address) => address.locationName !== 'Business Address')
  const sortedAddresses = [businessAddress, ...otherAddresses]

  return (
    <>
      {!hasInPersonDeliverySelected && (
        <Typography color="primary" m={1} variant="h5" mt={2} mb={3}>
          <CheckCircleOutlineIcon fontSize="large" sx={{ verticalAlign: 'middle', mr: 3 }} />I do
          not currently work with clients in-person.
        </Typography>
      )}
      {hasInPersonDeliverySelected && (
        <>
          <Typography variant="body2" my={2}>
            Please confirm the address/es where you work with your clients in-person.
          </Typography>
          {!isEditMode && (
            <FormControlLabel
              label={`Use Current Business Address (${formatAddress(businessAddress)})`}
              sx={{ my: 1 }}
              control={
                <Checkbox
                  checked={hasCurrentAddressSelected}
                  onChange={handleSwitch}
                  inputProps={{ 'aria-label': 'has-current-address-as-in-person-delivery' }}
                />
              }
            />
          )}
          <Stack divider={!wizardState ? <Divider /> : <div />} spacing={1}>
            {sortedAddresses.map((address, idx) => (
              <SingleAddressForm key={idx} idx={idx} address={address} />
            ))}
            <SingleAddressForm idx={addresses.length} address={{}} />
          </Stack>
        </>
      )}
    </>
  )
}

export const MissingDeliveryPreferences = () => {
  const {
    form,
    wizardState,
    setWizardState,
    showRestrictions,
    setShowRestriction,
    showErrors: _showErrors,
    setShowErrors,
  } = useContext(ProviderProfileContext)
  const remoteRestrictions = get(form, 'remoteRestrictions', [])

  // do we have in person delivery model selected?
  const hasRemoteDeliverySelected = form.deliveryModels.find((key) => key === 'Remote')

  useEffect(() => {
    setShowRestriction('Yes')
    setShowErrors(false)
    setWizardState('DELIVERY_PREFERENCES')
    // eslint-disable-next-line
  }, [])

  const isEditMode = wizardState?.includes('EDIT')
  const showErrors = showRestrictions === 'Yes' && _showErrors

  return (
    <>
      {!hasRemoteDeliverySelected && (
        <Typography color="primary" m={1} variant="h5" mt={2} mb={3}>
          <CheckCircleOutlineIcon fontSize="large" sx={{ verticalAlign: 'middle', mr: 3 }} />I do
          not currently work with clients remotely.
        </Typography>
      )}
      {hasRemoteDeliverySelected && (
        <>
          {!isEditMode && !remoteRestrictions.length && !showErrors && (
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 600, color: 'rgba(0, 0, 0, 0.87)' }}
              my={2}
            >
              Specify any location restrictions for remote services, such as limited licensing to
              certain states/provinces/regions.
            </Typography>
          )}
          <Stack
            divider={
              !isEditMode && !showErrors && remoteRestrictions.length > 1 ? <Divider /> : null
            }
            spacing={!isEditMode ? 2 : 0}
          >
            {remoteRestrictions.map((restriction, idx) => (
              <RemoteRestrictionForm idx={idx} restriction={restriction} />
            ))}
            <RemoteRestrictionForm idx={remoteRestrictions.length} restriction={{}} />
          </Stack>
        </>
      )}
    </>
  )
}

export const RemoteRestrictionForm = ({ restriction, idx }) => {
  const {
    form,
    wizardState,
    setWizardState,
    handleLists,
    upsertAddress,
    showErrors,
    setShowErrors,
    wizardErrors,
    setWizardErrors,
  } = useContext(ProviderProfileContext)
  const [open, setOpen] = useState(false)
  const [currentRestriction, setCurrentRestriction] = useState({
    countries: [],
    states: [],
    ...restriction,
  })
  const isNewRemoteRestriction = wizardState?.includes(
    `REMOTE_DELIVERY_INFO/EDIT_${form.remoteRestrictions.length}`
  )
  const handleEdit = () => {
    setWizardState(`REMOTE_DELIVERY_INFO/EDIT_${idx}`)
    setOpen(true)
  }
  const handleAdd = () => {
    // stop adding if we have error
    const currentErrors = {
      countries: !currentRestriction.countries.length,
    }

    if (Object.values(currentErrors).find((val) => !!val)) {
      setShowErrors(true)
      setWizardErrors(currentErrors)
      return
    }
    // reset adding to default value (nothing)
    if (idx === form.remoteRestrictions.length) {
      setCurrentRestriction({ ...restriction, countries: [], states: [] })
    }
    upsertAddress(idx, 'remoteRestrictions', currentRestriction)
    setWizardState()
    setOpen(false)
    setWizardErrors({})
    setShowErrors(false)
  }
  const handleRemove = () => {
    const newRemoteRestriction = form.remoteRestrictions.filter((_, index) => idx !== index)
    handleLists('remoteRestrictions')('', newRemoteRestriction)
    setCurrentRestriction({ ...restriction, countries: [], states: [] })
    setWizardState()
    setOpen(false)
    setWizardErrors({})
    setShowErrors(false)
  }

  const handleCancel = () => {
    if (isNewRemoteRestriction) {
      setCurrentRestriction({ ...restriction, countries: [], states: [] })
    } else {
      setCurrentRestriction({ ...restriction })
    }
    setWizardState()
    setOpen(false)
    setWizardErrors({})
    setShowErrors(false)
  }

  const handleCountryChange = (_, val) => {
    const countries = val.map((countryName) => {
      const isoCode = getCountries().find((country) => country.name === countryName)?.code
      return {
        name: countryName,
        isoCode,
      }
    })
    // update current state
    setCurrentRestriction({ ...currentRestriction, countries, states: [] })
    // update context
    handleLists('countries')(countries)
    handleLists('states')([])
  }

  const handleStateChange = (_, val) => {
    const states = val.map((stateName) => {
      const isoCode = getStates(currentRestriction.countries[0].isoCode).find(
        (state) => state.name === stateName
      )?.code
      return {
        name: stateName,
        isoCode,
      }
    })
    // update context
    setCurrentRestriction({ ...currentRestriction, states })
    handleLists('state')(states)
  }

  useEffect(() => {
    return () => setWizardState()
    // eslint-disable-next-line
  }, [])

  const error = showErrors ? wizardErrors : {}

  if (
    wizardState?.includes('REMOTE_DELIVERY_INFO') &&
    !wizardState?.includes(`REMOTE_DELIVERY_INFO/EDIT_${idx}`)
  ) {
    return null
  }
  return (
    <>
      {!open && (
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          {idx !== form.remoteRestrictions.length && (
            <>
              <Grid item xs={3}>
                <Typography variant="subtitle1" fontWeight={500}>
                  Remote Delivery Area {idx + 1}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  I deliver remotely in {formatRestrictionAddress(restriction)}
                </Typography>
              </Grid>
            </>
          )}
          <Grid
            container
            item
            xs={3}
            justifyContent={idx === form.remoteRestrictions.length ? 'flex-start' : 'flex-end'}
          >
            <Button
              variant={showErrors ? 'outlined' : 'text'}
              onClick={handleEdit}
              color={showErrors ? 'secondary' : 'primary'}
              sx={{ marginLeft: '-0.5rem' }}
            >
              {idx === form.remoteRestrictions.length
                ? 'Specify Remote Delivery Area/s'
                : 'Edit Remote Delivery Area'}
            </Button>
          </Grid>
        </Grid>
      )}
      {open && (
        <Stack direction="column" alignItems="flex-start">
          <Stack sx={{ width: '100%' }}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 600, color: 'rgba(0, 0, 0, 0.87)', mb: 1 }}
            >
              Specify Remote Delivery Area:
            </Typography>

            <Stack direction="column" className="w-full">
              <Stack direction="row" spacing={1} alignItems="center">
                <Autocomplete
                  fullWidth
                  multiple
                  options={COUNTRY_NAMES}
                  value={currentRestriction.countries?.map((country) => country.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Countries"
                      placeholder="Countries"
                      error={error.countries}
                    />
                  )}
                  onChange={handleCountryChange}
                />
              </Stack>

              {currentRestriction.countries.length === 1 && (
                <Autocomplete
                  fullWidth
                  multiple
                  options={
                    currentRestriction.countries.length === 1
                      ? getStates(currentRestriction.countries[0].isoCode).map(
                          (state) => state.name
                        )
                      : []
                  }
                  label="State/Province/Region (optional)"
                  value={currentRestriction.states.map((state) => state.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State (optional)"
                      placeholder="State (optional)"
                    />
                  )}
                  onChange={handleStateChange}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      )}

      {wizardState?.includes('REMOTE_DELIVERY_INFO/EDIT_') && (
        <Stack direction="row" justifyContent="space-between" my={2}>
          <Box sx={{ marginLeft: '-0.5rem' }}>
            <Button onClick={handleAdd}>
              {wizardState === `REMOTE_DELIVERY_INFO/EDIT_${form.remoteRestrictions.length}`
                ? 'Add Remote Delivery Area'
                : 'Save'}
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </Box>
          {wizardState !== `REMOTE_DELIVERY_INFO/EDIT_${form.remoteRestrictions.length}` && (
            <Button color="secondary" onClick={handleRemove}>
              Remove
            </Button>
          )}
        </Stack>
      )}
    </>
  )
}
